import React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import './ScoreCard.css';
import ScoreBar from './ScoreBar';

interface Props {
  className?: string,
  text: string,
  score: number,
  maxScore: number,
  surveyType: string,
}

const ScoreCard = (props: Props) => {
  const scoreColor = (): string => {
    let color = "#6DAE43";
    const scoreCurrent = props.score + 1;
    switch(props.surveyType) {
      case "Employee Burnout": //Burnout
        if(scoreCurrent >= 1 && scoreCurrent <= 1.62) return "#FB5523";
        if(scoreCurrent >= 1.63 && scoreCurrent <= 2.67) return "#FDD835";
        if(scoreCurrent >= 2.68 && scoreCurrent <= 4) return "#6DAE43";
        break;
      case "Employee Engagement": //Engagement
        if(scoreCurrent >= 1 && scoreCurrent <= 2.33) return "#FB5523";
        if(scoreCurrent >= 2.34 && scoreCurrent <= 3.67) return "#FDD835";
        if(scoreCurrent >= 3.67 && scoreCurrent <= 5) return "#6DAE43";
        break;
      case "Job Satisfaction Survey": //Job Satisfaction
        if(scoreCurrent >= 1 && scoreCurrent <= 3) return "#FB5523";
        if(scoreCurrent >= 3.1 && scoreCurrent <= 5.1) return "#FDD835";
        if(scoreCurrent >= 5.1 && scoreCurrent <= 7) return "#6DAE43";
        break;
      default:
        const percentage = props.score/props.maxScore;
        return percentage > 0.33? percentage > 0.66? "#6DAE43" : "#FDD835" : "#FB5523";
    }
    return color;
  }

  const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffff',
      ...theme.typography.body2,
      padding: theme.spacing(0.3),
      marginBottom: theme.spacing(3),
      color: theme.palette.text.primary,
      minWidth: '100%',
      //minWidth: props.surveyType ==="Employee Burnout"?'34.5%':'24.2%',
      minHeight: '150px',
  }));
    console.log("props.calssname")
  return (
    <>
      <Item>
      <div className={`${props.className}`}>
        <div className='cardName'>{props.text}</div>
        
        {/* <ScoreBar score={props.score} maxScore={1} /> */}
        <div className={`${scoreColor()} `} style={{
                fontFamily: "'IBM Plex Sans', sans-serif",
                fontSize: "32px",
                color: scoreColor(),
                fontWeight: "600",
              }}>{(props.score + 1).toFixed(2)}</div>
      </div>
      </Item>
    </>
  )
}

export default ScoreCard;