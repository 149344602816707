import ResentSurveyCard from "./resent-survey-card";
import {Button, CircularProgress, Link} from "@mui/material";
import React, {Component} from "react";
import {MsalContext} from "@azure/msal-react";
import {deleteSurveyThunk, getAllSurveysThunk} from "../../../redux/survey/survey.thunk";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../../../assets/styles/theme";

class ResentSurveyIndex extends Component {

    static contextType = MsalContext;

    destroySurvey = async (id) => {

        await this.props.deleteSurvey(id);
        this.props.getAllSurveys();
    };

    componentDidMount() {
        this.props.getAllSurveys(this.context.accounts[0].localAccountId);
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="text-black-50 mb-3">
                        Recent Surveys
                    </h3>
                    <Link underline="none" href="#">{`See all 3 surveys`}</Link>
                </div>
                <div>

                        <div className="d-flex justify-content-center">
                            <MutatingDots
                                color={palette.primary}
                                height="100"
                                width="100"
                                visible={this.props.status === 'loading'}/>
                        </div>

                    { this.props.status === 'loading'? null :
                    this.props.allSurveys.map((survey, index) => <ResentSurveyCard survey={survey} key={index} deleteSurvey={this.destroySurvey} />)}
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <p className="text-muted mb-0">
                        {`Showing 2 of 3 surveys | `}<Link underline="none" href="#">{`See all >>`}</Link>
                    </p>
                    <NavLink to='/survey-create' style={{textDecoration: 'none'}}>
                        <Button variant="contained" color="success">CREATE</Button>
                    </NavLink>
                </div>
            </div>
        )
    }
}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        allSurveys: state.surveys.allSurveys,
        status: state.surveys.status,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getAllSurveys: (tenantId) => dispatch(getAllSurveysThunk(tenantId)),
        deleteSurvey: (id) => dispatch(deleteSurveyThunk(id)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ResentSurveyIndex);