import {
    Alert,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Menu,
    MenuItem,
    Snackbar,
    Tooltip,
    Typography
} from "@mui/material";
import {InsertChartOutlined, MoreVert, Pause, PlayArrow, Share, Telegram, Visibility} from "@mui/icons-material";
import React, {useState} from "react";
import {format} from "date-fns";
import {Link} from "react-router-dom";
import ShareClipboard from "../../../layouts/ShareClipboard";
import {numberOfQuestions} from "../../../../helpers/survey.helper";
import './survey-card.css';
import {useTranslation} from "react-i18next";

const createOptions = (name, routerLink, translation) => {
    return {
        name,
        routerLink,
        translation
    }
}


const SurveyCardItem = (props) => {
    const {t} = useTranslation();


    const options = [
        /*createOptions('Send a copy', '', t('surveys.sendCopy')),
        createOptions('Transfer', '', t('surveys.transfer')),
        createOptions('Make a copy', '', t('surveys.makeCopy')),
        createOptions('Create template', '', t('surveys.createTemplate')),
        createOptions('Clear response', '', t('surveys.clearResponse')),*/
        createOptions('Delete', '', t('surveys.delete')),
    ]
    const {item, deleteSurvey, updateSurvey} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [status, setStatus] = useState(item.active);
    const [open, setOpen] = useState(false);
    const [openShare, setOpenShare] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseMenu = (option = '') => {
        if (option === 'Delete') {
            if (window.confirm(`Are you sure to delete the Survey: ${item.title}?`)) {
                deleteSurvey(item.id);
            }
        }
        setAnchorEl(null);
    }

    const handleOpenShareMenu = (event) => {
        setOpenShare(event.currentTarget);
    }

    const handleCloseShareMenu = () => {
        setOpenShare(null);
    }

    const toggleStatus = () => {
        console.log(item);
        console.log(props);
        updateSurvey(item.id, {active: !status});
        setStatus(!status)
        setOpen(true);
    }

    return (
        <div className="h-100">
            <Card className="h-100 position-relative">
                <CardHeader
                    action={
                        <div>
                            <IconButton onClick={handleOpenMenu}><MoreVert/></IconButton>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                {options.map((option, index) =>
                                    <MenuItem key={index}
                                              onClick={() => handleCloseMenu(option.name)}>{option.translation}</MenuItem>)}
                            </Menu>
                        </div>
                    }
                    title={item.title}
                    subheader={item.description}/>
                <CardContent>
                    <Typography gutterBottom component="div" color="text.secondary">
                        {t('surveys.created')}: <b>{format(new Date(item.createdAt), 'MM/dd/yyyy')}</b>
                    </Typography>
                    <Typography gutterBottom component="div" color="text.secondary">
                        {t('surveys.modified')}: <b>{format(new Date(item.updatedAt), 'MM/dd/yyyy')}</b>
                    </Typography>
                    <Typography gutterBottom variant="body2" color="text.info">
                        {t('surveys.questions')}: <b>{numberOfQuestions(item)}</b>
                    </Typography>
                    <Typography variant="body2" color="text.info">
                        {t('surveys.responses')}: <b>{item.numberOfResponses || 0}</b>
                    </Typography>
                </CardContent>

            </Card>
            <div className="card-action-bottom border-top">
                <div className="d-flex justify-content-evenly animateHover">
                    <div className="position-relative">
                        <Tooltip title="Share">
                            <IconButton onClick={handleOpenShareMenu}><Share/></IconButton>
                        </Tooltip>
                        <Menu anchorEl={openShare} open={Boolean(openShare)} onClose={handleCloseShareMenu}
                              className="h-menu-share">
                            <MenuItem>
                                <ShareClipboard id={item.id}/>
                            </MenuItem>
                        </Menu>
                        {/*<TransitionGroup className="item-content">
                            {openShare ?
                                <CSSTransition timeout={500} classNames="item" className="item-toggle">

                                </CSSTransition> : null}
                        </TransitionGroup>*/}
                    </div>
                    <Tooltip title="Show Survey">
                        <Link to={`/surveys-show/${item.id}`} params={{json: item.json}}>
                            <IconButton><Visibility/></IconButton>
                        </Link>
                    </Tooltip>

                    <Link to={`/collect/${item.id}`}>
                        <Tooltip title="Distribute">
                            <IconButton><Telegram/></IconButton>
                        </Tooltip>
                    </Link>

                    <div className="d-flex">
                        {/*<Link to={`/report/${item.id}`}>
                            <Tooltip title="Results">
                                <IconButton><PieChartOutline/></IconButton>
                            </Tooltip>
                        </Link>*/}
                        <Link to={`/myreporttest/${item.id}`}>
                            <Tooltip title={t('item.results')}>
                                <IconButton><InsertChartOutlined/></IconButton>
                            </Tooltip>
                        </Link>
                        {/*<Link to={`/myreport/${item.id}`}>
                        <Tooltip title="All Responses">
                            <IconButton><BubbleChartOutlined/></IconButton>
                        </Tooltip>
                    </Link>*/}
                    </div>

                    <Tooltip title="Status">
                        <IconButton color={status ? 'success' : 'warning'}
                                    sx={{borderRadius: '50rem'}}
                                    onClick={toggleStatus} value="status" selected={status}>
                            {status === false ? <PlayArrow/> : <Pause/>}
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div>
                <Snackbar open={open} autoHideDuration={3000} anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                          onClose={() => setOpen(false)}>
                    {status ?
                        <Alert severity="success" sx={{width: '100%'}} onClose={() => setOpen(false)}>
                            {`Survey `}<b>Active</b>
                        </Alert> :
                        <Alert severity="warning" sx={{width: '100%'}} onClose={() => setOpen(false)}>
                            {t('item.pause')}
                        </Alert>
                    }

                </Snackbar>
            </div>
        </div>
    )
}

export default SurveyCardItem;