import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React, {Component} from "react";
import {MsalContext} from "@azure/msal-react";
import axios from "axios";
import {connect} from "react-redux";
import './billing-index.css';
import {format} from "date-fns";
import {addUserThunk, getUserThunk} from "../../redux/users/users.thunk";

class Billing extends Component {

    static contextType = MsalContext;


    async componentDidMount() {
        console.log("component mounted");
        console.log(this.context.accounts);
        const account = this.context.accounts[0];
        if (!this.props.user) {
            await this.props.getUser(account.localAccountId);
            if (!this.props.user.id) {
                console.log("add new user");

                await this.props.addUser({
                    id: account.localAccountId,
                    email: account.idTokenClaims.email,
                    username: account.username,
                    firstName: account.idTokenClaims.extension_firstName,
                    lastName: account.idTokenClaims.extension_lastName,
                });
            } else {

                console.log("user exists")
            }
        }
    }


    /*this is the first step in the flow, it creates a url with a checkout session and redirects to it
    at this point, if the user purchases the first subscription, he gets a stripeCustomerId assigned in the backend
    */

    purchaseSubscription = async (e) => {
        console.log(e.currentTarget.value);

        const email = this.context.accounts[0].idTokenClaims.email;

        let obj = {
            //email is important since this is how backend searches for the user in the table, and updates it with stripeCustomerId
            email: email,
            //type of subscription plan, available types standard_plan_native, advanced_plan_native
            type: e.currentTarget.value
        };
        let res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/stripe/create-checkout-session`, obj);

        window.location.href = res.data;
    }

    /*
    this creates a link to stripe customer portal
    in order to created it, you need to pass stripeCustomerId
    this is the reason we have to first call the above method, so user can get stripeCustomerAssigned
    also, users are not able to purchase subscriptions from the portal (try cancelling all your subs, and then go to the portal again, there would be no option to purchase a plan), 
    so if user cancelled subs and wants to subscribe again, use purchaseSubscription()
    */

    stripePortal = async () => {
        let obj = {
            stripeCustomerId: this.props.user.stripeCustomerId
        }
        let res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/stripe/create-customer-portal-session`, obj);
        window.location.href = res.data;
    }


    render() {
        console.log(this.props.user?.stripe_subscriptions_natives);
        return (
            <div>
                <div className="row">
                    <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                       
                        {this.props.user && this.props.user.stripe_subscriptions_natives.length < 1 ?
                            <div className="row">
                                <div className="col-md-6 my-2">
                                    <Card className="card-area-animation text-center">
                                        <CardHeader title={this.props.t('billing.standard')}
                                                    subheader={this.props.t('billing.purchase')}/>

                                        <CardContent>
                                            <Typography variant="h2">
                                                <b>15 <h4 className="d-inline-block">$<small>/{this.props.t('billing.month')}</small></h4></b>
                                            </Typography>
                                        </CardContent>
                                        <CardActions className="justify-content-center my-3">
                                            <Button value="standard_plan_native" variant="contained" size="large"
                                                    onClick={(e) => this.purchaseSubscription(e)}>
                                               {this.props.t('billing.buy')}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </div>
                                <div className="col-md-6 my-2">
                                    <Card className="card-area-animation text-center">
                                        <CardHeader title={this.props.t('billing.advanced')}
                                                    subheader={this.props.t('billing.purchase')}/>

                                        <CardContent>
                                            <Typography variant="h2">
                                                <b>30 <h4 className="d-inline-block">$<small>/{this.props.t('billing.month')}</small></h4></b>
                                            </Typography>
                                        </CardContent>
                                        <CardActions className="justify-content-center my-3">
                                            <Button value="advanced_plan_native" variant="contained" size="large"
                                                    onClick={(e) => this.purchaseSubscription(e)} color="secondary">
                                                {this.props.t('billing.buy')}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </div>
                            </div> : null}
                        </div>

                    </div>
                    <div className="col-md-12 my-4">
                        <h6><b>{this.props.t('billing.yourSubs')}</b></h6>
                        {this.props.user && this.props.user.stripe_subscriptions_natives.length>0 ?

                        <div>
                             <div className="text-center my-5">
                            <Button variant="contained" size="large" onClick={()=>this.stripePortal()}>{this.props.t('billing.manage')}</Button>
                        </div>
                        
                            <TableContainer className="w-100">
                                <Table className="w-100" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {/* <TableCell>Product Id</TableCell>
                                            <TableCell align="right">Subscription Id</TableCell>*/}
                                            <TableCell align="center">{this.props.t('billing.plan')}</TableCell>
                                            <TableCell align="center">{this.props.t('billing.quantity')}</TableCell>
                                            <TableCell align="center">{this.props.t('billing.period')}</TableCell>
                                            <TableCell align="center">{this.props.t('billing.status')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.user.stripe_subscriptions_natives.map((row, index) => (
                                            row.active ?
                                                <TableRow key={index}
                                                          sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                    {/* <TableCell component="th" scope="row">
                                                        {row.productId}
                                                    </TableCell>
                                                    <TableCell align="right">{row.subscriptionId}</TableCell>*/}
                                                    <TableCell
                                                        align="center">{row.subscriptionJson.plan.amount / 100} {' ' + row.subscriptionJson.plan.currency + ' ' + row.subscriptionJson.plan.interval}</TableCell>
                                                    <TableCell align="center">{row.quantity}</TableCell>
                                                    <TableCell align="center">
                                                        {format(new Date(row.subscriptionJson.current_period_start * 1000), 'MM/dd/yyyy')
                                                        + ' to '
                                                        + format(new Date(row.subscriptionJson.current_period_end * 1000), 'MM/dd/yyyy')}
                                                    </TableCell>
                                                    <TableCell align="center">{row.status}</TableCell>
                                                </TableRow> : null
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </div>
                            : <p>{this.props.t('billing.noSubs')}</p>}
                    </div>
                </div>
            
        )
    }


    /*<Button onClick={()=>this.stripePortal()}>Customer Portal</Button>*/
    /* */

    /* now user objects have subscriptions field, it will include only currently active subscriptions (no old, cancelled, etc) */

    /*this.props.user && this.props.user.stripe_subscriptions_natives.length>0 ? <p>*/
    /* this can be used to control what content user has access to based on plan, etc */
    /*{JSON.stringify(this.props.user.stripe_subscriptions_natives)}</p> : <p>You don't have any valid subscriptions yet</p>*/

}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        user: state.users.user,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (id) => dispatch(getUserThunk(id)),
        addUser: (obj) => dispatch(addUserThunk(obj))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);