import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { IQuestion } from '../../classes/Question';
import { questionListRender, questionListRenderFactory } from '../render/QuestionListRender';
import { IQuestionList } from '../../classes/QuestionsList';
import { ISurvey } from '../../classes/Survey';
import { QuestionService } from '../../services/QuestionService';
import { SurveyService } from '../../services/SurveyService';
import QuestionsData from './QuestionsData';
import SurveyDashboardOverview from './SurveyDashboardOverview/SurveyDashboardOverview';
import ComparativeInformation from './ComparativeInformation/ComparativeInformation';
import {connect} from "react-redux";
import { addUserThunk, getUserThunk } from '../../../redux/users/users.thunk';


interface URLParams {
    id: string;
}

interface IState {
    loading: boolean,
    survey: ISurvey,
    questions: IQuestion[],
    errorMessage: string,
}

interface IProps {
    user: any,
    mapStateToProps: any,
    mapDispatchToProps: any,
    getUser: (id: string) => void,
}

const SurveyDashboard = (props: IProps) => {
    let { id } = useParams<URLParams | any>();

    const [state, setState] = useState<IState>({
        loading: false,
        survey: {} as ISurvey,
        questions: [] as IQuestion[],
        errorMessage: "",
    });
    const [viewQuestionsData, setViewQuestionsData] = useState(false);
    const [viewComparative, setViewComparative] = useState(false);
    const [allSurveys, setAllSurveys] = useState({} as any[]);
    const [titleSurveyMain, setTitleSurveyMain] = useState("");
    useEffect(() => {
        if (id) {
            setState({ ...state, loading: true });
            (async () => {
                //try {
                    setState({
                        ...state,
                        loading: false,
                        survey: await SurveyService.getSurvey(id),
                        questions: await QuestionService.getAllQuestionsOfSurvey(id),
                    });
                    await props.getUser(props.user.id); 
                    console.log(" ******* props.user in effect: ",props.user);
                    setAllSurveys(await SurveyService.getAllSurveysWithResponse(props.user.id));
                    setTitleSurveyMain( await SurveyService.getSurveyTitle( id ) );
                    /*}
                catch (error: any) {
                    setState({
                        ...state,
                        loading: false,
                        errorMessage: error.message,
                    })
                }
                */
            })();
        }
    }, [])
    console.log(" ******** props.user):   ",props.user);
    const questionLists = (questions: IQuestion[]): IQuestionList[] => {
        let questionLists = [] as IQuestionList[];
        let questionList: IQuestionList = {
            type: "",
            questions: [],
            render: questionListRender,
        };
        let previousType = "";
        for (const question of questions) {
            if (question.type != previousType && previousType != "") {
                questionList.type = previousType;
                questionList.render = questionListRenderFactory(previousType);
                questionLists.push(questionList);
                questionList = {
                    type: "",
                    questions: [],
                    render: questionListRender,
                };
            }
            questionList.questions.push(question);
            previousType = question.type;
        }
        questionList.type = previousType;
        questionList.render = questionListRenderFactory(previousType);
        questionLists.push(questionList);
        return questionLists;
    }

    const { loading, survey, questions, errorMessage } = state;
    console.log("FIle SurveyDashboard.tsx: survey: ",survey);
    console.log("FIle SurveyDashboard.tsx: questions: ",questions);
    console.log("FIle SurveyDashboard.tsx: questions2All: ",allSurveys);
    console.log("FIle SurveyDashboard.tsx: titleSurveyMain: ",titleSurveyMain);
    return (
        <div>
            {errorMessage}
            {loading ? "Loading" : ""}
            {
                Object.keys(survey).length > 0 && questions.length > 0 && (
                    viewQuestionsData && <QuestionsData survey={survey} questionLists={questionLists(questions)} setViewQuestionsData={setViewQuestionsData} />
                    ||
                    //viewComparative && <ComparativeInformation survey={survey} questions={questions} setViewComparative={setViewComparative} />
                    viewComparative && <ComparativeInformation survey={survey} questions={allSurveys} currentSurvey={questions} titleSurveyCurrent={titleSurveyMain} setViewComparative={setViewComparative} />
                    ||
                    <SurveyDashboardOverview survey={survey} questions={questions} setViewQuestionsData={setViewQuestionsData} setViewComparative={setViewComparative} />
                )
            }
        </div>
    )
}

const mapStateToProps = (state : any) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
        status: state.users.status
    };
};

const mapDispatchToProps = (dispatch : any) => {
    return {
        getUser: (id:any) => dispatch(getUserThunk(id)),
        addUser: (obj:any) => dispatch(addUserThunk(obj))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDashboard);
