import React,{ useState } from 'react';
import './funtionsUtils.css';

const obtainColorsScore = (value : number)=>{
    return value > 0.33? value > 0.66? "green" : "yellow" : "orange";
};
interface Props {
    value: number;
    rounded?: boolean;
    color?: string;
    text?: string;
    limit?:number;
    sizeMaxWidth?: string;
}
/**
 * 
 * @param   value: recive the value in the card, between 0-5 
 *          rounded: determines if the result should be rounded (an oval) or not
 *          color: determines the color, if it has nothing by default it will be calculated by the value of value
 *          text: What is the text that will be displayed inside the box, if it does not have anything by default, the value of value will be displayed
 *          limit: the limit that it must have, this helps to calculate the value of value that goes between 0 and 1
 * @returns  this creates an oval component that can be a progress bar with some specific color
 */
const MyProgressBar: React.FC<Props> = ({ value, rounded = true , color = 'blue', text = 'holas', limit=5, sizeMaxWidth = '100px'}) => {
    if(color === 'blue') {
        color = obtainColorsScore( value );
    }
    //value = Math.floor(value*100)/100+1;
    value = Math.round(value*100+100)/100;
    if(text === 'holas') {
        text = value.toString();
    }
    const progressStyle = {
        width: `${value+100}%`,
        backgroundColor: color,
        borderRadius: rounded ? '50px' : '0px',
    };
    const containerStyle = {
        maxWidth: sizeMaxWidth,
    }
      
    const textColor = color === 'lightgray' || color === 'white' ? 'black' : 'white';
    return (
        <div className="progress-bar-container" style={containerStyle}>
        <div className="progress-bar" style={progressStyle}>
            <div className="progress-bar-value" >{text}</div>
        </div>
        </div>
    );
};
///************ MyMatrix */

function hexToRGBA(hex: string, opacity: number): string {
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity+0.1})`;
}


interface MatrixSquareProps {
    color: string;
    size: number;
    value: number;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}
const MatrixSquare: React.FC<MatrixSquareProps> = ({ color, size, value, onMouseEnter, onMouseLeave }) => {
    const [showValue, setShowValue] = useState(true); ///cambiar aqui para que se vea o no el numero al inicio por defecto
  

    /**
     * esta parte define el color de fonde de 1 celda, en base a la siguiente funcion, si se quiere que de con el color mandado, modificar esta parte o quitarla nomas
     * TODO: hacer que esta funcion se pueda mandar como parametro general
     */
    const setColorBackground = ()=> {
        let newColor = "";
        if(value >= 0 && value <= 1.62) newColor= "#FB5523";
        if(value >= 1.63 && value <= 2.67) newColor= "#FDD835";
        if(value >= 2.68 && value <= 5) newColor= "#6DAE43";
        //newColor = hexToRGBA(newColor, value/60);
        return newColor;
    }
    color = setColorBackground();

    const handleMouseEnter = () => {
        setShowValue(true);
        onMouseEnter && onMouseEnter();
    };
  
    const handleMouseLeave = () => {
        setShowValue(true);
        //setShowValue(false);
        onMouseLeave && onMouseLeave();
    };
    const squareStyle : React.CSSProperties= {
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: color,
        display: 'inline-block',
        margin: '1px',
        position: 'relative',
    };
  
    const textStyle : React.CSSProperties= {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#000000',
        fontWeight: '200',///esto cambia el ancho de los numeros, mas bajo mas delgao son, se puede usar 'normal' en su lugar
        fontSize: `${size * 0.6}px`,
        display: showValue ? 'block' : 'none',
    };
  
    const overlayStyle: React.CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        content: '""',
        opacity: '0.3',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        zIndex: 1,
    };
  
    return (
        <div
            style={squareStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            >
        <div style={overlayStyle} />
        <div style={textStyle}>{value}</div>
        </div>
    );
  };
  

interface PropsMyMatrix {
    numRows: number;
    numCols: number;
    maxWidth: number;
    maxHeight: number;
    data: {x: number, y: number, valor: number}[];
    color: string;
    rowTitles: string[];
    columnTitles: string[];
}
const MatrixChartct: React.FC<PropsMyMatrix> = ({ numRows, numCols, maxWidth, maxHeight, data, color, rowTitles, columnTitles }) => {
    const squareSize = Math.min(maxWidth / numCols, maxHeight / numRows);
  
    const squares = data.map(({ x, y, valor }) => {
        //console.log("entra con: ",x,y,valor);
        return (
            <MatrixSquare
            key={`${x}-${y}`}
            color={hexToRGBA(color, valor / 60)}
            size={squareSize}
            value={valor}
          />    
        );
    });

    const columnTitleEls = columnTitles.map((title, index) => (
        <div
            key={`col-title-${index}`}
            className="column-title" // Agrega la clase de estilo para el título de columna
            style={{
            display: 'inline-block',
            width: squareSize + 'px',
            textAlign: 'center',
            fontSize: `${squareSize * 0.15}px`,
            fontWeight: 'bold',
            margin: '1px',
            }}
        >
            {title}
        </div>
        ));
        
        const rowTitleEls = rowTitles.map((title, index) => (
        <div
            key={`row-title-${index}`}
            className="row-title" // Agrega la clase de estilo para el título de fila
            style={{
            float: 'left',
            height: squareSize + 'px',
            lineHeight: `${squareSize}px`,
            textAlign: 'center',
            fontSize: `${squareSize * 0.10}px`,
            fontWeight: 'bold',
            margin: '1px',
            width: `${squareSize}px`,
            }}
        >
            {title}
        </div>
        ));
        
        console.log("paso 3: ", squares);
    const tableRows = [];
    for (let i = 0; i < numRows; i++) {
        const rowSquares = squares.slice(i * numCols, (i + 1) * numCols);
        const rowEl = (
        <div key={`row-${i}`} style={{ display: 'flex' }}>
            <div style={{ width: 
                `${squareSize}px`, 
                height: `${squareSize}px`, 
                fontWeight: 'bold', 
                fontSize: `${squareSize * 0.15}px`, 
                margin: '1px', 
                textAlign: 'center' 
            }}>
            {rowTitles[i]}
            </div>
            {rowSquares}
        </div>
        );
        tableRows.push(rowEl);
    }

    const headerEl = (
        <div style={{ display: 'flex', fontWeight: 'bold', marginBottom: '10px', justifyContent: 'center' }}>
        <div style={{ width: `${squareSize}px`, height: `${squareSize}px`, margin: '1px' }}></div>
        {columnTitleEls}
        </div>
    );
    console.log("paso 4: ", tableRows);
    return (
        <div>
        {headerEl}
        {tableRows}
        </div>
    );
};


interface PropsMatrix {
    numRows: number;
    numCols: number;
    maxWidth: number;
    maxHeight: number;
    title: string;
    data: {x:number, y:number, valor:number}[];
    color: string;
    rowTitles?: string[];
    columnTitles?: string[];
}
  
  
const MyMatrix: React.FC<PropsMatrix> = ({ numRows, numCols, maxWidth, maxHeight, title, data, color, rowTitles=[], columnTitles=[] }) => {
    const chartWidth = maxWidth + maxWidth/numCols*1.5;
    const chartHeight = maxHeight + maxHeight/numRows*2.5;
    console.log("myMatrix columnTitles:",columnTitles);
    return (
        <div
        style={{
            width: chartWidth,
            height: chartHeight, 
            padding: '20px',
            margin: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
        >

        <div style={{
            width: '100%',
            paddingBottom: '10px',
            marginBottom: '10px'
        }}>
            <h2 style={{ margin: '0', fontSize:'18px', fontWeight:'medium', color:'#1A237E', fontFamily: "'IBM Plex Sans', sans-serif" }}>{title}</h2>
        </div>
        <MatrixChartct numRows={numRows} numCols={numCols} maxWidth={maxWidth} maxHeight={maxHeight} data={data} color={color} rowTitles={rowTitles} columnTitles={columnTitles}/>
        </div>
    );
};



export { MyProgressBar, obtainColorsScore, MyMatrix };

/**
 * OSCURECER TODO EL CUADRADO Y EL NUMERO
 * 
  const MatrixSquare: React.FC<MatrixSquareProps> = ({ color, size, value, onMouseEnter, onMouseLeave }) => {
    const [showValue, setShowValue] = useState(false);
  
    const handleMouseEnter = () => {
      setShowValue(true);
      onMouseEnter && onMouseEnter();
    };
  
    const handleMouseLeave = () => {
      setShowValue(false);
      onMouseLeave && onMouseLeave();
    };
  
    const squareStyle : React.CSSProperties= {
      width: `${size}px`,
      height: `${size}px`,
      backgroundColor: color,
      display: 'inline-block',
      margin: '1px',
      position: 'relative',
    };
  
    const textStyle : React.CSSProperties= {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: `${size * 0.6}px`,
      display: showValue ? 'block' : 'none',
    };
  
    return (
      <div
        className="matrix-square"
        style={squareStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div style={textStyle}>{value}</div>
      </div>
    );
  };
 */