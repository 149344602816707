import ProfileInfo from "./profile-info";
import {Info} from "@mui/icons-material";
import ResentSurveyIndex from "./resent-surveys/resent-survey-index";
import {useMsal} from "@azure/msal-react";
import React from "react";

const Dashboard = () => {
    const {accounts} = useMsal();
    return (
        <div className="row">
            <div className="col-lg-3">
                <h3 className="text-black-50 mb-3">
                    Your Profile
                    <Info fontSize="small" color="disabled" className="ms-3"/>
                </h3>
                <ProfileInfo user={accounts[0]?.idTokenClaims}/>
            </div>
            <div className="col-lg-9">
                <ResentSurveyIndex/>
            </div>
        </div>
    )
}

export default Dashboard;