import {Button, capitalize, Card, CardContent, Divider, Typography} from "@mui/material";
import CircularProgressLabel from "../layouts/CircularProgressLabel";

const ProfileInfo = (props) => {
    const {user} = props;
    return (
        <Card className="mb-3">
            <CardContent className="text-center">
                <div className="my-4">
                    <CircularProgressLabel value={93}/>
                </div>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    <b>{`${capitalize(user.extension_firstName)} ${capitalize(user.extension_lastName)}`}</b>
                </Typography>
                <Typography sx={{fontSize: 11, mb: 1.5}} color="text.secondary">
                    {user.emails[0]}
                </Typography>
                <Typography variant="body2">
                    <span className="text-info">Job role</span> at <span className="text-info">Organization</span>
                </Typography>

                <Divider className="my-2"/>

                <p className="text-start">
                    <small><b>Plan Type: </b> BASIC</small>
                </p>
                <p className="text-secondary">
                    <small>Join 9 months ago</small>
                </p>
                <Button size="small" variant="contained" color="warning">UPGRADE</Button>
            </CardContent>
        </Card>
    )
}

export default ProfileInfo;