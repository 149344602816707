import { Alert, AlertTitle, Button, ButtonGroup, Card, CardContent, Collapse, IconButton } from "@mui/material";
import {AddLink, Close, ContentCopyOutlined, Share} from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {Link, useParams} from "react-router-dom";

const SaveIndex = () => {
    const [isCopied, setIsCopied] = useState(false);
    const [open, setOpen] = useState(true);
    const { t } = useTranslation();

    // const path = window.location.pathname.split('/')
    // console.log(path);
    // const id = path[path.length - 1];
    const {id} = useParams();

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    }

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div>
                <div className="py-3 my-3 text-end">
                    <Link to={`/`} style={{textDecoration: 'none'}}>
                        <Button variant="contained">Back</Button>
                    </Link>
                </div>
                <Collapse in={open} className="my-3">
                    <Alert severity="success" className="w-100" action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}>
                            <Close fontSize="inherit" />
                        </IconButton>}>
                        <AlertTitle>{t("success")}</AlertTitle>
                        {t('save.success')}
                    </Alert>
                </Collapse>
                <Card>
                    <CardContent>
                        <p>{t('save.message')}</p>
                        <ButtonGroup disableElevation variant="contained" className="w-100 my-3">
                            <Button color="primary"
                                className="w-100 mx-0 br-none-first"
                                startIcon={<AddLink sx={{ opacity: { md: 1, xs: 0 } }} />}
                                disabled>
                                {`${process.env.REACT_APP_REDIRECT_URI}/r/${id}`}
                            </Button>
                            <CopyToClipboard text={`${process.env.REACT_APP_REDIRECT_URI}/#/r/${id}`} onCopy={onCopyText}>
                                <Button className="px-3 mx-0 br-none-last">
                                    <ContentCopyOutlined />
                                </Button>
                            </CopyToClipboard>
                        </ButtonGroup>
                        <div className="text-center">
                            <CopyToClipboard text={`${process.env.REACT_APP_REDIRECT_URI}/r/${id}`} onCopy={onCopyText}>
                                <Button variant="contained" color="primary" size="large" className="my-2" startIcon={<Share />}>
                                    {t('save.share')}
                                </Button>
                            </CopyToClipboard>
                        </div>
                    </CardContent>
                </Card>
                {isCopied ?
                    <Alert severity="success" className="alert-style">{t('save.shareAlert')}</Alert> : <></>}
            </div>
        </div>
    )
}

export default SaveIndex;
