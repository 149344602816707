import React, {Component} from 'react';
import {PowerBIEmbed} from 'powerbi-client-react';
import {models} from 'powerbi-client';
import "./ResponsesReport.css";
import axios from 'axios';
import {connect} from "react-redux";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";

import {getSingleSurveysWithQandAThunk} from "../redux/survey/survey.thunk";
import {Link} from "react-router-dom";
import {Button, CircularProgress, Skeleton} from "@mui/material";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../assets/styles/theme";
import {withParams} from "../helpers/with-params";


// let filter = [
//   {
//     $schema: "http://powerbi.com/product/schema#advanced",
//     target: 
//     { table: "public slack_questions", 
//     column: "slack_survey_id" }, 
//     filterType: 0, 
//     logicalOperator: "And", 
//     conditions: [{ operator: "Is", value: 34 }]
//   }];


class ResponsesReport extends Component {


    state = {
        id: null,
        config: null,
        loading: true,
        style: "report-loading",
        slicerSet: false

    }

    async componentDidMount() {
        console.log(window);

        console.log(window.innerWidth);
        console.log(window.innerHeight);
        console.log("calling backend");
        let res = await axios.get("https://survey-app-backend.azurewebsites.net/api/pbi/getEmbedInfoNative");
        console.log('RESSSSSSSS', res);

        let config = {
            type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
            id: res.data.embedUrl[0].reportId,
            embedUrl: res.data.embedUrl[0].embedUrl,
            accessToken: res.data.accessToken,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
            settings: {
                panes: {
                    filters: {
                        expanded: false,
                        visible: false
                    }
                },


                layoutType: models.LayoutType.Custom,
                customLayout: {
                    displayOption: models.DisplayOption.FitToPage
                }
            }
        };

        // if(window.innerWidth < window.innerHeight){
        //   console.log("MOBILE")
        //   config.settings.layoutType = models.LayoutType.MobilePortrait
        //   delete config.settings.customLayout;
        // }

        this.setState({config: config});

        /*const path = window.location.pathname.split('/')
        // console.log(path);
        const id = path[path.length - 1]*/
        const {id} = this.props.params;
        this.setState({id: id});
        await this.props.getSingleSurvey(id);
        this.setState({loading: false});
        console.log('GET SURVEY');
    }


    // componentDidMount(){
    //   this.setState
    // }

    setFilters = async () => {
        console.log('Report loaded');
        let activePage = await window.report.getActivePage();


        // console.log(activePage);
        let filter = await activePage.getFilters();
        console.log('1');
        filter[0].conditions[0].value = this.props.singleSurvey.id;


        // console.log(window.location);


        try {
            await activePage.updateFilters(models.FiltersOperations.Replace, [filter[0]]);
            console.log('2');
        } catch (error) {
            console.log(error);
        }


        const visuals = await activePage.getVisuals();
        console.log('3');
        //console.log("VISUALS");
        //console.log(visuals);


        // Retrieve the target visual.
        let slicer = visuals.filter(function (visual) {
            return visual.type === "slicer";
        })[0];

        // Get the slicer state
        let state = await slicer.getSlicerState();
        console.log('4');

        // console.log(state);

        state.filters[0].values = [this.props.singleSurvey.native_questions[0].content];

        await slicer.setSlicerState({filters: [state.filters[0]]});
        console.log('5');

        //console.log(slicer);
        //console.log("Date slicer was set.");
        this.setState({slicerSet: true});
        //this.setState({ style: "report-style-class"  });
        console.log("SLICER!!!");

    }

    onRender = () => {

        console.log('Report rendered')
        //if (this.state.slicerSet == true) {
            this.setState({style: "report-style-class"});
        //}
    }


    render() {

        console.log(this.props);
        return (
            <div>
                {/*<AuthenticatedTemplate>*/}
                    <div className="py-3 text-end">
                        <Link to={`/surveys`} style={{textDecoration: 'none'}}>
                            <Button variant="contained">{this.props.t('option.back')}</Button>
                        </Link>
                    </div>
                {/*!this.state.loading && !this.state.slicerSet ?
                    <div className="text-center">
                        <Skeleton variant="circle" width="50%" height={20}/>
                        <Skeleton variant="rect" width="75%" height={50} className="my-3"/>
                        <Skeleton variant="rect" width="100%" height={100} />
                    </div> : null*/}
                <div className="d-flex justify-content-center">
                    <MutatingDots
                        color={palette.primary}
                        height="100"
                        width="100"
                        visible={!this.state.loading && !this.state.slicerSet}>
                    </MutatingDots>
                </div>
                {!this.state.loading && !this.state.slicerSet ? <p className="text-center">Rendering Report...</p> : null}

                <div className="d-flex justify-content-center">
                    <MutatingDots
                        color={palette.primary}
                        height="100"
                        width="100"
                        visible={this.state.loading}/>
                </div>

                    {this.state.loading? null :
                        <PowerBIEmbed
                            embedConfig={this.state.config}

                            eventHandlers={new Map([
                                ['loaded', (this.setFilters)],
                                ['rendered', this.onRender],
                                ['error', function (event) {
                                    console.log(event.detail);
                                }]
                            ])
                            }

                            cssClassName={
                                this.state.style
                            }

                            getEmbeddedComponent={
                                (embeddedReport) => {
                                    window.report = embeddedReport;
                                }
                            }/>
                    }

                {/*</AuthenticatedTemplate>
                <UnauthenticatedTemplate>Please sign-in to view this page</UnauthenticatedTemplate>*/}
            </div>
        )
    }

}


// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        allSurveys: state.surveys.allSurveys,
        singleSurvey: state.surveys.singleSurvey,

    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        // getAllSurveysWithScheduleSlack: (teamId) => dispatch(getAllSurveysWithScheduleSlackThunk(teamId)),
        getSingleSurvey: (id) => dispatch(getSingleSurveysWithQandAThunk(id))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withParams(ResponsesReport));
