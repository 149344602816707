//THUNKS
import axios from "axios";
import imgEmployee from '../../assets/employe engagement.png';
import imgEnps from '../../assets/eNPS.png';
import imgEmploySatisfaction from '../../assets/Employee Satisfaction Survey.png';
import imgAssessment from '../../assets/Assessment of the incentive system and benefits.png';
import imgRelationship from '../../assets/Relationship with Manager.png';
import imgStress from '../../assets/Stress at work.png';
import {
    addJSONtoSurvey,
    addNewAnswer,
    addNewQuestion,
    addNewResponse,
    addNewSurvey,
    changeStatus,
    deleteSurvey,
    errorSurvey,
    getAllSurveys,
    getAllTemplates,
    getSingleSurvey,
    getSingleSurveyWithQandA,
    getSingleTemplate,
    increaseResponsesCount,
    saveResponses,
    updateSurvey
} from "./survey.actions";
import {
    addJSONtoSurveyService,
    addNewAnswerService, addNewFullSurveyService,
    addNewQuestionService,
    addNewSurveyService,
    getAllSurveysService,
    getAllTemplatesService,
    getSingleTemplateService,
    increaseResponsesCountService,
    saveResponsesService,
    updateSurveyService
} from "../../services/survey.service";

export const addNewSurveyThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeStatus('loading'));
        try {
            const data = await addNewSurveyService(obj);
            console.log("data", data);
            if (!data.error) {
                dispatch(addNewSurvey(data));
                dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
            dispatch(errorSurvey(error));
        }
    };
};

export const addNewFullSurveyThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeStatus('loading'));
        try {
            const data = await addNewFullSurveyService(obj);
            console.log("data", data);
            if (!data.error) {
                dispatch(addNewSurvey(data));
                dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
            dispatch(errorSurvey(error));
        }
    };
};

export const addNewQuestionThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeStatus('loading'));
        try {
            const data = await addNewQuestionService(obj);
            console.log("data", data);
            if (!data.error) {
                dispatch(addNewQuestion(data));
                dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const addNewAnswerThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeStatus('loading'));
        try {
            const data = await addNewAnswerService(obj);
            console.log("data", data);
            if (!data.error) {
                dispatch(addNewAnswer(data));
                dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const addJSONtoSurveyThunk = (id, obj) => {
    return async (dispatch) => {
        dispatch(changeStatus('loading'));
        try {
            const data = await addJSONtoSurveyService(id, obj);
            console.log("data", data);
            if (!data.error) {
                dispatch(addJSONtoSurvey(data));
                dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};


//outdated, gets handled in backend by saveResponsesThunk
export const increaseResponsesCountThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeStatus('loading'));
        try {
            const data = await increaseResponsesCountService(id);
            console.log("data", data);
            if (!data.error) {
                dispatch(increaseResponsesCount(data));
                dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};


export const getAllSurveysThunk = (userId) => {
    return async (dispatch) => {
        dispatch(changeStatus('loading'));
        try {
            const data = await getAllSurveysService(userId);
            console.log("data", data);
            if (!data.error) {
                dispatch(getAllSurveys(data));
                dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

// export const getAllSurveysWithScheduleThunk = (tenantId) => {
//   return async (dispatch) => {
//     try {
//       const { data } = await axios.get(
//         `https://survey-app-backend.azurewebsites.net/api/surveys/allSurveysWithSchedule/${tenantId}`
//       );
//       console.log("data", data);
//       if (!data.error) dispatch(getAllSurveysWithSchedule(data));
//     } catch (error) {
//       console.error(error);
//     }
//   };
// };

export const getSingleSurveyThunk = (id) => {
    return async (dispatch) => {
        try {
            const {data} = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/native/surveys/singleSurvey/${id}`
            );
            console.log("data", data);
            if (!data.error) dispatch(getSingleSurvey(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const addNewResponseThunk = (obj) => {
    return async (dispatch) => {
        try {
            const {data} = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/native/responses/newResponse`,
                obj
            );
            console.log("data", data);
            if (!data.error) dispatch(addNewResponse(data));
        } catch (error) {
            console.error(error);
        }
    };
};

// export const getAllQuestionsBySurveyIdThunk = (id) => {
//   return async (dispatch) => {
//     try {
//       const { data } = await axios.get(
//         `https://survey-app-backend.azurewebsites.net/api/questions/allQuestions/${id}`
//       );
//       console.log("data", data);
//       if (!data.error) dispatch(getAllQuestionsBySurveyId(data));
//     } catch (error) {
//       console.error(error);
//     }
//   };
// };

export const getSingleSurveysWithQandAThunk = (id) => {
    return async (dispatch) => {
        try {
            const {data} = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/native/surveys/singleSurveyFull/${id}`
            );
            console.log("data", data);
            if (!data.error) dispatch(getSingleSurveyWithQandA(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const deleteSurveyThunk = (id) => {
    return async (dispatch) => {
        try {
            const {data} = await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/api/native/surveys/deleteSurvey/${id}`
            );
            console.log("data", data);
            if (!data.error) dispatch(deleteSurvey());
        } catch (error) {
            console.error(error);
        }
    };
};

export const getAllTemplatesThunk = () => {
    return async (dispatch) => {
        dispatch(changeStatus('loading'));
        try {
            let data = await getAllTemplatesService();
            /*data = data.map((item, index) => {
                switch (index) {
                    case 0:
                        // item.img = imgEnps;
                        item.img = 'https://riskandinsurance.com/wp-content/uploads/2019/09/burnout-700x525.jpg';
                        //item.img = imgEmployee;
                        break;
                    // case 1: item.img = 'https://www.openmet.com/wp-content/uploads/2016/02/nps-openmet.jpg';break;
                    case 1:
                        item.img = imgEnps;
                        break;
                    //case 2: item.img = 'https://s3-ap-southeast-1.amazonaws.com/s3-studilmu-ap-southeast-1.amazonaws.com/image/blogs/1539578130_tingkatkanemployeeengagement.jpg';break;
                    case 2:
                        item.img = imgEmployee;
                        
                        break;
                    case 3:
                        item.img = imgEmploySatisfaction;
                        break;
                    case 4:
                        item.img = imgAssessment;
                        break;
                    case 5:
                        item.img = imgRelationship;
                        break;
                    case 6:
                        item.img = imgStress;
                        break;
                    case 7:
                        item.img = 'https://riskandinsurance.com/wp-content/uploads/2019/09/burnout-700x525.jpg';
                        break;
                    case 8:
                        item.img = 'https://riskandinsurance.com/wp-content/uploads/2019/09/burnout-700x525.jpg';
                        break;
                    default:
                        item.img = 'https://www.myhrtoolkit.com/hubfs/differences-employee-worker.jpg';
                        break;
                }
                return item;
            })*/
            console.log("dataTemplate", data);
            if (!data.error) {
                dispatch(getAllTemplates(data));
                dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getSingleTemplateThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeStatus('loading'));
        try {
            const data = await getSingleTemplateService(id);
            console.log("data", data);
            if (!data.error) {
                dispatch(getSingleTemplate(data));
                dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const saveResponsesThunk = (surveyId, responses, userId) => {
    return async (dispatch) => {
        dispatch(changeStatus('loading'));
        try {
            const {data, status} = await saveResponsesService(surveyId, responses, userId);
            console.log("data", data);
            if (!data.error) {
                dispatch(saveResponses(data));
                dispatch(changeStatus('loaded'));

            }
            return status;
        } catch (error) {
            console.error(error);
        }
    };
};


export const updateSurveyThunk = (surveyId, changes) => {
    return async (dispatch) => {

        try {
            const data = await updateSurveyService(surveyId, changes);
            console.log("data", data);
            if (!data.error) {
                dispatch(updateSurvey(data));
            }
        } catch (error) {
            console.error(error);
        }
    };
}

// //not deleting, but updating
// export const deleteScheduleThunk = (id) => {
//   return async (dispatch) => {
//     try {
//       const { data } = await axios.put(
//         `https://survey-app-backend.azurewebsites.net/api/schedules/editSchedule/${id}`, {state: false}
//       );
//       console.log("data", data);
//       if (!data.error) dispatch(deleteSchedule());
//     } catch (error) {
//       console.error(error);
//     }
//   };
// };

// export const addScheduleThunk = (obj) => {
//   return async (dispatch) => {
//     try {
//       const { data } = await axios.post(
//         `https://survey-app-backend.azurewebsites.net/api/schedules/newSchedule`, obj
//       );
//       console.log("data", data);
//       if (!data.error) dispatch(addSchedule(data));
//     } catch (error) {
//       console.error(error);
//     }
//   };
// };
