import React from 'react'
import { MyProgressBar } from '../../../../helpers/funtionsUtils/funtionsUtils';
import './SentimentBar.css';
interface Props {
  score: number,
}

const SentimentBar = (props: Props) => {
  return (
    <>
        <div className="bg-gray-200 rounded">
            <MyProgressBar value={props.score+100}/>
        </div>
    </>
  )
}

export default SentimentBar;