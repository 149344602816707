import {List} from "@mui/material";
import CopyPasteSurveyListItem from "./copy-paste-survey-list-item";

const CopyPasteSurveyList = (props) => {
    const {surveys} = props;
    return (
        <List className="w-100 bg-white">
            {surveys.map((item, index) =>
                <CopyPasteSurveyListItem key={index} item={item} last={index === surveys.length - 1}/>
            )}
        </List>
    )
}

export default CopyPasteSurveyList;