import {
    Avatar,
    Badge,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Slider,
    Typography
} from "@mui/material";
import {Cancel, Check, Crop, CropRotate} from "@mui/icons-material";
import * as React from "react";
import {Component} from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./crop-image";
import {updateProfilePhotoThunk} from "../../../redux/users/users.thunk";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../../../assets/styles/theme";

const style = {
    textImg: {
        color: 'white',
        position: 'absolute',
        textShadow: '0 1px 2px rgba(0, 0, 0, 0.8)',
        fontWeight: 600,
        fontSize: '0.7em',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0
    }
}

class UserEditPhotoIndex extends Component {
    state = {
        photoUrl: `${process.env.REACT_APP_BACKEND_URL}/api/native/users/download/${this.props.user.photo}`,
        file: null,
        edit: false,
        crop: {x: 0, y: 0},
        zoom: 1,
        rotation: 0,
        croppedAreaPixels: null,
        status: 'initial'
    }

    constructor(props) {
        super(props);
        this.setState({
            photoUrl: `${process.env.REACT_APP_BACKEND_URL}/api/native/users/download/${this.props.user.photo}`,
            file: null,
            edit: false,
            crop: {x: 0, y: 0},
            zoom: 1,
            rotation: 0,
            croppedAreaPixels: null
        });
    }

    componentDidMount() {
        console.log('paso por el montado');
    }

    cropComplete = (croppedArea, croppedAreaPixels) => {
        // setCroppedAreaPixels(croppedAreaPixels);
        this.setState({
            croppedAreaPixels: croppedAreaPixels
        })
    }

    handleClose = () => {
        this.props.onClose();
    };

    handleChargeImg = (e) => {
        if (e.target.files.length > 0) {
            const currentFile = e.target.files[0];
            this.setState({
                file: currentFile,
                photoUrl: URL.createObjectURL(currentFile),
                edit: true,
                zoom: 1,
                rotation: 0
            });
            /*setFile(currentFile);
            setPhotoUrl(URL.createObjectURL(currentFile));
            setEdit(true);
            setZoom(1);
            setRotation(0);*/
        }
    }

    cropImage = async () => {
        try {
            const {
                file,
                url
            } = await getCroppedImg(this.state.photoUrl, this.state.croppedAreaPixels, this.state.rotation);
            this.setState({
                photoUrl: url,
                file: file,
                edit: false,
                zoom: 1,
                rotation: 0,
            });
            console.log('paso 2', this.state);
            /*setPhotoUrl(url);
            setFile(file);
            setEdit(false);*/
        } catch (e) {
            console.error(e);
        }
    }

    onStore = async (e) => {
        //if (confirm('Are you sure to update your profile picture?')) {
        e.preventDefault();
        const formData = new FormData();
        formData.append('image', this.state.file);
        if (this.props.user?.id) {
            await this.props.updateProfilePhoto(formData, this.props.user.id);
            this.handleClose();
            toastr.success(this.props.t('success') + '!', this.props.t('user.successSavePhoto'));
        }
        //}
    }

    render() {
        const {photoUrl, file, edit, crop, zoom, rotation} = this.state;
        const {open, user, status, t} = this.props;
        return (
            <Dialog onClose={this.handleClose} open={open}>
                <DialogTitle>Profile Picture</DialogTitle>
                <DialogContent className="text-center"
                               sx={{
                                   background: !edit ? 'none' : '#333',
                                   position: 'relative',
                                   height: !edit ? 'auto' : 400,
                                   width: 'auto',
                                   minWidth: {sm: edit ? 500 : 'auto'}
                               }}
                >
                    {!edit ?
                        status === 'loading' ?
                            <div>
                                <div className="d-flex justify-content-center">
                                    <MutatingDots
                                        color={palette.primary}
                                        height="100"
                                        width="100"
                                        visible={true}/>
                                </div>
                                <p className="text-center">{t('status.loadingMessage')}... </p>
                            </div> :
                            <Badge overlap="circular"
                                   anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                                   badgeContent={
                                       file ? <IconButton className="bg-white" color="primary"
                                                          onClick={() => this.setState({edit: true})}>
                                           <CropRotate/>
                                       </IconButton> : null
                                   }>
                                <label htmlFor="img"
                                       style={{cursor: 'pointer', width: 190, height: 190, position: 'relative'}}>
                                    <Avatar className="mx-2"
                                            alt={user?.firstName}
                                            sx={{width: 190, height: 190}}
                                            src={photoUrl}/>
                                    <span style={style.textImg}>{t('user.selectPhoto')}</span>
                                </label>
                            </Badge> :
                        <Cropper image={photoUrl || null}
                                 crop={crop}
                                 zoom={zoom}
                                 rotation={rotation}
                                 aspect={1}
                                 onZoomChange={(val) => this.setState({zoom: val})}
                                 onRotationChange={(val) => this.setState({rotation: val})}
                                 onCropChange={(val) => this.setState({crop: val})}
                                 onCropComplete={this.cropComplete}
                        />
                    }

                    <input type="file" id="img" accept="image/*" style={{display: 'none'}} name="img"
                           onChange={this.handleChargeImg}/>
                </DialogContent>
                {!edit ? <DialogActions disableSpacing={true} className="justify-content-around">
                        {/*<label htmlFor="img">
                        <Button color="success" variant="outlined" startIcon={<Edit/>}>
                            Change
                        </Button>
                    </label>*/
                            /*<Button color="error" variant="outlined" startIcon={<Delete/>}>
                                    Remove
                                </Button>*/}
                        {file && status !== 'loading' ?
                            <form onSubmit={this.onStore}>
                                <Button variant="contained" startIcon={<Check/>} type="submit">
                                    {t('save')}
                                </Button>
                            </form> : null}
                    </DialogActions> :
                    <DialogActions sx={{flexDirection: 'column', mx: 3, my: 2}}>
                        <Box sx={{width: '100%', mb: 1}}>
                            <Box>
                                <Typography>{t('user.zoom')}: {zoomPercent(zoom)}</Typography>
                                <Slider valueLabelDisplay="auto"
                                        valueLabelFormat={zoomPercent}
                                        min={1}
                                        max={3}
                                        step={0.01}
                                        value={zoom}
                                        onChange={(e, zoom) => this.setState({zoom})}
                                />
                            </Box>
                            <Box>
                                <Typography>{t('user.rotation')}: {rotation}</Typography>
                                <Slider valueLabelDisplay="auto"
                                        min={0}
                                        max={360}
                                        value={rotation}
                                        onChange={(e, rotation) => this.setState({rotation})}
                                />
                            </Box>
                        </Box>
                        <Box disableSpacing={true} className="d-flex justify-content-around">
                            <Button variant="outlined"
                                    startIcon={<Cancel/>}
                                    onClick={() => this.setState({edit: false})}
                            >{t('cancel')}</Button>

                            <Button variant="contained"
                                    startIcon={<Crop/>}
                                    onClick={this.cropImage}
                            >{t('user.crop')}</Button>
                        </Box>
                    </DialogActions>
                }
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        user: state.users.user,
        status: state.users.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProfilePhoto: (obj, id) => dispatch(updateProfilePhotoThunk(obj, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPhotoIndex);

const zoomPercent = (value) => {
    return `${Math.round(value * 100)}%`
}