import React, { Dispatch, SetStateAction } from 'react'
import { IQuestionList } from '../../classes/QuestionsList';
import { ISurvey } from '../../classes/Survey';
import ReturnBar from '../generic/ReturnBar/ReturnBar';

interface Props {
  survey: ISurvey;
  questionLists: IQuestionList[],
  setViewQuestionsData: Dispatch<SetStateAction<boolean>>,
}

const QuestionsData = (props: Props) => {
  console.log("Question data gg: ", props);
  let indexEnd = 1;
  const getIndexEnd = (n: number): void => {
    indexEnd = n;
  }
  return (
    <>
      <ReturnBar title={props.survey.title} setToggle={props.setViewQuestionsData} value={false}/>
      <div className=" ">
        {
          props.questionLists.map((questionList, index) =>
            <div key={index}>
              {
                questionList.render({
                  questions: questionList.questions,
                  indexStart: indexEnd,
                  getIndexEnd: getIndexEnd
                })
              }
            </div>
          )
        }
      </div>
    </>
  )
}

export default QuestionsData;