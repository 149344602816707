import { ISurvey } from '../classes/Survey';
import { IQuestion } from "./Question";
import { differenceInDays, differenceInMonths, differenceInYears } from 'date-fns';

interface IChartConfig {
    options: any,
    data: any,
}
/**
 * base class for charts, this uses the charts.js library
 * what this does is configure the options correctly, now it gives you some general options and 
    its child classes will give the specific options required for each type of graph
 * 
 */
export abstract class ChartConfig {
    protected chartConfig: IChartConfig = { options: {}, data: { labels: [], datasets: [{}, {}] } };
    protected question: IQuestion;
    protected valueIterator = 0;
    protected numberRandom = 0;
    constructor(question: IQuestion) {
        this.question = question;
        console.log("entra constructor question:",question);
        this.chartConfig.data.datasets=[{data:[]}];
    }

    /// basic options for everything
    protected options(): void {
        this.chartConfig.options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top' as const,
                    display: false
                },
                title: {
                    display: true,
                },
            },
        };
    }

    protected labels_chartConfig(): void {
        console.log("call labels chartConfig")
        //this.chartConfig.data.labels = ["label1", "label2", "label3", "label4"];
        this.chartConfig.data.labels = this.question.choiceQuestionProps?.choices;
    }

    /// test data only
    protected data_chartConfig(): void {
        this.chartConfig.data.datasets = [
            {
                ...this.chartConfig.data.datasets[0],
                //data: this.question.choiceQuestionProps?.choiceValues,
                //data: [1, 2, 3, 10],
                /*backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(255, 205, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)'
                ],*/
            },
        ]
    }


    /**
     * This and the following function serve to simulate the operation of the rg between() function, which interpolates colors, this because this function is not enabled for tsx
     * @param hex 
     * @returns 
     */
    protected hexToRgb(hex: string) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            red: parseInt(result[1], 16),
            green: parseInt(result[2], 16),
            blue: parseInt(result[3], 16)
        } : null;
    }
    protected interpolateColor(startRgb: string, endRgb: string | undefined, t: number): string {
        const start = this.hexToRgb(startRgb);
        const end = endRgb ? this.hexToRgb(endRgb) : this.hexToRgb(startRgb);
        if (!start || !end) {
            return startRgb;
        } else {
            
            const result = {
                r: Math.round(start.red + (end.red - start.red) * t),
                g: Math.round(start.green + (end.green - start.green) * t),
                b: Math.round(start.blue + (end.blue - start.blue) * t),
            };
            return `rgb(${result.r}, ${result.g}, ${result.b})`;    
        }
    }
    /**
     * This function is mainly for bars, what it does is that given the number of questions, 
     *   it generates colors with the interpolation function of interpolateColor
     *   this to generate the necessary number of colors for all questions
     */
    protected colors(): void {
        ///color management
        console.log("char data color: ",this.chartConfig);
        let backgroundColors = [
            `#FB5523`,
            `#FB744B`,
            `#FC9373`,
            `#FDB29B`,
            `#FDD835`,
            `#FFDE5C`,
            `#B1D798`,
            `#9BCC7B`,
            `#85C05D`,
            `#6DAE43`,
        ];
        let borderColors = [
            `#FB5523`,
            `#FB744B`,
            `#FC9373`,
            `#FDB29B`,
            `#FDD835`,
            `#FFDE5C`,
            `#B1D798`,
            `#9BCC7B`,
            `#85C05D`,
            `#6DAE43`,
        ];
        
        let step = 1;
        if(this.chartConfig.data.datasets[0].data && this.chartConfig.data.datasets[0].data.length > 1) {
            step = 1 / (this.chartConfig.data.datasets[0].data.length - 1);
        }
        let newBackgroundColors = [];
        let newBorderColors = []

        for(let start = 0; start <= 1; start += step) {
            newBackgroundColors.push(this.interpolateColor(backgroundColors[0], backgroundColors[backgroundColors.length - 1], start));
            //newBorderColors.push(this.interpolateColor(borderColors[0], borderColors[borderColors.length - 1], start));
        }
        console.log("new backoug,",newBackgroundColors);
        this.chartConfig.data.datasets = [
            {
                ...this.chartConfig.data.datasets[0],
                //data: [1, 2, 3, 5],
                backgroundColor: newBackgroundColors,
            },
        ]
    }

    ///load the test dataset first
    protected others(): void {
        this.chartConfig.data.datasets = [
            {
                ...this.chartConfig.data.datasets[0],
                //label: 'Dataset 1',
            },
        ];
    }
    
    ///chart config setter and getter for its child classes
    public getChartConfig () : IChartConfig {
        return this.chartConfig;
    }
    public setChartConfig(value : IChartConfig) : void {
        this.chartConfig = value;
    }

    //This function is important, it is the one that calls and gives the basic default values of configurations
    public createChartConfigChart(): IChartConfig {
        this.labels_chartConfig();
        //this.data_chartConfig();
        this.others();
        this.options();
        this.colors();
        return this.chartConfig;
    }
}

export class RatingChartConfig extends ChartConfig {
    ///bar graph         console.log("bar graph questions: ")
    constructor(question: IQuestion) {
        super(question);

        const chartConfigs = this.createChartConfigChart();
        console.log("*** question:",question);
        console.log(" chartConfigs: ",chartConfigs);
        console.log("Soy bar graph: ",question.score);
        //chartConfigs.data.datasets[0].data.push( question.score );
        //this.data();
        //this.setChartConfig(chartConfigs);
    }
    public createChartConfig() : IChartConfig {
        console.log("createChartconfig nps?1");
        this.data();
        const chartConfigs = this.createChartConfigChart();

        chartConfigs.options = {
            //...chartConfigs.options,
            indexAxis: 'x',
            scales: {
                x: {
                    type: 'category',
                },
            },
            responsive: true,
            barThickness: 'flex',
            tooltips: {
                enabled: false,
            },
            /*responsive: true,
            maintainAspectRatio: false,

            aspectRatio: window.innerWidth / window.innerHeight, // agregar esta opción

            datasets: [{
            label: 'My First Dataset',
            borderWidth: 1,
            }]*/
        }
        console.log("antes:",chartConfigs);
        if( !this.isENPSSurvey()) {
            console.log("entra isENPSSurvey ");
            chartConfigs.options = {
                ...chartConfigs.options,
                indexAxis: 'y',
                responsive:true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        stacked: false,
                        grid: {
                            display: true,    
                        },
                        ticks:{
                            display: true,
                            beginAtZero: true,
                        },
                    },
                    x: {
                        stacked: false,
                        grid: {
                            display: true,
                        },
                    },
                },
                plugins: [{
                    title: {
                        display: true,
                    },
                    legend: {
                        display: false
                    },
                    datalabels: {
                        labels: {
                            label1: {
                                formatter: function(value:any, context:any) {
                                    return Math.round((value/chartConfigs.data.datasets[0].data.reduce((a:any, b:any) => a + b, 0))*100) + '%';
                                }
                            },
                            label2: {
                                anchor: 'end',
                                align: 'end',
                                formatter: Math.round,
                                font: {
                                    weight: 'medium',
                                    size: '14px',
                                    fontFamily: "'IBM Plex Sans', sans-serif",
                                    color: '#1A237E'
                                }
                            }
                        },   
                    },                    
                }, floatingLabels],
            }
            console.log("sale isENPSSurvey");
        }
        console.log("despues: ",chartConfigs)

        this.setChartConfig(chartConfigs);
        return chartConfigs;
    }
    data() {
        //const chartConfigs = this.createChartConfigChart();
        const chartConfigs = this.getChartConfig();
        chartConfigs.data.datasets = [
            {
                ...this.chartConfig.data.datasets[0],
                data: this.question.choiceQuestionProps?.choiceValues,
            },
        ];
        this.setChartConfig(chartConfigs);
        const chartConfigs2 = this.createChartConfigChart();
        this.setChartConfig(chartConfigs2);
    }
    private isENPSSurvey():boolean {
        const questions = ["How likely is it that you would recommend this company to a friend or colleague?"];
        console.log("this.question.title: ",this.question.title);
        for(const valueQuestionsCurr of questions) {
            console.log("comparacion: ",valueQuestionsCurr === this.question.title )
            if(valueQuestionsCurr === this.question.title ) {
                return true;
            }
        }
        return false;
    }

}

export class TextChartConfig extends ChartConfig {
    ///donut
    constructor(question: IQuestion) {
        super(question);
        console.log("Soy donut graph");
    }
    labels() {
        this.chartConfig.data.labels = ["Negative", "Neutral", "Positive"];
    }
    public createChartConfig() : IChartConfig { 
        const chartConfigs = this.createChartConfigChart();
        chartConfigs.options.plugins.legend = {
           position: 'bottom', ///top
        }
        chartConfigs.options.plugins = {
            ...chartConfigs.options.plugins,
            tooltips: {
                enabled: false,
            },
            datalabels: {
                display: false,
            },
        }
        chartConfigs.options.layout = {
            padding: 0,
        };
        chartConfigs.options = {
            ...chartConfigs.options,
            maintainAspectRatio: false,
        }

        this.setChartConfig(chartConfigs);
        this.data_prop();
        return chartConfigs;
    }
    data_prop() {
        const chartConfigs = this.createChartConfigChart();
        const choiceValues: number[] = this.calculateChoiceValues();
        let backgroundColors = [
            '#FB5523',
            '#FDD835',
            '#6DAE43',      
            ];
        let borderColors = [
            '#FB5523',
            '#FDD835',
            '#6DAE43',
        ];

        chartConfigs.data.datasets = [
            {
                ...chartConfigs.data.datasets[0],
                data: choiceValues,
                //data: [1, 2, 3, 10],
                backgroundColor: backgroundColors,
                borderColor: borderColors,
            },
        ]
        this.setChartConfig(chartConfigs);
    }

    private calculateChoiceValues(): number[] {
        let choiceValues = [0, 0, 0];
        if (this.question.textQuestionProps != undefined) {
            for (const x of this.question.textQuestionProps?.sentimentScores) {
                if (x > 0.33) {
                    if (x > 0.66) choiceValues[2]++;
                    else choiceValues[1]++;
                }
                else choiceValues[0]++;
            }
        }
        return choiceValues;
    }
}

export class DimensionsChartConfig extends ChartConfig {
    constructor(
        //private questions: IQuestion[],
        //private filter: string,
        private allSurveys : any[],
        private currentSurvey: IQuestion[],
        private titleSurveyCurrent: string,
        private dimension: string,
        private filter: string,
    ) {
        //super({} as IQuestion)
        console.log("--> llega a consturctor line currentSurvey:",currentSurvey);
        if(Object.keys(allSurveys).length === 0){allSurveys=[]};
        if(Object.keys(currentSurvey).length === 0){currentSurvey=[]};
        console.log("--> llega a consturctor line allSurveys:",allSurveys);
        //super(questions[0]);
        super({} as IQuestion);
        const inforName = currentSurvey[0].surveyId;
        console.log("surveyId:",inforName);
        console.log("sruvey name: ",titleSurveyCurrent);
        console.log("this.titleSurveyCurrent: ",titleSurveyCurrent);
        console.log("this.dimension: ",dimension);
    }
    public createChartConfig() : IChartConfig { 

        const chartConfigs = this.createChartConfigChart();
        chartConfigs.options = {
            responsive: false,
            plugins: {
                title: {
                    display: true,
                },
                legend: {
                    display: false
                },
                datalabels: {
                    formatter: function(value : any, context : any) {
                        return Math.round((value/chartConfigs.data.datasets[0].data.reduce((a:any, b:any) => a + b, 0))*100) + '%';
                    }
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                },
                y: {
                    grid: {
                        display: false
                    },
                },
            },
            layout: {
                padding: 20
            },
            maintainAspectRatio: false,
            borderColor: '#1A237E',
            pointBackgroundColor: '#1A237E',
            pointRadius: 0,
        }
        this.setChartConfig(chartConfigs);
        this.labels();
        this.data();
        console.log("***line 3data chartConfigs.data.datasets: ",chartConfigs.data.datasets);
        return chartConfigs;
    }
    public labels() {
        console.log("lables line");
        const chartConfigs = this.getChartConfig();
        switch (this.filter) {
            case "month":
                chartConfigs.data.labels = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                break;
            case "year":
                chartConfigs.data.labels = ["2022", "2023"];
                break;
            case "week":
                chartConfigs.data.labels = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
                break;
            default: chartConfigs.data.labels = [];
        }
        this.setChartConfig(chartConfigs);
    }

    data() {
        const chartConfigs = this.getChartConfig();
        const choiceValues: number[] = this.calculateChoiceValues();
        console.log("***line data this.question: ",this.question);
        console.log("***line data choiceValues: ",choiceValues);
        chartConfigs.data.datasets = [
            {
                ...chartConfigs.data.datasets[0],
                data: choiceValues,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(255, 205, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)'],
            },
        ]
        console.log("***line 2data chartConfigs.data.datasets: ",chartConfigs.data.datasets);
        this.setChartConfig(chartConfigs);
    }

    private calculateChoiceValues(): number[] {
        switch( this.filter ) {
            case "week": return this.calculateDataLastWeek();
            case "month": return this.calculateDataLastMounth();
            case "year": return this.calculateDataLastYear();
        }
        return [4, 10, 2, 3, 5, 7, 8, 9, 2, 1, 0, 2];
    }
    private obtainValuesResponse() {
        const myAns = new Map();
        const dataSurveyEmployeeEngagement = ["Never","Rarely","Sometimes","Often","Always"];
        const dataSurveyEmployeeBurnout = ["Strongly agree","Agree","Disagree","Strongly disagree"];
        const dataSurveyJobSatisfactionSurvey = ["Very dissatisfied","Dissatisfied","Slightly dissatisfied","Uncertain","Slightly Satisfied","Satisfied","Very Satisfied"];
        let valueNumber = 1;
        switch(this.titleSurveyCurrent) {
            case "Employee Engagement": 
                for(let value of dataSurveyEmployeeEngagement) {
                    myAns.set(value,valueNumber);
                    valueNumber++;
                }
                break;
            case "Employee Burnout": 
                for(let value of dataSurveyEmployeeBurnout) {
                    myAns.set(value,valueNumber);
                    valueNumber++;
                }
                break;
            case "Job Satisfaction Survey": 
                for(let value of dataSurveyJobSatisfactionSurvey) {
                    myAns.set(value,valueNumber);
                    valueNumber++;
                }
                break;
        }
        console.log("Map: ",myAns);
        console.log("this.titleSurveyCurrent: ",this.titleSurveyCurrent);
        return myAns;
    }

    private isValidQuestionDimensionCurr(value : number) {
        let iterator;
        ///this is for Employee Engagement
        const surveyEmployeeEngagement = ["Vigor","Dedication","Absorption"];
        const mapEmployeeEngagement = new Map();
        const numberQuestionSurveyEmployeeEngagement = [1,1,1];
        const cumulativeSumNumberQuestionsurveyEmployeeEngagement = [0];
        
        iterator = 0;
        for(let value of numberQuestionSurveyEmployeeEngagement) {
            cumulativeSumNumberQuestionsurveyEmployeeEngagement.push( cumulativeSumNumberQuestionsurveyEmployeeEngagement[ iterator ] + value);
            mapEmployeeEngagement.set(surveyEmployeeEngagement[iterator], iterator+1);
            iterator++;
        }
        cumulativeSumNumberQuestionsurveyEmployeeEngagement.push( cumulativeSumNumberQuestionsurveyEmployeeEngagement[ iterator ] + 1);
        const sumTotalNumberQuestionSurveyEmployeeEngagement = cumulativeSumNumberQuestionsurveyEmployeeEngagement[ iterator ];


        ///Employee Burnout
        const surveyEmployeeBurnout = ["Disengagement","Exhaustion"];
        const mapEmployeeBurnout = new Map();
        const numberQuestionSurveyEmployeeBurnout = [8,8];
        const cumulativeSumNumberQuestionsurveyEmployeeBurnout = [0];
        
        iterator = 0;
        for(let value of numberQuestionSurveyEmployeeBurnout) {
            cumulativeSumNumberQuestionsurveyEmployeeBurnout.push( cumulativeSumNumberQuestionsurveyEmployeeBurnout[ iterator ] + value);
            mapEmployeeBurnout.set(surveyEmployeeBurnout[iterator], iterator+1);
            iterator++;
        }
        cumulativeSumNumberQuestionsurveyEmployeeBurnout.push( cumulativeSumNumberQuestionsurveyEmployeeBurnout[ iterator ] + 1);
        const sumTotalNumberQuestionSurveyEmployeeBurnout = cumulativeSumNumberQuestionsurveyEmployeeBurnout[ iterator ];


        ///Job Satisfaction Survey
        const surveyJobSatisfactionSurvey = ["Job Security","Pay","Colleagues","Supervision","Growth Opportunities"];
        const mapJobSatisfactionSurvey = new Map();
        const numberQuestionSurveyJobSatisfactionSurvey = [3,2,4,3,2];
        const cumulativeSumNumberQuestionsurveyJobSatisfactionSurvey  = [0];
        
        iterator = 0;
        for(let value of numberQuestionSurveyJobSatisfactionSurvey) {
            cumulativeSumNumberQuestionsurveyJobSatisfactionSurvey.push( cumulativeSumNumberQuestionsurveyJobSatisfactionSurvey[ iterator ] + value);
            mapJobSatisfactionSurvey.set(surveyJobSatisfactionSurvey[iterator], iterator+1);
            iterator++;
        }
        cumulativeSumNumberQuestionsurveyJobSatisfactionSurvey.push( cumulativeSumNumberQuestionsurveyJobSatisfactionSurvey[ iterator ] + 1);
        const sumTotalNumberQuestionSurveyJobSatisfactionSurvey = cumulativeSumNumberQuestionsurveyJobSatisfactionSurvey[ iterator ];


        switch(this.titleSurveyCurrent) {
            case "Employee Engagement": 
                value = value % sumTotalNumberQuestionSurveyEmployeeEngagement;
                iterator = mapEmployeeEngagement.get( this.dimension );
                if(value > cumulativeSumNumberQuestionsurveyEmployeeEngagement[ iterator - 1 ] 
                    && value < cumulativeSumNumberQuestionsurveyEmployeeEngagement[ iterator + 1 ]) {
                        return  true;
                    } else { return false; }
            case "Employee Burnout":
                value = value % sumTotalNumberQuestionSurveyEmployeeBurnout;
                iterator = mapEmployeeBurnout.get( this.dimension );
                if(value > cumulativeSumNumberQuestionsurveyEmployeeBurnout[ iterator - 1 ] 
                    && value < cumulativeSumNumberQuestionsurveyEmployeeBurnout[ iterator + 1 ]) {
                        return  true;
                    } else { return false; }
            case "Job Satisfaction Survey":
                value = value % sumTotalNumberQuestionSurveyJobSatisfactionSurvey;
                iterator = mapJobSatisfactionSurvey.get( this.dimension );
                if(value > cumulativeSumNumberQuestionsurveyJobSatisfactionSurvey[ iterator - 1 ] 
                    && value < cumulativeSumNumberQuestionsurveyJobSatisfactionSurvey[ iterator + 1 ]) {
                        return  true;
                    } else { return false; }
        }
        
    }
    private extractDateParts(isoDateString: string): { year: number, month: number, day: number } {
        const date = new Date(isoDateString);
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1; // Sumamos 1 porque los meses empiezan en 0
        const day = date.getUTCDate();
        return { year, month, day };
    }

    private getDaysDifference(day: number, month: number, year: number): number {
        const currentDate = new Date();
        const targetDate = new Date(year, month - 1, day);
        const dayDiff = differenceInDays(currentDate, targetDate);
        if(dayDiff < 0) {
            return -dayDiff;
        }
        return dayDiff;
    }

    private calculateDataLastWeek() {
        const ansValueWeek = [0,0,0,0,0,0,0] as number[];
        const contAnsWeek = [0,0,0,0,0,0,0] as number[];

        console.log(" -- ** entra calculateDataLastWeek: ",ansValueWeek);
        const dataResponseValue = this.obtainValuesResponse();
        for(let survey of this.allSurveys) {
            if(survey.data.title === this.titleSurveyCurrent) {
                let counterResponse = 0;
                for(let response of survey.data.native_responses) {
                    if( this.isValidQuestionDimensionCurr( counterResponse ) ) {
                        const dateParts = this.extractDateParts(response.updatedAt);
                        const daysDifference = this.getDaysDifference(dateParts.day, dateParts.month, dateParts.year);
                        if(daysDifference < 7) {
                            ansValueWeek[ 6 - daysDifference ] += dataResponseValue.get( response.content );
                            contAnsWeek[ 6 - daysDifference ]++;
                        }
                    }
                    counterResponse += 1;
                }
            }
        }
        for(let iterator = 0; iterator < 7; iterator++) {
            if(contAnsWeek[ iterator ] > 0){
                ansValueWeek[ iterator ] /= contAnsWeek[ iterator ];
            }
        }
        console.log(" -- ** sale calculateDataLastWeek: ",ansValueWeek);
        return ansValueWeek;
    }

    private getMonthsDifference(day: number, month: number, year: number): number {
        const currentDate = new Date();
        const targetDate = new Date(year, month - 1, day);
        const monthDiff = differenceInMonths(currentDate, targetDate);
        if( monthDiff < 0) {
            return -monthDiff;
        }
        return Math.max(monthDiff, 0); // No permitir resultados negativos
      }
      
    private calculateDataLastMounth() {
        const ansValueMounth = [0,0,0,0,0,0,0,0,0,0,0,0] as number[];
        const contAnsMounth = [0,0,0,0,0,0,0,0,0,0,0,0] as number[];

        console.log(" -- ** entra calculateDataLastMounth: ",ansValueMounth);
        const dataResponseValue = this.obtainValuesResponse();
        for(let survey of this.allSurveys) {
            if(survey.data.title === this.titleSurveyCurrent) {
                let counterResponse = 0;
                for(let response of survey.data.native_responses) {
                    if( this.isValidQuestionDimensionCurr( counterResponse ) ) {
                        const dateParts = this.extractDateParts(response.updatedAt);
                        const mounthDifference = this.getMonthsDifference(dateParts.day, dateParts.month, dateParts.year);
                        if(mounthDifference < 12) {
                            ansValueMounth[ 11 - mounthDifference ] += dataResponseValue.get( response.content );
                            contAnsMounth[ 11 - mounthDifference ]++;
                        }
                    }
                    counterResponse++;
                }
            }
        }
        for(let iterator = 0; iterator < 7; iterator++) {
            if(contAnsMounth[ iterator ] > 0){
                ansValueMounth[ iterator ] /= contAnsMounth[ iterator ];
            }
        }
        console.log(" -- ** sale calculateDataLastMounth: ",ansValueMounth);
        return ansValueMounth;
    }

    private getYearsDifference(day: number, month: number, year: number): number {
        const currentDate = new Date();
        const targetDate = new Date(year, month - 1, day);
        const yearDiff = differenceInYears(currentDate, targetDate);
        return Math.max(yearDiff, 0); // No permitir resultados negativos
      }
    private calculateDataLastYear() {
        const ansValueMounth = [0,0] as number[];
        const contAnsMounth = [0,0] as number[];

        console.log(" -- ** entra calculateDataLastYear: ",ansValueMounth);
        const dataResponseValue = this.obtainValuesResponse();
        for(let survey of this.allSurveys) {
            if(survey.data.title === this.titleSurveyCurrent) {
                let counterResponse = 0;
                for(let response of survey.data.native_responses) {
                    if( this.isValidQuestionDimensionCurr( counterResponse ) ) {
                        const dateParts = this.extractDateParts(response.updatedAt);
                        let thisYear = 0;
                        if(dateParts.year === 2023) {
                            thisYear = 1;
                        } else if(dateParts.year === 2022) {
                            thisYear = 0;
                        }
                        ansValueMounth[ thisYear ] += dataResponseValue.get( response.content );
                        contAnsMounth[ thisYear ]++;
                    }
                    counterResponse++;
                }
                
            }
        }
        for(let iterator = 0; iterator < 7; iterator++) {
            if(contAnsMounth[ iterator ] > 0){
                ansValueMounth[ iterator ] /= contAnsMounth[ iterator ];
            }
        }
        console.log(" -- ** sale calculateDataLastYear: ",ansValueMounth);
        return ansValueMounth;
    }

}

const floatingLabels = {
    id: 'floatingLabels',
    afterDatasetsDraw(chart: any, args: any, options: any) {
        const { ctx, chartArea: { top }, scales: { x, y}} = chart;

        for(let i = 0; i < chart.config.data.datasets[0].data.length; i++){

            const xPosition = chart.getDatasetMeta(1).data[i].x;
            const yPosition = top + 6;

            ctx.save();

            if(chart.isDatasetVisible(1) === true) {
                ctx.fillStyle = 'rgba(255, 26, 104, 1)';
            } else {
                ctx.fillStyle = 'transparent';
            }

            ctx.textAlign = 'center';
            ctx.font = 'bolder 12px Arial';
            ctx.fillText(chart.config.data.datasets[0].data[i], xPosition, yPosition);
        }
    }
}