import './collect-index.css'
import {Alert, Button, ButtonGroup, Card, Typography} from "@mui/material";
import {Code, Email, Key, Share} from "@mui/icons-material";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import { useTranslation } from 'react-i18next';

const CollectIndex = () => {
    const [isCopied, setIsCopied] = useState(false);

    // const path = window.location.pathname.split('/')
    // const id = path[path.length - 1];
    const {id} = useParams();

    const {t} = useTranslation();

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    }

    return (
        <div className="row mt-3">
            <div className="col-md-8 offset-md-2 py-3 text-end">

                <Link to={`/surveys`} style={{textDecoration: 'none'}}>
                    <Button variant="contained">{t('option.back')}</Button>
                </Link>
            </div>
            <div className="col-md-8 offset-md-2 py-3">
                <h5>{t('collect.title')}</h5>
            </div>
            <Card className="col-md-8 offset-md-2">

                <ButtonGroup disableElevation variant="text" className="w-100 my-3">
                    <Button className="w-100 mx-0 br-none-first flex-wrap">
                        <Email className="w-100 fs-1" color="primary"/>
                        <Typography sx={{display: {xs: 'none', md: 'inline'}}}>{t('collect.email')}</Typography>
                    </Button>
                    <CopyToClipboard text={`${process.env.REACT_APP_REDIRECT_URI}/#/r/${id}`} onCopy={onCopyText}>
                        <Button className="w-100 mx-0 br-none flex-wrap">
                            <Share className="w-100 fs-1" color="secondary"/>
                            <Typography sx={{display: {xs: 'none', md: 'inline'}}}>{t('collect.link')}</Typography>
                        </Button>
                    </CopyToClipboard>
                    <Button className="w-100 mx-0 br-none flex-wrap">
                        <Code className="w-100 fs-1" color="success"/>
                        <Typography sx={{display: {xs: 'none', md: 'inline'}}}>{t('collect.website')}</Typography>
                    </Button>
                    <CopyToClipboard text={`${process.env.REACT_APP_REDIRECT_URI}/r/sirq/${id}`} onCopy={onCopyText}>
                        <Button className="w-100 mx-0 br-none-last flex-wrap">
                            <Key className="w-100 fs-1" color="warning"/>
                            <Typography sx={{display: {xs: 'none', md: 'inline'}}}>{t('collect.custom')}</Typography>
                        </Button>
                    </CopyToClipboard>
                </ButtonGroup>
            </Card>
            {isCopied ?
                <Alert severity="success" className="alert-style">{t('share.message')}</Alert> : <></>}
        </div>
    )
}

export default CollectIndex;
