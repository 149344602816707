import React, {Component} from 'react';
import {PowerBIEmbed} from 'powerbi-client-react';
import {models} from 'powerbi-client';
import "./ResponsesReport.css";
import axios from 'axios';
import {connect} from "react-redux";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";

import {getSingleSurveysWithQandAThunk} from "../redux/survey/survey.thunk";
import "powerbi-report-authoring";
import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../assets/styles/theme";
import {withParams} from "../helpers/with-params";


// let filter = [
//   {
//     $schema: "http://powerbi.com/product/schema#advanced",
//     target: 
//     { table: "public slack_questions", 
//     column: "slack_survey_id" }, 
//     filterType: 0, 
//     logicalOperator: "And", 
//     conditions: [{ operator: "Is", value: 34 }]
//   }];


class MyReport extends Component {


    state = {
        id: null,
        config: null,
        loading: true,
        style: "report-loading"

    }

    async componentDidMount() {
        console.log(window);

        console.log(window.innerWidth);
        console.log(window.innerHeight);
        console.log("calling backend");
        let res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/pbi/getEmbedInfoNativeTest`);
        console.log(res);

        let config = {
            type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
            id: res.data.embedUrl[0].reportId,
            embedUrl: res.data.embedUrl[0].embedUrl,
            accessToken: res.data.accessToken,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
            settings: {
                panes: {
                    filters: {
                        expanded: false,
                        visible: false
                    }
                },
                visualSettings: {
                    visualHeaders: [
                        {
                            settings: {
                                visible: false
                            }
                            /* No selector - Hide visual header for all the visuals in the report */
                        }
                    ]
                },


                layoutType: models.LayoutType.Custom,
                customLayout: {
                    displayOption: models.DisplayOption.FitToPage
                }
            }
        };

        // if(window.innerWidth < window.innerHeight){
        //   console.log("MOBILE")
        //   config.settings.layoutType = models.LayoutType.MobilePortrait
        //   delete config.settings.customLayout;
        // }

        this.setState({config: config});

        /*const path = window.location.pathname.split('/')
        console.log(path);
        const id = path[path.length - 1]*/
        const {id} = this.props.params;
        this.setState({id: id});
        await this.props.getSingleSurvey(id);
        this.setState({loading: false});

    }


    // componentDidMount(){
    //   this.setState
    // }

    setFilters = async () => {

        console.log('Report loaded');
        let activePage = await window.report.getActivePage();
        console.log(activePage);


        console.log(activePage);
        let filter = await activePage.getFilters();
        console.log(filter);
        filter[0].conditions[0].value = this.props.singleSurvey.id;


        console.log(window.location);


        try {
            await activePage.updateFilters(models.FiltersOperations.Replace, [filter[0]]);
            console.log("Page filters were replaced.");
        } catch (error) {
            console.log(error);
        }


        // const visuals = await activePage.getVisuals();


        // console.log("VISUALS");
        // console.log(visuals);


        // let data = await visuals[0].getDataFields('Category');
        // let d1 = await visuals[0].getDataFields('Series');
        // let d2 = await visuals[0].getDataFields('Y');
        // let d3 = await visuals[0].getDataFields('Tooltips');

        // console.log(data)
        // console.log(d1)
        // console.log(d2)
        // console.log(d3)


        // // Retrieve the target visual.
        // let slicer = visuals.filter(function (visual) {
        //   return visual.type === "slicer";
        // })[0];

        // // Get the slicer state
        // let state = await slicer.getSlicerState();

        // console.log(state);

        // state.filters[0].values = [this.props.singleSurvey.native_questions[0].content];

        // await slicer.setSlicerState({ filters: [state.filters[0]] });

        // console.log(slicer);
        // console.log("Date slicer was set.");

        //this.setState({ style: "report-style-class"  });
        let count = 0;
        for (let q of this.props.singleSurvey.native_questions) {

            if (count > 7) {
                let authoringPage = await window.report.addPage("Responses");
                await authoringPage.setActive();
                activePage = authoringPage;
                count = 0;

            }

            // const customLayout = {
            //      x: 0,
            //     y: 0,
            //     width: 2000,
            //     height: 2000
            // }
            const response = await activePage.createVisual('pieChart');
            let lastCreatedVisual = response.visual;

            await lastCreatedVisual.setProperty({
                objectName: "title",
                propertyName: "titleText"
            }, {schema: 'http://powerbi.com/product/schema#property', value: q.content});
            await lastCreatedVisual.setProperty({
                objectName: "visualHeader",
                "propertyName": "visible"
            }, {schema: 'http://powerbi.com/product/schema#property', value: false})
            //await lastCreatedVisual.setProperty({ objectName: "border", propertyName: "visible" }, { schema: 'http://powerbi.com/product/schema#property', value: true });


            // await lastCreatedVisual.resizeVisual(400, 390)

            const capabilities = await lastCreatedVisual.getCapabilities();
            console.log("Visual capabilities:\n", capabilities);

            const legend = {
                column: 'content',
                table: 'public native_responses',
                schema: 'http://powerbi.com/product/schema#column'
            }
            const responses = {
                schema: "http://powerbi.com/product/schema#columnAggr",
                aggregationFunction: "CountNonNull",
                column: "content",
                table: "public native_responses"
            }

            lastCreatedVisual.addDataField('Category', legend);
            lastCreatedVisual.addDataField('Y', responses);

            console.log(legend);

            let filter = {
                $schema: "http://powerbi.com/product/schema#advanced",
                conditions: [
                    {operator: 'Is', value: q.id}
                ],

                filterType: 0,
                logicalOperator: "And",
                target: {
                    column: "id",
                    table: "public native_questions",
                }
            }


            await lastCreatedVisual.updateFilters(models.FiltersOperations.Add, [filter]);
            count++;
        }


    }

    onRender = () => {


    }


    render() {

        console.log(this.props);
        return (
            <div>
                <AuthenticatedTemplate>
                    <div className="py-3 text-end">
                        <Link to={`/surveys`} style={{textDecoration: 'none'}}>
                            <Button variant="contained">{this.props.t('option.back')}</Button>
                        </Link>
                    </div>
                    <div className="d-flex justify-content-center">
                        <MutatingDots
                            color={palette.primary}
                            height="100"
                            width="100"
                            visible={this.state.loading}/>
                    </div>
                    {this.state.loading === true ?
                        null :
                        <PowerBIEmbed
                            embedConfig={this.state.config
                            }

                            eventHandlers={new Map([
                                ['loaded', this.setFilters],
                                ['rendered', this.onRender],
                                ['error', function (event) {
                                    console.log(event.detail);
                                }]
                            ])
                            }

                            cssClassName={
                                'report-style-class'
                            }

                            getEmbeddedComponent={
                                (embeddedReport) => {
                                    window.report = embeddedReport;
                                }
                            }

                        />
                    }

                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>Please sign-in to view this page</UnauthenticatedTemplate>
            </div>
        )
    }

}


// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        allSurveys: state.surveys.allSurveys,
        singleSurvey: state.surveys.singleSurvey,

    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        // getAllSurveysWithScheduleSlack: (teamId) => dispatch(getAllSurveysWithScheduleSlackThunk(teamId)),
        getSingleSurvey: (id) => dispatch(getSingleSurveysWithQandAThunk(id))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withParams(MyReport));
