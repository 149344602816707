import {useState} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Alert, IconButton, Tooltip} from "@mui/material";
import "./YourSurveys.css";
import {Share, ShareOutlined} from "@mui/icons-material";
import { useTranslation } from "react-i18next";


const ShareClipboard = (props) => {
    const [isCopied, setIsCopied] = useState(false);
    const {t} = useTranslation();

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    };

    return (
        <div className="copy-to-clipboard item-toggle">

            <CopyToClipboard text={`${process.env.REACT_APP_REDIRECT_URI}/#/r/${props.id}`} onCopy={onCopyText}>

                <Tooltip title={t('share.anon')}>
                <IconButton color="primary">
                    <Share/>
                </IconButton>
                </Tooltip>

            </CopyToClipboard>
            <CopyToClipboard text={`${process.env.REACT_APP_REDIRECT_URI}/#/r/sirq/${props.id}`} onCopy={onCopyText}>
                <Tooltip title={t('share.signIn')}>
                    <IconButton color="primary">
                        <ShareOutlined/>
                    </IconButton>
                </Tooltip>
            </CopyToClipboard>
            <br/>

            {isCopied ?
                <Alert severity="success" className="alert-style">{t('save.shareAlert')}</Alert> : <></>}

        </div>
    );
}

export default ShareClipboard;
