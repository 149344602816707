import SurveyActionTypes from "./survey.types";

//ACTION CREATORS

export const changeStatus = (payload) => ({
    type: SurveyActionTypes.CHANGE_STATUS,
    payload,
});

export const errorSurvey = (payload) => ({
    type: SurveyActionTypes.ERROR_SURVEY,
    payload,
});

export const addNewSurvey = (payload) => ({
    type: SurveyActionTypes.ADD_NEW_SURVEY,
    payload,
});

export const addSchedule = (payload) => ({
    type: SurveyActionTypes.ADD_SCHEDULE,
    payload,
});

export const addNewQuestion = (payload) => ({
    type: SurveyActionTypes.ADD_NEW_QUESTION,
    payload,
});

export const addNewAnswer = (payload) => ({
    type: SurveyActionTypes.ADD_NEW_ANSWER,
    payload,
});

export const addJSONtoSurvey = (payload) => ({
    type: SurveyActionTypes.ADD_JSON_TO_SURVEY,
    payload,
});

export const addJSONCardtoSurvey = (payload) => ({
    type: SurveyActionTypes.ADD_JSON_CARD_TO_SURVEY,
    payload,
});

export const getAllSurveys = (payload) => ({
    type: SurveyActionTypes.GET_ALL_SURVEYS,
    payload,
});

export const getAllSurveysWithSchedule = (payload) => ({
    type: SurveyActionTypes.GET_ALL_SURVEYS_WITH_SCHEDULE,
    payload,
})

export const getSingleSurvey = (payload) => ({
    type: SurveyActionTypes.GET_SINGLE_SURVEY,
    payload,
});

export const addNewResponse = (payload) => ({
    type: SurveyActionTypes.ADD_RESPONSE,
    payload,
});

export const getAllQuestionsBySurveyId = (payload) => ({
    type: SurveyActionTypes.GET_ALL_QUESTIONS,
    payload,
});

export const getSingleSurveyWithQandA = (payload) => ({
    type: SurveyActionTypes.GET_SINGLE_SURVEY_WITH_QUESTIONS_AND_ANSWERS,
    payload,
});

export const deleteSurvey = () => ({
    type: SurveyActionTypes.DELETE_SURVEY,
});

export const deleteSchedule = () => ({
    type: SurveyActionTypes.DELETE_SCHEDULE,
});

export const increaseResponsesCount = () => ({

    type: SurveyActionTypes.INCREASE_RESPONSES_COUNT
})

export const getAllTemplates = (payload) => ({
    type: SurveyActionTypes.GET_ALL_TEMPLATES,
    payload
})

export const getSingleTemplate = (payload) => ({
    type: SurveyActionTypes.GET_SINGLE_TEMPLATE,
    payload
})

export const saveResponses = (payload) => ({
    type: SurveyActionTypes.SAVE_RESPONSES,
    payload
})

export const updateSurvey = (payload) => ({
  type: SurveyActionTypes.UPDATE_SURVEY,
  payload
})

