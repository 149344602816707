import axios from "axios";
import { ISurvey } from "../classes/Survey";
import { SurveyConverterFactory } from "../classes/data-conversion/SurveyConverter";
import SurveyFaker from "../classes/SurveyFaker";



export class SurveyService {
    private static allSurveysURL: string = "https://survey-app-backend.azurewebsites.net/api/native/surveys/allSurveys/"; //d09675f1-5567-4d98-9e73-44e1ecd3c3bb";
    private static singleSurveyFullURL: string = "https://survey-app-backend.azurewebsites.net/api/native/surveys/singleSurveyFull/";
    private static singleSurveyWithRURL: string = "https://survey-app-backend.azurewebsites.net/api/native/surveys/singleSurveyWithR/";

    public static async getAllSurveys(id="d09675f1-5567-4d98-9e73-44e1ecd3c3bb"): Promise<ISurvey[]> {
        let allSurveys = [] as ISurvey[];
        allSurveys = (await axios.get(this.allSurveysURL+id)).data;
        console.log("all Surverys: SurveyService.ts: allSurveys: ",allSurveys);
        //allSurveys = SurveyFaker.generateSurveys(10);
        return allSurveys;
    }

    public static async getSurvey(id: string): Promise<ISurvey> {
        console.log("gg game");
        let singleSurvey = {} as ISurvey;

        let singleSurveyFull: any = (await axios.get(this.singleSurveyFullURL + id));
        let singleSurveyWithR: any = (await axios.get(this.singleSurveyWithRURL + id));

        const surveyConverterFactory = new SurveyConverterFactory();
        const surveyConverter = surveyConverterFactory.createConverter("enps", singleSurveyFull.data, singleSurveyWithR.data);
        singleSurvey = surveyConverter.createSurvey();
        console.log("Single survey:");
        console.log(singleSurvey);
        console.log(singleSurvey.title);
        const singleSuveyTitle = singleSurvey.title;
        /*
        let singleSurveyFake = SurveyFaker.generateSurvey("generic");
        let i=0;
        singleSurvey.dimensions.map(dimension => {
            return dimension.score = singleSurveyFake.dimensions[i++].score;
        })
        */

        //singleSurvey = SurveyFaker.generateSurvey("generic");
        return singleSurvey;
    }
    public static async getSurveyTitle(id: string): Promise<string> {
        console.log("gg game 2");
        let singleSurvey = {} as ISurvey;

        let singleSurveyFull: any = (await axios.get(this.singleSurveyFullURL + id));
        let singleSurveyWithR: any = (await axios.get(this.singleSurveyWithRURL + id));

        const surveyConverterFactory = new SurveyConverterFactory();
        const surveyConverter = surveyConverterFactory.createConverter("enps", singleSurveyFull.data, singleSurveyWithR.data);
        singleSurvey = surveyConverter.createSurvey();
        const singleSurveyTitle = singleSurvey.title;
        /*
        let singleSurveyFake = SurveyFaker.generateSurvey("generic");
        let i=0;
        singleSurvey.dimensions.map(dimension => {
            return dimension.score = singleSurveyFake.dimensions[i++].score;
        })
        */

        //singleSurvey = SurveyFaker.generateSurvey("generic");
        return singleSurveyTitle;
    }
    public static async getAllSurveysWithResponse(id="d09675f1-5567-4d98-9e73-44e1ecd3c3bb"): Promise<any[]> {
        console.log("llamada con id: get al surveys: ",id);
        let allSurveys = [] as ISurvey[];
        allSurveys = (await axios.get(this.allSurveysURL+id)).data;
        const ans = [];
        for(const current of allSurveys) {
            ans.push(await axios.get(this.singleSurveyWithRURL + current.id));
        }
        return ans;
    }

    public static async getSingleSurveyWithResponse(id="6b9403b7-8fcb-4828-bba8-8f600d447cf1"): Promise<any> {
        const ansSurvey = (await axios.get(this.singleSurveyWithRURL+id)).data;
        return ansSurvey;
    }
    public static async getSingleSurveyFull(id="6b9403b7-8fcb-4828-bba8-8f600d447cf1"): Promise<any> {
        const ansSurvey = (await axios.get(this.singleSurveyFullURL+id)).data;
        return ansSurvey;
    }
    

    public static async getAlSurveys() {
    }
}