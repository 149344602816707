import { IQuestion } from "../Question";
import { IDimension, ISurvey } from "../Survey";
import { DataConverter } from "./DataConverter";
import { QuestionsConverter } from "./QuestionsConverter";

export class SurveyConverterFactory {
    public createConverter(type: string, rawDataFull: any, rawDataWithR: any): SurveyConverter {
        switch (type) {
            case "enps": return new EmpsSurveyConverter(rawDataFull, rawDataWithR);
            default: return {} as SurveyConverter;
        }
    }
}

export abstract class SurveyConverter extends DataConverter {
    protected survey = {} as ISurvey;

    protected getBasicData(): void {
        this.survey.id = this.rawDataFull.id;
        this.survey.title = this.rawDataFull.title;
    }

    protected getDimensionsData(): void {
        //get dimensions name
        let dimensions = [] as IDimension[];
        for (const page of this.rawDataFull.json.pages) {
            dimensions.push({ name: page.title } as IDimension)
        }
        this.survey.dimensions = dimensions;

        const questionsConverter = new QuestionsConverter(this.rawDataFull, this.rawDataWithR);
        const questions: IQuestion[] = questionsConverter.createQuestions();
        console.log("ver data de questions: ",questions);
        let i=0;
        for (const dimension of this.survey.dimensions) {
            let scoreSum = 0;
            let maxScoreSum = 0;
            let dimensionQuestions: IQuestion[] = questions.filter(question => question.dimension == dimension.name);
            let number = dimensionQuestions.length;
            for (const question of dimensionQuestions) {
                scoreSum += question.score;
                maxScoreSum += question.maxScore;
            }
            this.survey.dimensions[i].score = scoreSum / number;
            this.survey.dimensions[i].maxScore = maxScoreSum / number;
            i++;
        }
        console.log("ver getDimensionsData survey ",this.survey);
    }

    public createSurvey(): ISurvey {
        this.getBasicData();
        this.getDimensionsData();
        return this.survey;
    }
}

export class EmpsSurveyConverter extends SurveyConverter {

}