import Box from "@mui/material/Box";
import {CircularProgress, Typography} from "@mui/material";

const CircularProgressLabel = (props) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress size={130} variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Typography sx={{ fontSize: 25 }} variant="caption" component="div" color="text.secondary">
                    <b>{`${Math.round(props.value)}%`}</b>
                    <p className="fs-6">COMPLETE</p>
                </Typography>
            </Box>
        </Box>
    )
}

export default CircularProgressLabel;