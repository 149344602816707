

export const adminItems = (t) =>
{return [
    /*{
        name: 'Dashboard',
        link: true,
        routerLink: '/dashboard'
    },*/
    {
        name: t('home'),
        link: true,
        routerLink: '/surveys'
    },
    {
        name: t('billing'),
        link: true,
        routerLink: '/billing'

    },
    /*{
        name: 'Reports',
        link: true,
        routerLink: '/reports'
    },*/
    {
        name: t('surveys.newSurvey'),
        link: false,
        routerLink: '/survey-create'
    },
]
}
