import SurveyTable from "./survey-table/survey-table";
import {Component} from "react";
import {deleteSurveyThunk, getAllSurveysThunk, updateSurveyThunk} from "../../../redux/survey/survey.thunk";
import {connect} from "react-redux";
import {MsalContext} from "@azure/msal-react";
import SurveyCard from "./survey-card/survey-card";
import {Box} from "@mui/material";

class SurveyIndex extends Component {

    static contextType = MsalContext;
    state = {
        loadInit: false
    }

    componentDidMount() {
        // this.props.getAllSurveys(this.context.accounts[0].localAccountId);
        // this.props.getAllSurveys(this.props.user?.id);
        // toastr.success('Success!', 'The surveys were loaded successfully');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user && !this.state.loadInit) {
            this.setState({loadInit: true});
            this.props.getAllSurveys(this.props.user?.id);
        }
    }

    destroySurvey = async (id) => {
        await this.props.deleteSurvey(id);
        this.props.getAllSurveys(this.context.accounts[0].localAccountId);
    };

    render() {
        return (
            <div>
                <Box sx={{display: {xs: 'block', md: 'none'}}}>
                    <SurveyCard surveys={this.props.allSurveys} status={this.props.status}
                                deleteSurvey={this.destroySurvey} updateSurvey={this.props.updateSurvey}/>
                </Box>
                <Box sx={{display: {xs: 'none', md: 'block'}}}>
                    <SurveyTable surveys={this.props.allSurveys} status={this.props.status}
                                 deleteSurvey={this.destroySurvey} updateSurvey={this.props.updateSurvey}/>
                </Box>
            </div>
        );
    }
}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        allSurveys: state.surveys.allSurveys,
        status: state.surveys.status,
        user: state.users.user,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getAllSurveys: (tenantId) => dispatch(getAllSurveysThunk(tenantId)),
        deleteSurvey: (id) => dispatch(deleteSurveyThunk(id)),
        updateSurvey: (surveyId, changes) => dispatch(updateSurveyThunk(surveyId, changes))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SurveyIndex);
