import React, {Component} from "react";
import "survey-react/survey.css";
import * as Survey from "survey-react";
import {connect} from "react-redux";
import {getSingleSurveyThunk, saveResponsesThunk} from "../../../../redux/survey/survey.thunk";
import {AuthenticatedTemplate, MsalContext} from "@azure/msal-react";
import FooterSurvey from "../../share-components/footer-survey";
import {addUserThunk, getUserThunk} from "../../../../redux/users/users.thunk";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../../../../assets/styles/theme";
import {withParams} from "../../../../helpers/with-params";

class TakeSurvey extends Component {

    constructor(props) {
        super(props);
        this.survey = null;
    }

    state = {
        loaded: false,
        survey: {},
        user: {
            firstName: null,
            lastName: null,
            email: null,
            id: null,
            tenantId: null,
        },
    };

    static contextType = MsalContext;


    async componentDidMount() {

        /*const path = window.location.pathname.split('/')
        console.log(path);
        const id = path[path.length - 1]*/
        const {id} = this.props.params;
        await this.props.getSingleSurvey(id);

        this.survey = new Survey.Model(this.props.singleSurvey.json);

        this.survey.onComplete.add(this.surveyCompleted);

        this.setState({loaded: true});
    }

    surveyCompleted = async (sender, options) => {


        this.setState({loaded: false});
        const account = this.context.accounts[0];

        await this.props.getUser(account.idTokenClaims.oid);


        if (!this.props.user.id) {

            await this.props.addUser({
                id: account.idTokenClaims.oid,
                email: account.idTokenClaims.emails[0],
                username: account.username,
                firstName: account.idTokenClaims.extension_firstName,
                lastName: account.idTokenClaims.extension_lastName,
            });

        }


        let userId = this.props.user.id;


        let surveyId = this.props.singleSurvey.id;

        //necessary cause otherwise backend had errors with data types
        function toString(o) {
            Object.keys(o).forEach(k => {
                if (typeof o[k] === 'object') {
                    return toString(o[k]);
                }

                o[k] = '' + o[k];
            });

            return o;
        }

        let responses = toString(sender.data);
        console.log(responses);

        let res = await this.props.saveResponses(surveyId, responses, userId);
        console.log(res);

        if (res === 201) {
            this.setState({loaded: true});
            options.showDataSavingSuccess();
        }
        if (res === 500) {
            this.setState({loaded: true});
            options.showDataSavingError();
        }
        console.log(res);


    };

    render() {

        return (
            <div>


                <AuthenticatedTemplate>


                    <div>
                        {this.state.loaded ? (
                            <div>
                                <Survey.Survey model={this.survey}/>
                                <FooterSurvey/>
                            </div>
                        ) : null}
                        <div>
                            <div className="d-flex justify-content-center">
                                <MutatingDots
                                    color={palette.primary}
                                    height="100"
                                    width="100"
                                    visible={!this.state.loaded}/>
                            </div>
                            {!this.state.loaded ?
                                <p className="text-center">{this.props.t('status.loading')}... </p> : null}
                        </div>
                    </div>

                </AuthenticatedTemplate>


            </div>
        );
    }
}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        singleSurvey: state.surveys.singleSurvey,
        user: state.users.user,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getSingleSurvey: (id) => dispatch(getSingleSurveyThunk(id)),
        getUser: (id) => dispatch(getUserThunk(id)),
        addUser: (obj) => dispatch(addUserThunk(obj)),
        saveResponses: (surveyId, responses, userId) => dispatch(saveResponsesThunk(surveyId, responses, userId)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withParams(TakeSurvey));
