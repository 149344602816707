//this component is used to overcome the problem with pages in templates
//gets the template and renders the creator component passing the template json
import React, { Component } from "react";
import * as SurveyCreator from "survey-creator-react";
import * as Survey from "survey-core";


import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";

// import "survey-core/defaultV2.css";
// import "survey-creator-core/survey-creator-core.css";
import "./survey-creator-core.css";
import "./defaultV2.css";

import { getSingleTemplateThunk } from "../../../../redux/survey/survey.thunk";

import { connect } from "react-redux";
import { MsalContext, UnauthenticatedTemplate, AuthenticatedTemplate } from "@azure/msal-react";


import CreateSurvey from "./start-from-scratch-index";
import {CircularProgress} from "@mui/material";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../../../../assets/styles/theme";
import {useParams} from "react-router-dom";
import {withParams} from "../../../../helpers/with-params";

class MySurveyCreator extends Component {

    state = {
        loaded: false,
        json: {}

    }

    constructor(props) {
        super(props);
    }




    componentDidMount() {

        // const path = window.location.pathname.split('/')
        // const path = this.props.getParams.id;
        // console.log(path, this.props.params);
        // const id = path[path.length - 1];
        const {id} = this.props.params;
        console.log(id);
        /*if (id) {
            if (id !== "start-from-scratch") {
                this.props.getTemplate(id).then(() => {
                    this.setState({ json: this.props.singleTemplate, loaded: true });
                })
            } else {
                this.setState({ loaded: true })
            }
        }*/
        if (id) {
            this.props.getTemplate(id).then(() => {
                this.setState({ json: this.props.singleTemplate, loaded: true });
            })
        } else {
            this.setState({ loaded: true })
        }
    }


    static contextType = MsalContext;

    render() {
        console.log(this);
        return (

            <div>
                <AuthenticatedTemplate>
                   {this.state.loaded ? <CreateSurvey myJson={this.state.json}
                                                      navigate={this.props.navigate}
                                                      t={this.props.t}
                                                      breakpoint={this.props.breakpoint}/> : null
                   }
                    <div>
                        <div className="d-flex justify-content-center">
                            <MutatingDots
                                color={palette.primary}
                                height="100"
                                width="100"
                                visible={!this.state.loaded}/>
                        </div>
                        {!this.state.loaded ? <p className="text-center">{this.props.t('status.loading')}... </p> : null}
                    </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>Please Sign To Use the Dashboard</UnauthenticatedTemplate>
            </div>);
    }

}
// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        singleTemplate: state.surveys.singleTemplate
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getTemplate: (id) => dispatch(getSingleTemplateThunk(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withParams(MySurveyCreator));
