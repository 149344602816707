import React, {Component} from "react";
import "survey-react/survey.css";
import * as Survey from "survey-react";

import {connect} from "react-redux";
import {getSingleSurveyThunk} from "../../../../redux/survey/survey.thunk";

import {AuthenticatedTemplate} from "@azure/msal-react";
import {Button} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import FooterSurvey from "../../share-components/footer-survey";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../../../../assets/styles/theme";
import {withParams} from "../../../../helpers/with-params";


const jsonFile = {
    "title": "Encuesta 1",
    "pages": [{
        "questions": [{
            "name": "Estoy seguro de conocer la misión y la estrategia de nuestro Banco",
            "type": "radiogroup",
            "choices": ["Totalmente en desacuerdo", "No estoy de acuerdo", "Neutral", "De acuerdo", "Totalmente de acuerdo", "No puedo calificar"]
        }, {
            "name": "Entiendo cómo se mide mi desempeño",
            "type": "radiogroup",
            "choices": ["Totalmente en desacuerdo", "No estoy de acuerdo", "Neutral", "De acuerdo", "Totalmente de acuerdo", "No puedo calificar"]
        }, {
            "name": "Cuento con las herramientas, materiales y recursos necesarios para hacer bien mi trabajo",
            "type": "radiogroup",
            "choices": ["Totalmente en desacuerdo", "No estoy de acuerdo", "Neutral", "De acuerdo", "Totalmente de acuerdo", "No puedo calificar"]
        }, {
            "name": "Comprendo claramente como crecer o desarrollarme profesionalmente en el Banco",
            "type": "radiogroup",
            "choices": ["Totalmente en desacuerdo", "No estoy de acuerdo", "Neutral", "De acuerdo", "Totalmente de acuerdo", "No puedo calificar"]
        }, {
            "name": "Siento que mi trabajo es importante para lograr los resultados del Banco",
            "type": "radiogroup",
            "choices": ["Totalmente en desacuerdo", "No estoy de acuerdo", "Neutral", "De acuerdo", "Totalmente de acuerdo", "No puedo calificar"]
        }, {
            "name": "Question Text 6",
            "type": "radiogroup",
            "choices": ["Option 1"]
        }, {
            "name": "El apoyo y ayuda que recibo de mi equipo facilita mi trabajo",
            "type": "radiogroup",
            "choices": ["Totalmente en desacuerdo", "No estoy de acuerdo", "Neutral", "De acuerdo", "Totalmente de acuerdo", "No puedo calificar"]
        }, {
            "name": "A qué oficina pertenece?",
            "type": "radiogroup",
            "choices": ["Oficina Nacional ", "Oficina Central Sucursal"]
        }], "description": "Sobre mi cargo"
    }]
};


class DisplaySurvey extends Component {
    state = {
        json: undefined,
        loaded: false,
    };

    async componentDidMount() {

        /*const path = window.location.pathname.split('/')
        console.log(path);
        const id = path[path.length - 1]*/

        const {id} = this.props.params;
        //let id = this.props.match.params.id;
        await this.props.getSingleSurvey(id);
        this.setState({json: this.props.singleSurvey.json, loaded: true});
    }

    render() {
        return (
            <div>

                <AuthenticatedTemplate>
                    <div className="py-3 text-end">
                        <Link to={`/surveys`} style={{textDecoration: 'none'}}>
                            <Button variant="contained">{this.props.t('option.back')}</Button>
                        </Link>
                    </div>
                    <div>
                        <div className="d-flex justify-content-center">
                            <MutatingDots
                                color={palette.primary}
                                height="100"
                                width="100"
                                visible={!this.state.loaded && this.state.json === undefined}/>
                        </div>

                        {!this.state.loaded && this.state.json === undefined ? null : (
                            <div>
                                <Survey.Survey
                                    json={this.state.json}
                                    showPreview={true}
                                    showNavigationButtons={false}
                                    showInvisibleElements={true}
                                    questionsOnPageMode="singlePage"
                                    mode="display"
                                />
                                <FooterSurvey/>
                            </div>
                        )}
                    </div>

                </AuthenticatedTemplate>
            </div>
        );
    }
}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        singleSurvey: state.surveys.singleSurvey,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getSingleSurvey: (id) => dispatch(getSingleSurveyThunk(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withParams(DisplaySurvey));
