import {Button, Card, CardActions, CardContent, CardMedia, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import './start-template-card.css';

const StartTemplateCard = (props) => {
    const navigate = useNavigate();

    const isAvailable = (id) => {
        return id === 8 || id === 2 || id === 10 || id === 9;
    }

    return (
        <div className="row">
            {props.templates.map((item, index) =>
                isAvailable(item.id) &&
                <div className="col-md-6 col-lg-4 my-3" key={index}>
                    <Card className="template-card h-100 position-relative pb-5">
                        <CardMedia
                            component="img"
                            height="140"
                            image={`${process.env.REACT_APP_BACKEND_URL}/api/native/surveys/template-image-download/${item.image}/templates-native`}
                            alt=""/>
                        <CardContent className="template-content-card">
                            <Typography gutterBottom variant="h5" component="div">
                                {item.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {item.description}
                            </Typography>
                        </CardContent>
                        <CardActions className="template-action-card position-absolute bottom-0 w-100">
                            <Button className="w-100" variant="contained" onClick={()=>navigate(`/survey-create/start-template/${item.id}`)}>Start</Button>
                        </CardActions>
                    </Card>
                </div>)}
        </div>
    )
}

export default StartTemplateCard;
