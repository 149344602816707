import {Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, useTheme} from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import * as React from "react";
import {adminItems} from "../admin-items";
import {useNavigate} from "react-router-dom";
import logo from "../../assets/MoodbitLogo.png";
import logo2 from "../../assets/MoodbitLogo2.png";


const drawerWidth = 240;

const styles = {
    logo2: {
        width: '50px',
        cursor: 'pointer'
    },
    logo: {
        width: '180px',
        cursor: 'pointer'
    }
}

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('xs')]: {
        width: 0,
    },
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const MenuDrawer = ({...props}) => {
    console.log("DRAWER PROPS", props)
    const {handleDrawerClose, open} = props;
    const pages = adminItems(props.user);
    const theme = useTheme();
    // const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const onRoute = (url) => {
        navigate(url);
//         handleDrawerToggle();
    }
    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                {!open ?
                    <img style={styles.logo2} src={logo2} alt="" onClick={() => onRoute('/')}/> :
                    <img style={styles.logo} src={logo} alt="" onClick={() => onRoute('/')}/>
                }
            </DrawerHeader>
            <Divider/>
            <List>
                {pages.map((item, index) => (
                    <ListItem key={item.name} disablePadding sx={{display: 'flex'}}>
                        <ListItemButton
                            onClick={() => onRoute(item.routerLink)}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                paddingBottom: '26vh'
                            }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    opacity: { xs: open? 1: 0, sm: 1}
                                }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} sx={{opacity: open ? 1 : 0}}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            {/*<Divider/>
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem key={text} disablePadding sx={{display: 'block'}}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {index % 2 === 0 ? <Inbox/> : <Mail/>}
                            </ListItemIcon>
                            <ListItemText primary={text} sx={{opacity: open ? 1 : 0}}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>*/}
        </Drawer>
    )
}

export default MenuDrawer;
