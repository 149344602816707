import StartTemplateIndex from "./start-template/start-template-index";
import {Button, Typography, useTheme} from "@mui/material";
import {ContentPaste} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";

const SurveyCreateHome = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const theme = useTheme();
    return (
        <div>
            <div className="text-center">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 py-4 bg-target">
                        <Typography variant="h5" sx={{color: theme.palette.primary.main}}>
                            <b>{t('surveys.startFromScratch')}</b>
                        </Typography>
                        <small className="d-block mt-1 text-black-50">
                            {t('surveys.scratch.desc')}
                        </small>
                        <Button variant="outlined" className="mt-2 my-3 py-2 px-3" size="large"
                                onClick={() => navigate('start-from-scratch')}>
                            <ContentPaste/>
                            <b>{t('survey')}</b>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <StartTemplateIndex/>
            </div>

        </div>
    )
}

export default SurveyCreateHome;