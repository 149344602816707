import {Card, CardContent, CardHeader} from "@mui/material";
import {MsalContext} from "@azure/msal-react";
import TextFieldWrapper from "../layouts/forms/text-field-wrapper";
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import ButtonWrapper from "../layouts/forms/button-wrapper";
import {connect} from "react-redux";
import {editUserThunk, getUserThunk} from "../../redux/users/users.thunk";
import React, {Component} from "react";
import {toastr} from "react-redux-toastr";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../../assets/styles/theme";

class UserProfile extends Component {
    state = {
        updateMsg: false
    }
    static contextType = MsalContext;


    INITIAL_FROM_STATE = {
        firstName: '',
        lastName: '',
        username: ''
    };

    FORM_VALIDATION = Yup.object().shape({
        firstName: Yup.string().required(this.props.t('user.validFirstNameRequired')),
        lastName: Yup.string().required(this.props.t('user.validLastNameRequired')),
        username: Yup.string().required(this.props.t('user.validUsernameRequired')),
    });

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.props.getUser(this.context.accounts[0].localAccountId);
    }

    initialValues() {
        if (this.props.user) {
            this.INITIAL_FROM_STATE.firstName = this.props.user.firstName;
            this.INITIAL_FROM_STATE.lastName = this.props.user.lastName;
            this.INITIAL_FROM_STATE.username = this.props.user.username;
        }
        return this.INITIAL_FROM_STATE;
    }

    onSubmit = async (values) => {
        await this.props.editUser(values, this.props.user.id);
        toastr.success(`${this.props.t('success')}!`, this.props.t('user.successUpdated'));
    }

    render() {
        const {t} = this.props;
        return (
            <div className="row">
                <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                    {this.props.user && this.props.status !== 'loading' ?
                        <Card>
                            <CardHeader title={t('user.userProfile')}/>
                            {/*<CardMedia component="img"
                                       className="w-50 mx-auto"
                                       image={userImg}
                                       alt={this.props.user.firstName}/>*/}
                            <CardContent>
                                <p className="text-center mb-3">
                                    <small><b>{`${t('user.email')}: ${this.props.user.email}`}</b></small></p>
                                <Formik initialValues={this.initialValues()}
                                        validationSchema={this.FORM_VALIDATION}
                                        onSubmit={values => this.onSubmit(values)}>
                                    <Form>
                                        <div className="my-3">
                                            {/*<TextField className="w-100" id="filled-basic" label="Username" variant="outlined"
                                           disabled defaultValue={userAccount.username}/>*/}
                                            <TextFieldWrapper name="username" label={t('user.username')}/>
                                        </div>
                                        <div className="my-3">
                                            {/*<TextField className="w-100" id="filled-basic" label="Firstname" variant="outlined"
                                           disabled defaultValue={userAccount.idTokenClaims.extension_firstName}/>*/}
                                            <TextFieldWrapper name="firstName" label={t('user.firstName')}/>

                                        </div>
                                        <div className="my-3">
                                            {/*<TextField className="w-100" id="filled-basic" label="Lastname" variant="outlined"
                                           disabled defaultValue={userAccount.idTokenClaims.extension_lastName}/>*/}
                                            <TextFieldWrapper name="lastName" label={t('user.lastName')}/>

                                        </div>
                                        {/*<div className="my-3">
                                <TextField className="w-100" id="filled-basic" label="Email" variant="outlined"
                                           disabled defaultValue={userAccount.idTokenClaims.emails[0]}/>
                            </div>*/}
                                        <div className="my-3">
                                            <ButtonWrapper>{t('user.update')}</ButtonWrapper>
                                        </div>
                                    </Form>
                                </Formik>
                            </CardContent>
                        </Card> : null}
                    <div>
                        <div className="d-flex justify-content-center">
                            <MutatingDots
                                color={palette.primary}
                                height="100"
                                width="100"
                                visible={!this.props.user || this.props.status === 'loading'}/>
                        </div>
                        {!this.props.user || this.props.status === 'loading' ?
                            <p className="text-center">{t('status.loading')}... </p> : null}
                    </div>
                </div>
            </div>
        )
    }
}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        user: state.users.user,
        status: state.users.status
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        editUser: (obj, id) => dispatch(editUserThunk(obj, id)),
        getUser: (id) => dispatch(getUserThunk(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
