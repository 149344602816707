import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import * as React from "react";
import {useState} from "react";
import {Avatar, Badge, Box, capitalize, Divider, IconButton, MenuList} from "@mui/material";
import {PhotoCamera} from "@mui/icons-material";
import UserEditPhotoIndex from "../user/user-edit-photo/user-edit-photo-index";
import {useTranslation} from "react-i18next";

const MenuUser = ({...props}) => {
    const {anchorElUser, handleCloseUserMenu, settings, user} = props;

    const [openEditPhoto, setOpenEditPhoto] = useState(false);

    const{t} = useTranslation();

    const handleClickOpenEditPhoto = () => {
        setOpenEditPhoto(true);
    };

    const handleCloseEditPhoto = () => {
        setOpenEditPhoto(false);
    };

    return (
        <Box>
            <Menu
                sx={{mt: '45px'}}
                id="menu-appbar-user"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={() => handleCloseUserMenu()}>
                <MenuList disableListWrap={true} className="justify-content-center">
                    <div className="text-center">
                        <Badge overlap="circular"
                               anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                               badgeContent={
                                   <IconButton className="bg-white p-1" onClick={handleClickOpenEditPhoto}>
                                       <PhotoCamera fontSize="small"/>
                                   </IconButton>
                               }>
                            <Avatar alt={user?.firstName} className="m-auto" src={`${process.env.REACT_APP_BACKEND_URL}/api/native/users/download/${user.photo}`}
                                    sx={{width: 56, height: 56}}/>
                        </Badge>
                        <p className="my-2">
                            <small><b>{capitalize(user?.firstName || '') + ' ' + capitalize(user?.lastName || '')}</b></small>
                        </p>
                    </div>
                </MenuList>
                <Divider variant="middle"/>
                {settings.map((setting, index) => (
                    <MenuItem key={index} onClick={() => handleCloseUserMenu(setting)}>
                        <Typography textAlign="center">{setting.translation}</Typography>
                    </MenuItem>
                ))}
            </Menu>
            {openEditPhoto ? <UserEditPhotoIndex open={openEditPhoto}
                                user={user}
                                                 t={t}
                                onClose={handleCloseEditPhoto}/>: null}
        </Box>
    )
}
export default MenuUser;