import {
    addUser,
    changeCreateUserStatus,
    changeUserStatus,
    editUser,
    getUser,
    updateProfilePhoto
} from "./users.actions";
import {
    addUserService,
    editUserService,
    getUserService,
    getUserWithEmailService,
    updateProfilePhotoService
} from "../../services/user.service";

export const getUserThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeUserStatus('loading'));
        try {
            const data = await getUserService(id);
            console.log("data get user", data);
            if (!data.error) {
                dispatch(getUser(data));
                dispatch(changeUserStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

/** get user with email **/
export const getUserWithEmailThunk = (email) => {
    return async (dispatch) => {
        dispatch(changeUserStatus('loading'));
        console.log("LOADING USER");
        try {
            const data = await getUserWithEmailService(email);
            console.log("data get user EMAIL", data);
            if (!data.error) {
                dispatch(getUser(data));
                dispatch(changeUserStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const addUserThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeCreateUserStatus('loading'));
        try {
            await addUserService(obj);
            const data = await getUserWithEmailService(obj.email);
            console.log("data", data);
            if (!data.error){
                dispatch(addUser(data));
                dispatch(changeCreateUserStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const editUserThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeUserStatus('loading'));
        try {
            const data = await editUserService(obj, id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(editUser(data));
                dispatch(changeUserStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updateProfilePhotoThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeUserStatus('loading'));
        try {
            const data = await updateProfilePhotoService(obj, id);
            console.log("data update profile photo", data);
            if (!data.error) {
                dispatch(updateProfilePhoto(data));
                dispatch(changeUserStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};
