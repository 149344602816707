import React from 'react'
import { IQuestion } from '../../classes/Question'
import Grid from '@mui/material/Unstable_Grid2';
interface Props {
  index: number,
  question: IQuestion,
}

const SurveyChart = (props: Props) => {
  return (
    <>
    <Grid xs={10} md={6} >
      {props.question.render(props.index, props.question)}
    </Grid>
    </>
  );
}

export default SurveyChart;