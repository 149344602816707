import './App.css';
import AppRouting from "./app-routing";
import NavBar from "./components/layouts/NavBar";
import {Box, Container, CircularProgress,Toolbar} from "@mui/material";
import {addUserThunk, getUserThunk, getUserWithEmailThunk} from "./redux/users/users.thunk";
import {connect} from "react-redux";
import {Component} from "react";
import {MsalContext} from "@azure/msal-react";
import MenuDrawer from "./components/layouts/MenuDrawer";

/*class App extends Component {
    static contextType = MsalContext;

    useEffect( async() => {
        console.warn('APP 1', props);
        if(accounts.length > 0) {

        }
    }, []);*/
    class App extends Component {

        state = {
            loading: false,
            open: false,
            createUser: false,
        }
    
        static contextType = MsalContext;
    
        async componentDidMount() {
            console.log("ACCOUNTS Mount: ", this.context.accounts)
            this.setState({ loading: false });
        }
    
        handleDrawerOpen = () => {
            this.setState({open: true});
        };
    
        handleDrawerClose = () => {
            this.setState({open: false});
        };

    async componentDidUpdate() {
        console.warn('APP 1', this.props);
        if (this.context.accounts.length > 0 && !this.props.user) {
            const email = this.context.accounts[0].idTokenClaims.email;
            // await this.props.getUser(this.context.accounts[0].localAccountId);
            await this.props.getUser(email);
            console.log('APP 2', this.props);
            if (!this.state.createUser && this.props.statusCreate !== 'loading' && !this.props.user) {
                this.setState({ createUser: true });
                console.log("add new user");
                const account = this.context.accounts[0];
                await this.props.addUser({
                    // id: account.localAccountId,
                    email: account.idTokenClaims.email,
                    username: account.username,
                    firstName: account.idTokenClaims.extension_firstName,
                    lastName: account.idTokenClaims.extension_lastName,
                });
            } else {

                console.log("user exists")
            }
        }
    }

    render() {
        return (
            
            <div className={`min-vh-100 ${this.context.accounts.length === 0 ? this.context.inProgress === 'logout' ? '' : 'bg-light' : 'bg-light-2'}`}>
                <NavBar user={this.props.user} handleDrawerOpen={this.handleDrawerOpen} handleDrawerClose={this.handleDrawerClose} open={this.state.open}/>
                {/*this.context.accounts?.length > 0 ?
                <MenuDrawer handleDrawerClose={this.handleDrawerClose} open={this.state.open} user={this.props.user}/>
                :null*/}
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="h-100">
                    <Toolbar/>
                    <Container className="mt-md-5">
                        <div className="mx-md-5">
                            <AppRouting t={this.props.t}/>
                        </div>
                    </Container>
                </Box>

            {/*<div className={`min-vh-100 ${this.context.accounts?.length === 0 ? this.context.inProgress === 'logout' ? '' : 'bg-light' : 'bg-light-2'}`}>
            <NavBar handleDrawerOpen={this.handleDrawerOpen} handleDrawerClose={this.handleDrawerClose} open={this.state.open}/>
            <div className="w-100 d-flex">
            {this.context.accounts?.length > 0 ?
                <MenuDrawer handleDrawerClose={this.handleDrawerClose} open={this.state.open} user={this.props.user}/>
            :null}
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="w-100 h-100">
                <Toolbar/>
                        {this.state.loading ?
                            <div className="text-center col-12">
                                <p>Loading...</p>
                                <CircularProgress/>
                            </div>
                            : <AppRouting open={this.state.open}/>}
            </Box>
            </div>
            </div>*/}

            </div>      
        )
    }
}

const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
        status: state.users.status,
        statusCreate: state.users.statusCreate
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
        addUser: (obj) => dispatch(addUserThunk(obj))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
