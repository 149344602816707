import axios from "axios";
import { convertQuestions } from "../classes/convertQuestions";
import { IQuestion } from "../classes/Question";
import { QuestionsConverter } from "../classes/data-conversion/QuestionsConverter";
import SurveyFaker from "../classes/SurveyFaker";

export class QuestionService {
    private static singleSurveyFullURL: string = "https://survey-app-backend.azurewebsites.net/api/native/surveys/singleSurveyFull/";
    private static singleSurveyWithRURL: string = "https://survey-app-backend.azurewebsites.net/api/native/surveys/singleSurveyWithR/";

    public static async getAllQuestionsOfSurvey(surveyId: string): Promise<IQuestion[]> {
        let allQuestions = [] as IQuestion[];
 
        let singleSurveyFull: any = (await axios.get(this.singleSurveyFullURL + surveyId));
        let singleSurveyWithR: any = (await axios.get(this.singleSurveyWithRURL + surveyId));
        //console.log(singleSurveyWithR);
        
        const dataConverter = new QuestionsConverter(singleSurveyFull.data, singleSurveyWithR.data);
        allQuestions = dataConverter.createQuestions();
        
        //allQuestions = convertQuestions(singleSurveyFull.data, singleSurveyWithR.data);
        console.log(allQuestions);

        /*
        let allQuestionsFake = SurveyFaker.generateQuestions(20);
        for(let i = 0; i<allQuestions.length; i++) {
            allQuestions[i] = {
                ...allQuestions[i],
                score: allQuestionsFake[i].score
            };
        }
        */

        //allQuestions = SurveyFaker.generateQuestions(10);
        return allQuestions;
    }

    public static async getQuestion(id: string): Promise<IQuestion> {
       return {} as IQuestion;
    }
}