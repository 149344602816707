  const SurveyActionTypes = {
  ADD_NEW_SURVEY: "ADD_NEW_SURVEY",
  ADD_NEW_QUESTION: "ADD_NEW_QUESTION",
  ADD_NEW_ANSWER: "ADD_NEW_ANSWER",
  ADD_JSON_TO_SURVEY: "ADD_JSON_TO_SURVEY",
  ADD_JSON_CARD_TO_SURVEY: "ADD_JSON_CARD_TO_SURVEY",
  GET_ALL_SURVEYS: "GET_ALL_SURVEYS",
  GET_ALL_SURVEYS_WITH_SCHEDULE: "GET_ALL_SURVEYS_WITH_SCHEDULE",
  GET_SINGLE_SURVEY: "GET_SINGLE_SURVEY",
  ADD_RESPONSE: "ADD_RESPONSE",
  GET_ALL_QUESTIONS: "GET_ALL_QUESTIONS",
  GET_SINGLE_SURVEY_WITH_QUESTIONS_AND_ANSWERS:
    "GET_SINGLE_SURVEY_WITH_QUESTIONS_AND_ANSWERS",
  DELETE_SURVEY: "DELETE_SURVEY",
  DELETE_SCHEDULE: "DELETE_SCHEDULE",
  ADD_SCHEDULE: "ADD_SCHEDULE",
  INCREASE_RESPONSES_COUNT: "INCREASE_RESPONSES_COUNT",
  CHANGE_STATUS: "CHANGE_STATUS",
  GET_ALL_TEMPLATES: "GET_ALL_TEMPLATES",
  GET_SINGLE_TEMPLATE: "GET_SINGLE_TEMPLATE",
  SAVE_RESPONSES: "SAVE_RESPONSES",
  UPDATE_SURVEY: "UPDATE_SURVEY",
  ERROR_SURVEY: "ERROR_SURVEY"
};

export default SurveyActionTypes;
