import React from 'react'
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

interface Props {
  score: number,
  maxScore: number,
}

const ScoreBar = (props: Props) => {
  console.log("entra scorebar");
  const backgroundColor = (score: number): string => {
    const percentage = score/props.maxScore;
    return percentage > 0.33? percentage > 0.66? "success" : "warning" : "danger";
  }
  
return (
    <>
        <div>
            <div className={`${backgroundColor(props.score)} `} style={{borderRadius: "7px", height: "30px"}} >{props.score}</div>
        </div>
    </>
  )
}

export default ScoreBar;