import React from "react";
import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import logo from '../../../assets/MoodbitLogo.png';
import {useTranslation} from "react-i18next";

const styles = {
    logo: {
        width: '120px',
        cursor: 'pointer'
    },
    fsSmall: {
        fontSize: '0.7rem'
    }
}


const FooterSurvey = () => {
    const{t} = useTranslation();
    return (
        <div className="my-3 text-center">
            {/*<Link to={`/`} style={{textDecoration: 'none'}}>
                <Button variant="contained" size="large">DONE</Button>
            </Link>*/}
            <div className="mt-4 h6" style={styles.fsSmall}>
                <p className="mb-0">{t('footer.powerBy')}</p>
                <img style={styles.logo} src={logo} alt="" className="my-1"/>
                <p>{t('footer.paragraph')} <Link to={`/survey-create`}>{t('footer.link')}</Link></p>
                <p className="my-5">{t('footer.privacy')}</p>
            </div>
        </div>
    )
}

export default FooterSurvey;