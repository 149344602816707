import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow
} from "@mui/material";
import SurveyTableSearch from "./survey-table-search";
import SurveyTableHeader from "./survey-table-header";
import React, {useState} from "react";
import SurveyTableItem from "./survey-table-item";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../../../../assets/styles/theme";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator, filter) {
    array = array.filter((el) => {
        const include = el.title.toLowerCase().includes(filter.toLowerCase());
        return filter === '' || include;
    });

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const SurveyTable = (props) => {
    const {status, surveys, deleteSurvey, updateSurvey} = props;
    const [order, setOrder] = useState('desc');
    const [filter, setFilter] = useState('');
    const [orderBy, setOrderBy] = useState('updateAt');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const {t} = useTranslation();

    const handleRequestSort = (event, property) => {

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeFilter = (value) => {
        setFilter(value);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - surveys.length) : 0;

    return (
        <Box sx={{width: '100%'}}>
            <Paper sx={{width: '100%', mb: 2}}>
                <SurveyTableSearch setFilter={handleChangeFilter}/>
                <TableContainer>
                    <Table sx={{minWidth: 750}}
                           aria-labelledby="tableTitle">
                        <SurveyTableHeader order={order}
                                           orderBy={orderBy}
                                           onRequestSort={handleRequestSort}
                                           rowCount={surveys.length}/>
                        <TableBody>
                            {status === 'loading' ?
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <div className="d-flex justify-content-center">
                                            <MutatingDots
                                                color={palette.primary}
                                                height="100"
                                                width="100"
                                                visible={true}/>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                : stableSort(surveys, getComparator(order, orderBy), filter)
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) =>
                                        <SurveyTableItem key={index} item={row} deleteSurvey={deleteSurvey}
                                                         updateSurvey={updateSurvey}/>
                                    )}
                            {emptyRows > 0 && (
                                <TableRow style={{height: 53 * emptyRows}}>
                                    <TableCell colSpan={5}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className="child-mb-0"
                    rowsPerPageOptions={[6, 10, 24]}
                    component="div"
                    count={surveys.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('survey.table')}/>
            </Paper>
            <div className="py-3">
                <NavLink to='/survey-create' style={{textDecoration: 'none'}}>
                    <Button variant="contained">{t('surveys.newSurvey')}</Button>
                </NavLink>
            </div>
        </Box>
    );
}

export default SurveyTable;