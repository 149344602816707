import React from "react";
import $ from "jquery";
import * as SurveyCore from "survey-core";
import * as SurveyReact from "survey-react";
import * as widgets from "surveyjs-widgets";

import { SvgRegistry } from "survey-core";
import { ReactComponent as EmojiPickerIcon } from "../1F642.svg";
import ReactDOMServer from "react-dom/server";


const svg = ReactDOMServer.renderToString(<EmojiPickerIcon />);
SvgRegistry.registerIconFromSvg("emotionsratings", svg);

window["$"] = window["jQuery"] = $;
require("emotion-ratings/dist/emotion-ratings.js");
widgets.emotionsratings(SurveyCore);
widgets.emotionsratings(SurveyReact);

SurveyCore.Serializer.removeProperty("emotionsratings", "emotions");
SurveyCore.Serializer.addProperty("emotionsratings", {
  name: "emotions",
  type: "itemvalues",
  default: ["angry", "disappointed", "meh", "happy", "inlove"],
  category: "emotions",
  categoryIndex: 1,
});

//var emotionsratingsWidget = SurveyCore.CustomWidgetCollection.Instance.getCustomWidgetByName("emotionsratings");
//console.log(emotionsratingsWidget);
//var properties = SurveyCore.JsonObject.metaData.getProperties("emotionsratings");
//console.log(properties);

function SurveyComponent() {
  return;
}
export default SurveyComponent;