import React, { Dispatch, SetStateAction } from 'react'
import { IQuestion } from '../../../classes/Question';
import { ISurvey } from '../../../classes/Survey';
import './ComparativeInformation.css';
import ReturnBar from '../../generic/ReturnBar/ReturnBar';
import { ComparativeInformationChart } from '../ComparativeInformationChart/ComparativeInformationChart';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

interface Props {
    survey: ISurvey,
    //questions: IQuestion[],
    questions: any[],
    currentSurvey: IQuestion[],
    setViewComparative: Dispatch<SetStateAction<boolean>>,
    titleSurveyCurrent: string,
    
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffff',
    ...theme.typography.body2,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(3),
    color: theme.palette.text.primary,
    }));

    
const ComparativeInformation = (props: Props) => {
    const scoreColor = (score: number, maxScore: number): string => {
        let color = "";
        console.log("score Survey Dashboard OVerview: ",score);
        score += 1;
        switch(props.survey.title) {
          case "Employee Burnout": //Burnout
            if(score >= 1 && score <= 1.62) color= "#FB5523";
            if(score >= 1.63 && score <= 2.67) color= "#FDD835";
            if(score >= 2.68 && score <= 4) color= "#6DAE43";
            break;
          case "Employee Engagement": //Engagement
            if(score >= 1 && score <= 2.33) color= "#FB5523";
            if(score >= 2.34 && score <= 3.67) color= "#FDD835";
            if(score >= 3.67 && score <= 5) color= "#6DAE43";
            break;
          case "Job Satisfaction Survey": //Job Satisfaction
            if(score >= 1 && score <= 3) color= "#FB5523";
            if(score >= 3.1 && score <= 5.1) color= "#FDD835";
            if(score >= 5.1 && score <= 7) color= "#6DAE43";
            break;
          default:
            const percentage = score / maxScore;
            color = percentage > 0.33? percentage > 0.66? "#6DAE43" : "#FDD835" : "#FB5523";
        }
        console.log("color y score: ", score, color);
        return color;
      }

    return (
        <>
            <ReturnBar setToggle={props.setViewComparative} value={false} title=" Dimensions" />
            
            <div className="flex">
                {
                    props.survey.dimensions.map((dimension, index) => {
                        return (
                            <Item>
                            <div key={index}>
                                <h3>{dimension.name}</h3>
                                <br/>
                                <div style={{
                                    fontFamily: "'IBM Plex Sans', sans-serif",
                                    fontSize: "32px",
                                    color: scoreColor(dimension.score, dimension.maxScore),
                                    fontWeight: "600",
                                }} >
                                    {dimension.score}</div>
                                {/* <ComparativeInformationChart survey={props.survey} questions={props.questions.filter(question => question.dimension === dimension.name)}/> */}
                                <ComparativeInformationChart survey={props.survey} questions={props.questions} currentSurvey={props.currentSurvey} titleSurveyCurrent={props.titleSurveyCurrent} dimension={dimension.name}/>
                            </div>
                            </Item>
                        )
                    })
                }
            </div>
        </>
    )
}

export default ComparativeInformation;