import {InputBase, Paper, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {GridOn, Search, Star, ViewList} from "@mui/icons-material";
import React, {Component} from "react";
import CopyPasteSurveyCard from "./copy-paste-survey-card";
import IconButton from "@mui/material/IconButton";
import {deleteSurveyThunk, getAllSurveysThunk} from "../../../../redux/survey/survey.thunk";
import {connect} from "react-redux";
import {MsalContext} from "@azure/msal-react";
import CopyPasteSurveyList from "./copy-paste-survey-list";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../../../../assets/styles/theme";

class CopyPasteSurveyIndex extends Component {
    state = {
        filterItems: 'all',
        alignment: 'grid'
    };

    static contextType = MsalContext;

    componentDidMount() {
        this.props.getAllSurveys(this.context.accounts[0].idTokenClaims.oid);
    }

    handleFilter = (event, filter) => {
        this.setState({filterItems: filter})
    };

    handleAlignment = (event, newAlignment) => {
        this.setState({alignment: newAlignment})
    };

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-6 my-3">
                        <h3 className="text-black-50 mb-3">Copy a paste survey</h3>
                    </div>
                    <div className="col-md-6 my-3">
                        <Paper component="form"
                               sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto'}}>
                            <IconButton type="submit" sx={{p: '10px'}} aria-label="search">
                                <Search/>
                            </IconButton>
                            <InputBase sx={{ml: 1, flex: 1}}
                                       placeholder="Search surveys"
                                       inputProps={{'aria-label': 'search surveys'}}/>
                        </Paper>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 my-3">
                        <ToggleButtonGroup
                            value={this.state.filterItems}
                            exclusive
                            onChange={this.handleFilter}
                            aria-label="text filter Items">
                            <ToggleButton value="all" aria-label="All" sx={{borderRadius: 'auto'}}>
                                All
                            </ToggleButton>
                            <ToggleButton value="favorites" aria-label="Favorites">
                                <Star/>
                                Favorites
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div className="col-md-6 text-md-end my-3">
                        <ToggleButtonGroup
                            value={this.state.alignment}
                            exclusive
                            onChange={this.handleAlignment}
                            aria-label="text alignment">
                            <ToggleButton value="grid" aria-label="Grid" sx={{borderRadius: 'auto'}}>
                                <GridOn/>
                            </ToggleButton>
                            <ToggleButton value="list" aria-label="List">
                                <ViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                <h4 className="text-muted">Recent</h4>

                <div className="d-flex justify-content-center">
                    <MutatingDots
                        color={palette.primary}
                        height="100"
                        width="100"
                        visible={this.props.status === 'loading'}/>
                </div>

                {this.props.status === 'loading' ?
                    null : this.state.alignment === 'grid' ?
                        <CopyPasteSurveyCard surveys={this.props.allSurveys}/> :
                        <CopyPasteSurveyList surveys={this.props.allSurveys}/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        allSurveys: state.surveys.allSurveys,
        status: state.surveys.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllSurveys: (tenantId) => dispatch(getAllSurveysThunk(tenantId)),
        deleteSurvey: (id) => dispatch(deleteSurveyThunk(id)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CopyPasteSurveyIndex);