import * as React from 'react';
import {AppBar, Avatar, Box, Button, Container, IconButton, Toolbar, Tooltip, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/MoodbitLogo.png';
import MenuOption from "./MenuOption";
import MenuUser from "./MenuUser";
import {adminItems} from "../admin-items";
import {NavLink, useNavigate} from "react-router-dom";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {useTranslation} from 'react-i18next';
import UserEditPhotoIndex from "../user/user-edit-photo/user-edit-photo-index";
import {useState} from "react";

//const pages = ['Dashboard', 'Surveys', 'Reports'];

const styles = {
    logo: {
        width: '180px',
        cursor: 'pointer'
    }
}

const NavBar = (props) => {
    console.log('NAVBAR', props.user);

    const {t} = useTranslation();

    const settings = [
        {
            name: 'Profile',
            translation: t('user.profile'),
            route: '/user-profile'
        },
        /*{
            name: 'Account',
            route: '/user-profile'
        },*/
        {
            name: 'Logout',
            translation: t('user.logout'),
            route: '/'
        },
    ];

    const items = adminItems(t);

    const isAuthenticated = useIsAuthenticated();

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const {accounts, instance, inProgress} = useMsal();

    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (route = null) => {
        setAnchorElNav(null);
        if (route !== null) {
            navigate(route);
        }
    };

    const handleCloseUserMenu = (value = '') => {
        setAnchorElUser(null);
        if (value.name === 'Logout') {
            instance.logoutRedirect({postLogoutRedirectUri: "/"});
        } else {
            if (value !== '') navigate(value.route)
        }
    };

    const routerLink = (route) => {
        navigate(route);
    }

    return (
        <Box>
            <AppBar position="fixed" color="inherit"
                    sx={{top: {xs: 'auto', sm: 0}, zIndex: (theme) => theme.zIndex.drawer + 1}}
                    className={!isAuthenticated ? inProgress === 'logout' ? '' : 'bg-light' : ''}
                    style={{boxShadow: 'none'}}>
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        <Typography variant="h6" noWrap component="div" sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}>
                            <img style={styles.logo} src={logo} alt="" onClick={() => routerLink('/')}/>
                        </Typography>

                        {isAuthenticated ?
                            <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                                <IconButton size="large" aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleOpenNavMenu}
                                            color="inherit">
                                    <MenuIcon/>
                                </IconButton>
                            </Box> : null}
                        <Typography variant="h6" noWrap component="div"
                                    sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <img style={styles.logo} src={logo} alt="" onClick={() => routerLink('/')}/>
                        </Typography>
                        {isAuthenticated ? <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                            {items.map((page, index) => (
                                <NavLink to={page.routerLink} style={{textDecoration: 'none'}} key={index}>
                                    <Button variant={page.link ? "text" : "contained"}
                                            color="primary"
                                            sx={{my: 2, color: page.link ? '#4c4b4b' : 'white', display: 'block'}}>
                                        {page.name}
                                    </Button>
                                </NavLink>))}
                        </Box> : null}

                        {isAuthenticated && accounts[0] && props.user ?
                            <Box sx={{flexGrow: 0, display: 'flex'}}>
                                {/*<Languaje/>*/}
                                <Tooltip title={t('user.settings')}>
                                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}
                                                aria-controls="menu-appbar-user"
                                                aria-haspopup="true">
                                        <Avatar alt={props.user.firstName}
                                                src={`${process.env.REACT_APP_BACKEND_URL}/api/native/users/download/${props.user.photo}`}/>

                                        {/*<Avatar alt={props.user.firstName}
                                                src={`http://localhost:8081/api/native/users/${props.user.photo}`}/>*/}

                                    </IconButton>
                                </Tooltip>
                            </Box> : null}
                    </Toolbar>
                </Container>
            </AppBar>
            {isAuthenticated && accounts[0]? <MenuOption anchorElNav={anchorElNav} handleCloseNavMenu={handleCloseNavMenu}/>: null}

            {isAuthenticated && accounts[0] && props.user?
                <MenuUser anchorElUser={anchorElUser}
                          user={props.user}
                          handleCloseUserMenu={handleCloseUserMenu}
                          settings={settings}/> : null}
        </Box>
    );
};
export default NavBar;
