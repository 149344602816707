import React, { Dispatch, SetStateAction, useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { IQuestion } from '../../../classes/Question';
import { ISurvey } from '../../../classes/Survey';
import ScoreCard from '../../generic/ScoreCard';
import Grid from '@mui/material/Unstable_Grid2';
import Table from 'react-bootstrap/Table';
import './SurveyDashboardOverview.css'
import { MyProgressBar } from '../../../../helpers/funtionsUtils/funtionsUtils';
import ReturnBar from '../../generic/ReturnBar/ReturnBar';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
interface IProps {
    survey: ISurvey,
    questions: IQuestion[],
    setViewQuestionsData: Dispatch<SetStateAction<boolean>>,
    setViewComparative: Dispatch<SetStateAction<boolean>>,
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
    color: theme.palette.text.primary,
    //minHeight: '250px',
    }));


const SurveyDashboardOverview = (props: IProps) => {
    console.log("entra a funcion SurveyDashboardOverview:",props);
    const [dimension, setDimension] = useState("All dimensions");
    const changeDimension = (event: any): void => {
        setDimension(event.target.value);
    }

    const filterQuestionsByDimension = () => {
        if (dimension !== "All dimensions") {
            console.log("caso si");
            return props.questions.filter(question => question.dimension === dimension);
        }
        else {
            console.log("caso no", props.questions);
            return props.questions;
        }
    }

    const calculateSurveyAverage = (): number => {
        let total = 0;
        for(const dimension of props.survey.dimensions) {
            if(dimension.score && !Number.isNaN(dimension.score) ) {
                total += dimension.score;
            } else {
                let sum = 0;
                for(const item of props.questions) {
                    sum += item.score;
                } 
                sum /= props.questions.length;
                total += sum;
            }
        }
        return total / props.survey.dimensions.length - 1;
    }

    const calculateSurveyMaxScore = (): number => {
        let total = 0;
        for(const dimension of props.survey.dimensions) {
            total += dimension.maxScore;
        }
        return total - 1;
    }

    const calculateSurveyParticipation = (): number => {
        let total = 0;
        if(props.questions[0].choiceQuestionProps != undefined) {
            total = props.questions[0].choiceQuestionProps?.choiceValues.reduce((total, value) => total + value);
        }
        return total - 1;
    }

    const scoreColor = (score: number, maxScore: number): string => {
        let color = "";
        console.log("score Survey Dashboard OVerview: ",score);
        score += 1;
        switch(props.survey.title) {
          case "Employee Burnout": //Burnout
            if(score >= 1 && score <= 1.62) color= "#FB5523";
            if(score >= 1.63 && score <= 2.67) color= "#FDD835";
            if(score >= 2.68 && score <= 4) color= "#6DAE43";
            break;
          case "Employee Engagement": //Engagement
            if(score >= 1 && score <= 2.33) color= "#FB5523";
            if(score >= 2.34 && score <= 3.67) color= "#FDD835";
            if(score >= 3.67 && score <= 5) color= "#6DAE43";
            break;
          case "Job Satisfaction Survey": //Job Satisfaction
            if(score >= 1 && score <= 3) color= "#FB5523";
            if(score >= 3.1 && score <= 5.1) color= "#FDD835";
            if(score >= 5.1 && score <= 7) color= "#6DAE43";
            break;
          default:
            const percentage = score / maxScore;
            color = percentage > 0.33? percentage > 0.66? "#6DAE43" : "#FDD835" : "#FB5523";
        }
        console.log("color y score 2: ", score, color);
        return color;
      }
    const isDimesionValid = () =>{
        const myMap = new Map();
        myMap.set("Employee Engagement",1);
        myMap.set("Employee Burnout",1);
        myMap.set("Job Satisfaction Survey",1);
        return myMap.get(props.survey.title) === 1;
    }
    const dimensionValid = isDimesionValid();
    
    return (
        <>
            <ReturnBar title={props.survey.title}/>
            <div className="cardsContainer">
                <Grid container spacing={5}>
                        <Grid
                            xs={12} md={3}
                            style={{ display: "flex", gap: "1.5rem", alignItems: "center"}}
                        >
                            <ScoreCard className="h-28 p-3"
                                text='survey'
                                score={calculateSurveyAverage()}
                                maxScore={calculateSurveyMaxScore()}
                                surveyType={props.survey.title}
                            />
                        </Grid>
                    
                        {
                            props.survey.dimensions.map((dimension, index) => {
                                if(dimension.name !== undefined) {
                                    return (
                                        <Grid
                                            xs={12} md={3}
                                            style={{ display: "flex", gap: "1.5rem", alignItems: "center"}}
                                        >
                                        <ScoreCard key={index} className="h-28 p-3"
                                            text={dimension.name}
                                            score={dimension.score - 1}
                                            maxScore={dimension.maxScore}
                                            surveyType={props.survey.title}
                                        />
                                        </Grid>
                                    )
                                }

                            })
                        }
                     
                    <Grid
                        xs={12} md={3}
                        style={{ display: "flex", gap: "1.5rem", alignItems: "center"}}
                    >
                        <ScoreCard className="h-28 p-3"
                            text='Employee Participation'
                            score={calculateSurveyParticipation()}
                            maxScore={calculateSurveyParticipation()}
                            surveyType={props.survey.title}
                        />
                    </Grid>
                    
                </Grid>
            </div>    

            <Item>  
                <Grid container spacing={2}>
                        <Grid xs={6}>
                            <div className='tableTitle'>
                            Questions<br/>
                            <h2 className="reportLink" onClick={() => props.setViewQuestionsData(true)}>Report <ArrowOutwardIcon /></h2>
                            </div>
                        </Grid>
                        {dimensionValid && <Grid xs={12} md={5}>
                            <div className="flex bg-gray-300">
                            <select onClick={changeDimension}>
                                <option value="All dimensions">All dimensions</option>
                                {
                                    props.survey.dimensions.map((dimension, index) => {
                                        return (
                                            <option key={index} value={dimension.name}>{dimension.name}</option>
                                        )
                                    })
                                }
                            </select>
                            </div>
                        </Grid>}
                    </Grid>


                    <Table striped bordered hover className="w-full" size="sm">
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Results</th>
                                {dimensionValid && <th onClick={() => props.setViewComparative(true)} style={{cursor:"pointer"}}>Dimension <ArrowOutwardIcon /></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filterQuestionsByDimension().map((question, index) => {
                                    if( Number.isNaN(question.score)  || !question.score) {
                                        question.score = 0;
                                    }
                                    console.log("paso 3,",question,question.score)
                                    let x = question.score * 20 / 100;
                                    console.log("x:",x);
                                    return (
                                        <tr key={index} className={`${index % 2 === 0 ? "bg-blue-50" : ""} h-10`}>
                                            <td>{index + 1}. {question.title}</td>
                                            <td>
                                            <MyProgressBar value={question.score - 1} color = {scoreColor(question.score - 1, question.maxScore)}/>
                                            </td>
                                            {dimensionValid && <td>{question.dimension}</td>}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
            </Item>
          
        </>
    )
}

export default SurveyDashboardOverview;