//New component for sharing surveys, calls new endpoint, so responses processing is handled on backend side
import React, {Component} from "react";
import "survey-react/survey.css";
import * as Survey from "survey-react";
import {connect} from "react-redux";
import {getSingleSurveyThunk, saveResponsesThunk} from "../redux/survey/survey.thunk";
import {Alert, Typography} from "@mui/material";
import FooterSurvey from "./survey/share-components/footer-survey";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../assets/styles/theme";
import {withParams} from "../helpers/with-params";

class RespondSurvey extends Component {

    constructor(props) {
        super();
        this.survey = null;


    }

    state = {
        loaded: false,
        survey: {},
        user: {
            firstName: null,
            lastName: null,
            email: null,
            id: null,
            tenantId: null,
        },
    };


    async componentDidMount() {

        /*const path = window.location.pathname.split('/')
        console.log(path);
        const id = path[path.length - 1]*/
        const {id} = this.props.params;
        await this.props.getSingleSurvey(id);

        this.survey = new Survey.Model(this.props.singleSurvey.json);

        this.survey.onComplete.add(this.surveyCompleted);

        this.setState({loaded: true});

    }

    surveyCompleted = async (sender, options) => {

        console.log(sender.data);

        window.localStorage.setItem(`${this.props.singleSurvey.id}`, true);
        console.log(window.localStorage);

        options.showDataSaving();

        let surveyId = this.props.singleSurvey.id;


        function toString(o) {
            Object.keys(o).forEach(k => {
                if (typeof o[k] === 'object') {
                    return toString(o[k]);
                }

                o[k] = '' + o[k];
            });

            return o;
        }

        let responses = toString(sender.data);
        console.log(responses);

        let res = await this.props.saveResponses(surveyId, responses);
        console.log(res);

        if (res === 201) {
            options.showDataSavingSuccess();
        }
        if (res === 500) {
            options.showDataSavingError();
        }
        console.log(res);
        window.location.href = "/";

    };

    render() {

        console.log(window.localStorage);

        return (
            <div>


                <div>
                    {this.state.loaded ? (
                        <div>

                            {!this.props.singleSurvey.active ?
                                <Alert severity="warning" sx={{width: '100%'}}>
                                    {this.props.t('survey.stopResponses')}
                                </Alert> :
                                <div>

                                    {window.localStorage.getItem(this.props.singleSurvey.id) ?
                                        <Typography>{this.props.t('completeSurvey')}</Typography> :

                                        <div>
                                            <Survey.Survey model={this.survey}/>
                                            <FooterSurvey/>
                                        </div>

                                    }
                                </div>
                            }
                        </div>
                    ) : null}
                    <div>
                        <div className="d-flex justify-content-center">
                            <MutatingDots
                                color={palette.primary}
                                height="100"
                                width="100"
                                visible={!this.state.loaded}/>
                        </div>
                        {!this.state.loaded ? <p className="text-center">{this.props.t('status.loading')}... </p> : null}
                    </div>
                </div>


            </div>
        );
    }
}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        singleSurvey: state.surveys.singleSurvey,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getSingleSurvey: (id) => dispatch(getSingleSurveyThunk(id)),
        saveResponses: (surveyId, responses) => dispatch(saveResponsesThunk(surveyId, responses)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withParams(RespondSurvey));
