import {Button, Card, CardContent, Menu, MenuItem, Typography} from "@mui/material";
import {MoreHoriz} from "@mui/icons-material";
import {numberOfQuestions} from "../../../helpers/survey.helper";
import {format} from 'date-fns';
import './resent-survey-card.css';
import React, {useState} from "react";

const ResentSurveyCard = (props) => {
    const {survey, deleteSurvey} = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseMenu = (option = '') => {
        if (option === 'Delete') {
            if (window.confirm(`Are you sure to delete the Survey: ${survey.title}?`)) {
                deleteSurvey(survey.id);
            }
        }
        setAnchorEl(null);
    }

    return (
        <Card className="position-relative pt-2 mb-3">
            <span className="chip-badge bg-warning text-white">DRAFT</span>
            <CardContent className="card-survey">
                <div className="card-item-first">
                    <Typography>
                        <b>{survey.title}</b>
                    </Typography>
                    <Typography className="text-muted-info">
                        <span className="d-block">Created: {format(new Date(survey.createdAt), 'MM/dd/yyyy')}</span>
                        <span className="d-block">Modified: {format(new Date(survey.updatedAt), 'MM/dd/yyyy')}</span>
                    </Typography>
                </div>
                <div className="card-item-survey">
                    <h4>{numberOfQuestions(survey)}</h4>
                    <span className="text-muted-info">Questions</span>
                </div>
                <div className="border-start ps-3 card-item-survey">
                    <h4>{`< 1 min`}</h4>
                    <p className="text-muted-info">Estimated time to complete</p>
                </div>
                <div className="border-start ps-3 card-item-survey">
                    <h4>{survey.numberOfResponses}</h4>
                    <span className="text-muted-info">Collectors</span>
                </div>

                <div className="border-start ps-3 card-item-last">
                    <Button onClick={handleOpenMenu}><MoreHoriz/></Button>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                        <MenuItem key={1} onClick={() => handleCloseMenu('Delete')}>{`Delete`}</MenuItem>
                    </Menu>
                </div>
            </CardContent>
        </Card>
    )
}

export default ResentSurveyCard;
