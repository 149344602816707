import axios from "axios";


export const addNewSurveyService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/native/surveys/newSurvey`, obj);
    return data;
}

export const addNewFullSurveyService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/native/surveys/newFullSurvey`, obj);
    return data;
}

export const addNewQuestionService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/native/questions/newQuestion`, obj);
    return data;
}

export const addNewAnswerService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/native/answers/newAnswer`, obj);
    return data;
}

export const addJSONtoSurveyService = async (id, obj) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/native/surveys/newSurvey/${id}`, obj);
    return data;
}

export const increaseResponsesCountService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/native/surveys/addResponse/${id}`);
    return data;
}

export const getAllSurveysService = async (userId) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/native/surveys/allSurveys/${userId}`);
    return data;
}


export const getAllTemplatesService = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/native/surveys/templates`);
    return data;
}

export const getSingleTemplateService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/native/surveys/templates/${id}`);
    return data;
}

export const saveResponsesService = async (surveyId, responses, userId) => {
    const { data, status } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/native/responses/saveResponses`, {surveyId: surveyId, responses: responses, userId: userId});
    return {data, status};
}

//use it to update status only for now
export const updateSurveyService = async (surveyId, changes) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/native/surveys/update/${surveyId}`, changes);
    return data;

}