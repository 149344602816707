import React from 'react';
import { IQuestion } from '../../classes/Question'
import SurveyChart from '../questionsData/SurveyChart';
import Grid from '@mui/material/Unstable_Grid2';
/*
interface Props {
    startIndex: number;
    questions: IQuestion[],
}
*/
interface IProps {
    questions: IQuestion[],
    indexStart: number,
    getIndexEnd: (indexEnd: number) => void
}

export const questionListRender = (props: IProps) => {
    props.getIndexEnd(props.indexStart + props.questions.length);
    return (
        <>
            <div className="h-40 bg-green-200">
                Nothing
            </div>
        </>
    )
}

export const choiceQuestionListRender = (props: IProps) => {
    props.getIndexEnd(props.indexStart + props.questions.length);
    return (
        <>
            <div className="grid grid-cols-2 gap-2 mt-4 mb-4 p-2 bg-white rounded-lg shadow shadow-gray-300">
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                {
                    props.questions.map((question, index) => 
                        <SurveyChart key={index} index={index + props.indexStart} question={question} />
                    )
                }
                </Grid>
            </div>
        </>
    )
}

export const textQuestionListRender = (props: IProps) => {
    props.getIndexEnd(props.indexStart + props.questions.length);
    return (
        <>
            <div className="mt-4 mb-4 p-2 pl-0">
                {
                    props.questions.map((question, index) =>
                        <SurveyChart key={index} index={index + props.indexStart} question={question} />
                    )
                }
            </div>
        </>
    )
}

export const questionListRenderFactory = (type: string) => {
    switch(type){
        //case "rating":
        //    return choiceQuestionListRender;
        case "text":
        case "comment":
            return textQuestionListRender;
        default:
            return choiceQuestionListRender;
            //return questionListRender;
    }
}