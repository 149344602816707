import SurveyActionTypes from "./survey.types";

const INITIAL_STATE = {
  status: 'initial',
  allSurveys: [],
  singleSurvey: {},
  singleQuestion: {},
  questions: [],
  templates: [],
  singleTemplate: {},
  error: null
};


const surveysReducer = (state = INITIAL_STATE, action) => {
  console.log("PL", action);
  switch (action.type) {
    case SurveyActionTypes.CHANGE_STATUS:
      return { ...state, status: action.payload };
    case SurveyActionTypes.ERROR_SURVEY:
      return { ...state, error: action.payload };
    case SurveyActionTypes.GET_ALL_SURVEYS:
      return { ...state, allSurveys: action.payload };
    case SurveyActionTypes.GET_ALL_SURVEYS_WITH_SCHEDULE:
      return { ...state, allSurveys: action.payload };
    case SurveyActionTypes.GET_SINGLE_SURVEY:
      return { ...state, singleSurvey: action.payload };
    case SurveyActionTypes.ADD_NEW_SURVEY:
      return { ...state, singleSurvey: action.payload, error: null };
    case SurveyActionTypes.ADD_NEW_QUESTION:
      return { ...state, singleQuestion: action.payload, error: null };
    case SurveyActionTypes.ADD_NEW_ANSWER:
      return { ...state, error: null };
    case SurveyActionTypes.ADD_RESPONSE:
      return { ...state, error: null };
    case SurveyActionTypes.GET_ALL_QUESTIONS:
      return { ...state, questions: action.payload, error: null };
    case SurveyActionTypes.GET_SINGLE_SURVEY_WITH_QUESTIONS_AND_ANSWERS:
      return { ...state, singleSurvey: action.payload };
    case SurveyActionTypes.DELETE_SURVEY:
      return { ...state };
      case SurveyActionTypes.DELETE_SCHEDULE:
        return { ...state };
    case SurveyActionTypes.ADD_JSON_CARD_TO_SURVEY:
      return { ...state };
    case SurveyActionTypes.ADD_SCHEDULE:
      return {...state};
    case SurveyActionTypes.GET_ALL_TEMPLATES:
      return {...state, templates: action.payload}
    case SurveyActionTypes.GET_SINGLE_TEMPLATE:
      return {...state, singleTemplate: action.payload}
    case SurveyActionTypes.SAVE_RESPONSES:
      return {...state}
    case SurveyActionTypes.UPDATE_SURVEY:
      return {
        ...state,
        singleSurvey: action.payload,
        allSurveys: state.allSurveys.map((survey) => {
          if (survey.id === action.payload.id) {
            return action.payload;
          }
          return survey;
        })}
    default:
      return state;
  }
};

export default surveysReducer;
