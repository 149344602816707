import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import './ReturnBar.css';

interface Props {
  title?: string,
  setToggle?: Dispatch<SetStateAction<boolean>>,
  value?: boolean;
}

const ReturnBar = (props: Props) => {
  const navigate = useNavigate();

  const notUndefined = (): boolean => {
    return props.setToggle !== undefined && props.value !== undefined;
  }
  const setToggle = (): void => {
    if(props.setToggle !== undefined && props.value !== undefined) {
      props.setToggle(props.value)
    }
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffff',
    ...theme.typography.body2,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
    color: theme.palette.text.primary,
    }));

  const Today = new Date();

  return (
    <React.Fragment>
      <Item> 
        {
          notUndefined() ?
            <h1 onClick={() => {
              setToggle()
            }}
            >
              <ArrowBackIcon />
               {props.title}
            </h1>
            :
            <h1 onClick={() => navigate(-1)}><ArrowBackIcon /> {props.title}</h1>
        }
        <p>{Today.toDateString()}</p>
      </Item>
    </React.Fragment>
  )
}

export default ReturnBar;