import {Card, CardContent, CardHeader, IconButton, Typography} from "@mui/material";
import {Star} from "@mui/icons-material";
import {format} from "date-fns";
import {numberOfQuestions} from "../../../../helpers/survey.helper";

const CopyPasteSurveyCard = (props) => {
    const {surveys} = props;
    return (
        <div className="row">
            {surveys.map((item, index) =>
                <div className="col-md-6 col-lg-3 my-2" key={index}>
                    <Card>
                        <CardHeader
                            action={
                                <IconButton aria-label="favorite">
                                    <Star/>
                                </IconButton>
                            }
                            title={item.title}
                            subheader={format(new Date(item.createdAt), 'MM/dd/yyyy')}/>
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                {`${numberOfQuestions(item)} questions`}
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                {`${item.numberOfResponses} responses`}
                            </Typography>
                        </CardContent>
                    </Card>
                </div>)}
        </div>
    )
}

export default CopyPasteSurveyCard;