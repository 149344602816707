const UserActionTypes = {
  ADD_USER: "ADD_USER",
  EDIT_USER: "EDIT_USER",
  GET_USER: "GET_USER",
  CHANGE_STATUS: "CHANGE_STATUS",
  CHANGE_CREATE_STATUS: "CHANGE_CREATE_STATUS",
  UPDATE_PROFILE_PHOTO: "UPDATE_PROFILE_PHOTO",
};

export default UserActionTypes;
