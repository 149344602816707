import {Navigate, useRoutes, useNavigate} from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import HomePage from "./components/home/HomePage";
import SurveyIndex from "./components/survey/survey-index/survey-index";
import SurveyCreate from "./components/survey/survey-create/survey-create";
import ReportIndex from "./components/reports/report-index";
import DisplaySurvey from "./components/survey/survey-index/survey-show/DisplaySurvey";
import ResponsesReport from "./PBI/ResponsesReport";
import MyReport from "./PBI/Report";
import TakeSurvey from "./components/survey/survey-index/survey-take/TakeSurvey";
import RespondSurvey from "./components/RespondSurvey";
import RespondSignInRequired from "./components/RespondSignInRequired";
import UserProfile from "./components/user/user-profile";
import CollectIndex from "./components/survey/share-components/collect/collect-index";
import Billing from "./components/billing/billing-index";
import {useIsAuthenticated} from "@azure/msal-react";
import useBreakpoint from "use-breakpoint";
import SurveyDashboard from "./charts/components/main/SurveyDashboard";

const BREAKPOINTS = {mobile: 0, tablet: 768, desktop: 1280};

const AppRouting = ({t}) => {
    const isAuthenticated = useIsAuthenticated();
    const {breakpoint} = useBreakpoint(BREAKPOINTS, 'desktop');
    let routes = [];
    if (isAuthenticated) {
        routes = [
            {
                path: '/',
                element: <HomePage/>
            },
            {
                path: 'dashboard',
                element: <Dashboard/>
            },
            {
                path: 'surveys',
                element: <SurveyIndex/>
            },
            {
                path: 'collect/:id',
                element: <CollectIndex/>
            },
            {
                path: 'takeSurvey/:id',
                element: <TakeSurvey t={t}/>
            },
            {
                path: 'surveys-show/:id',
                element: <DisplaySurvey t={t}/>
            },
            {
                path: 'r/sirq/:id',
                element: <RespondSignInRequired t={t}/>
            },
            {
                path: 'report/:id',
                element: <ResponsesReport t={t}/>
            },
            {
                path: 'myreport/:id',
                element: <MyReport t={t}/>
            },
            {
                path: 'myreporttest/:id',
                //element: <ResponsesSurveyReport t={t} breakpoint={breakpoint}/>
                element: <SurveyDashboard/>
            },
            {
                path: 'reports',
                element: <ReportIndex/>
            },
            {
                path: 'survey-create/*',
                element: <SurveyCreate t={t}/>
            },
            {
                path: 'user-profile',
                element: <UserProfile t={t}/>
            },
            {
                path: 'billing',
                element: <Billing t={t}/>
            },
        ];
    } else {
        routes = [
            {
                path: '/',
                element: <HomePage/>
            },
            /*{
                path: 'collect/:id',
                element: <CollectIndex/>
            },*/
            {
                path: 'takeSurvey/:id',
                element: <TakeSurvey t={t}/>
            },
            /*{
                path: 'surveys-show/:id',
                element: <DisplaySurvey t={t}/>
            },*/
            {
                path: 'surveys',
                element: <SurveyIndex/>
            },
            {
                path: 'report/:id',
                element: <ResponsesReport t={t}/>
            },
            /*{
                path: 'myreporttest/:id',
                element: <ResponsesSurveyReport t={t} breakpoint={breakpoint}/>
            },*/
            {
                path: '*',
                element: <Navigate to="/"/>//<RedirectRoute to="/"/>
            },
        ];
    }
    routes.push({
        path: 'r/:id',
        element: <RespondSurvey t={t} navigate={useNavigate()}/>
    })
    return useRoutes(routes);
}

export default AppRouting;