
import React, { useEffect, useState, useRef } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import 'chart.js/auto'; //fix: Canvas is already in use. Chart with ID '0' must be destroyed before the canvas can be reused.
import { Bar, Doughnut } from 'react-chartjs-2'
import ScoreBar from '../../generic/ScoreBar';
import SentimentBar from '../../generic/SentimentBar/SentimentBar';
import { RatingChartConfig, TextChartConfig } from '../../../classes/ChartConfig';
import { IQuestion } from '../../../classes/Question';
import Table from 'react-bootstrap/Table';
import { ProgressBar } from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import { MyMatrix, MyProgressBar } from '../../../../helpers/funtionsUtils/funtionsUtils';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Chart, { ChartConfiguration, ChartTypeRegistry,Color } from 'chart.js/auto';
import { MatrixDataPoint } from 'chartjs-chart-matrix';
import {draw, generate} from 'patternomaly';
import { SurveyService } from '../../../services/SurveyService';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  width: '100%',
  height: 'auto',
}));


export const choiceQuestionRender = (index: number, question: IQuestion): React.ReactNode => {
  console.log("*** entra choiceQuestionRender");  
  let chartConfig = new RatingChartConfig(question);
    let { options, data } = chartConfig.createChartConfig();
    options.plugins = {legend: {display: false},};
    // options.maintainAspectRatio = false;
    console.log("this optiones : ",options);
    console.log("*** antes return  choiceQuestionRender");  
    return (
      <Item>
        <h3 style={{ fontSize:'18px', fontWeight:'medium', color:'#1A237E', fontFamily: "'IBM Plex Sans', sans-serif" }}>{index}. {question.title}</h3>
        <div>
          <Bar options={options} data={data}/>
        </div>
      </Item>
    );
    /*
    return (
        <>
        <Item2>
        <div className="flex flex-wrap">
                {index}. {question.title}
                <div className="container-deducer">
                  <Bar options={options} data={data} />
                </div>
            </div>
        </Item2>
        </>
    );*/
}

export const textQuestionRender = (index: number, question: IQuestion): React.ReactNode => {
    console.log("data textQuestionRender: ",question);
    const chartConfig = new TextChartConfig(question);
    const { options, data } = chartConfig.createChartConfig();
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center'
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
        color: theme.palette.text.primary,
    }));

    return (
        <>
        <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
            
            <Item>
                
                <h3>{index}. {question.title}</h3>
                <Table striped bordered hover className='w-100'>
                    <thead>
                    <tr>
                        <th>Answers</th>
                        <th>Sentiment</th>
                    </tr>
                    </thead>
                    <tbody>
                    {question.textQuestionProps?.answers.map((answer, index) => {
                        let sentiment = 0;
                        if (question.textQuestionProps) {
                        sentiment = question.textQuestionProps.sentimentScores[index];
                        }
                        return (
                        <tr key={index}>
                            <td className="height-plus">{index + 1}. {answer}</td>
                            <td>
                            <ProgressBar
                                variant={sentiment > 0.33 ? sentiment > 0.66 ? "success" : "warning" : "danger"}
                                now={100}
                                label={sentiment > 0.33 ? (sentiment > 0.66 ? "POSITIVE" : "NEUTRAL") : "NEGATIVE"}
                                className="center"
                            />
                            </td>
                        </tr>
                        );
                    })}
                    </tbody>
                </Table>
                
            </Item>
            </Grid>
            <Grid item xs={12} md={3}>
            <Item>
                    <h3>Satisfaction</h3>
                    <p>Team feelings</p>
                    <Doughnut options={options} data={data} />
                    <br />
                    <div className="" style={containerStyle}>
                     <MyProgressBar
                            value = {1}
                            text = "POSITIVE"
                            rounded = {false}
                        />
                        <MyProgressBar
                            value = {0.5}
                            text = "NEUTRAL"
                            rounded = {false}
                        />
                    </div>
                    <div className="" style={containerStyle}>
                        <MyProgressBar
                            value = {0.1}
                            text = "NEGATIVE"
                            rounded = {false}
                        />
                    </div>                        
                
                </Item>
            </Grid>
        </Grid>
        </>
    )
}
///**** matrix */
interface RawData {
    x: number;
    y: number;
    v: number;
}

const formatData = (responses : any, questions : any, title : string)=>{
  console.log("format data response: ",responses);
  console.log("format data questions: ",questions);
  console.log("format data title: ",title);
  console.log("format data questions.native_question: ",questions.native_questions);
  let data = [{x:1,y:2,valor:30},{x:1,y:2,valor:30},{x:1,y:2,valor:30},{x:1,y:2,valor:30}];

  const rowTitles: string[] = [];
  const columnTitles: string[] = [];
  try {
    let idQuestionCurrent = 0;
    ///obtain id question
    for(const nativeQuestions of questions.native_questions) {
      if(nativeQuestions.content) {
        if(nativeQuestions.content === title) {
          idQuestionCurrent = nativeQuestions.id;
        }
      } else {
        if(nativeQuestions.name === title) {
          idQuestionCurrent = nativeQuestions.id;
        }
      }
      //if( nativeQuestions.name === title) {
      //  idQuestionCurrent = nativeQuestions.id;
     // }
    }
    console.log("format data questions.json.pages: ",questions.json.pages);
    ///obtain quetsions of rows
    for(const jsonPages of questions.json.pages) {
      console.log("jsonPages: ",jsonPages);
      for(const questionPageCurrent of jsonPages.elements) {
        console.log("page Current name:",questionPageCurrent);
        let inOk = false;
        if(questionPageCurrent.title) {
          if(questionPageCurrent.title === title) {
            inOk = true;
          }
        } else {
          if(questionPageCurrent.name === title) {
            inOk = true
          }
        }
        if( inOk ) {
          console.log("entra if questionPageCurrent.name === title:",questionPageCurrent);
          for(const valueRowCurrent of questionPageCurrent.rows) {
            if(valueRowCurrent.text) {
              rowTitles.push( valueRowCurrent.text );
            } else {
              rowTitles.push( valueRowCurrent );
            }
          }
          for(const valueColumnsCurrent of questionPageCurrent.columns) {
            if( valueColumnsCurrent.text ) {
              columnTitles.push( valueColumnsCurrent.text );
            } else {
              columnTitles.push( valueColumnsCurrent );
            }
          }
        }
      }
    }

    console.log("otro paso title: ",title);
    console.log("otro paso responses: ",responses);
    console.log("idQuestionCurrent: ",idQuestionCurrent)
    const matrixMap = new Map<string, Map<string, number>>();
    for(const responseCurrent of responses.native_responses) {
      if(responseCurrent.nativeQuestionId === idQuestionCurrent) {
        const row = responseCurrent.contentQuestion;
        const column = responseCurrent.contentValue;
        const rowMap = matrixMap.get(row) ?? new Map<string, number>();
        const currentValue = rowMap.get(column) ?? 0;
        rowMap.set(column, currentValue + 1);
        matrixMap.set(row, rowMap);
      }
    }
    console.log("mapa es: ", matrixMap);

    data = [];
    console.log("rowTitles: ",rowTitles)
    console.log("columnTitles: ",columnTitles)

    for(const rowCurrent of rowTitles) {
      const rowMap = matrixMap.get(rowCurrent) ?? new Map<string, number>();
      for(const columnCurrent of columnTitles) {
        console.log("1: rowCurrent", rowCurrent, "columCurrent:", columnCurrent);
        console.log("value map: ", rowMap.get(columnCurrent));
        data.push({x: 0, y: 0, valor: rowMap.get(columnCurrent) ?? 0});
      }
    }

    console.log("rowTitles: ",rowTitles);
    console.log("columnTitles: ",columnTitles);
    console.log("idQuestionCurrent:",idQuestionCurrent);
    console.log("data:",data);
    return {
      data:data,
      rowTitles:rowTitles,
      columnTitles:columnTitles,
    };
  } catch (error){
    console.log("error try cathc funtion formatData: ",error);
    data = [
      { x: 1, y: 1, valor: 11 },
    ];
    return {data:data,rowTitles:["h"],columnTitles:["a"]};
  } finally {
    console.log("entra finally");
  }
 
}
const ObtainMatrizFinal = ({ id, index, title }:any) => {
  console.log("type id:", id);
  console.log("obationMatriFinal llega title:",title);
  const [responses, setResponses] = useState({});
  const [questions, setQuestions] = useState({});
  //const [data, setData] = useState<{ x: number; y: number; valor: number }[]>([]);
  useEffect(() => {
    if (id) {
          (async () => {
              //console.log(" ******* props.user in effect: ");
              setResponses(await SurveyService.getSingleSurveyWithResponse(id) );
              setQuestions(await SurveyService.getSingleSurveyFull(id) );
              
            })();
            //setData(formatData(response, questions, title));
      }
    }, [])
    //console.log("index:",index);
    console.log("index, ObtainMatrizFinal response:", responses);
    console.log("index, ObtainMatrizFinal questions:", questions);
//const  rowTitles = ['Aas','B','C'];
//const columnTitles = ["a","b","fa","asd"];
    const {data, rowTitles, columnTitles} = (formatData(responses, questions, title));
//console.log("afuera: ",columnTitles);
console.log("enra Matrix questionRender: ");
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
    <MyMatrix 
          numRows={rowTitles.length} 
          numCols={columnTitles.length} 
          maxWidth={250} 
          maxHeight={150} 
          title={title} 
          data={data} 
          color={'00CC00'} 
          rowTitles={rowTitles} 
          columnTitles={columnTitles}
      />
      </div>
  );
}


export const matrixQuestionRender = (index: number, question: IQuestion): React.ReactNode => {
  
  console.log("antes matrix datos index:",index);
  console.log("antes matrix datos question:",question);
  console.log("antes matrix datos title", question.title, typeof question.title)
    return (
       <>
       {/* <Matrix4 data={data}/> */}
        {/* <Matrtix2/> */}
        {/* <MatrixChart data={data}/> */}
       {/* <MatrixChartJS data={data}/> */}
          <ObtainMatrizFinal id={question.surveyId} index={index} title={question.title}/>
       </>
    )
};
  

export const choiceQuestionShowResults = (question: IQuestion): React.ReactNode => {
    console.log("question 1: ",question);
    if( Number.isNaN(question.score)) {
        question.score = 0;
    }
    return (
        <>
            <ScoreBar score={question.score} maxScore={question.maxScore}/>
        </>
    )
}

export const textQuestionShowResults = (question: IQuestion): React.ReactNode => {
    console.log("question 2: ",question);
    return (
        <>
            <SentimentBar score={question.score} />
        </>
    )
}