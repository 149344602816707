import axios from "axios";

export const getUserService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/native/users/user/${id}`);
    return data;
}

export const getUserWithEmailService = async (email) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/native/users/user-email`, {email});
    return data;
}

export const addUserService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/native/users/newUser`, obj);
    return data;
}

export const editUserService = async (obj, id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/native/users/editUser/${id}`, obj);
    return data;
}

export const updateProfilePhotoService = async (obj, id) => {
    /*const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/native/users/updateProfilePhotoUser/${id}`, obj, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });*/

    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/native/users/updateProfilePhotoUser/${id}`, obj, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });

    return data;
}
