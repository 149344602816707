import employee from "../../assets/employee.gif";
import './home.css';
import {capitalize} from "@mui/material";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {SignInButton} from "../../auth/ui";
import React from "react";
import SurveyIndex from "../survey/survey-index/survey-index";
import {useTranslation} from "react-i18next";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../../assets/styles/theme";

const HomePage = () => {
    const isAuthenticated = useIsAuthenticated();
    const {accounts, inProgress} = useMsal();
    const {t, i18n} = useTranslation();
    console.log(t('home.title'));
    const title = t('home.title');
    let lastWord = title.split(" ");
    lastWord = lastWord[lastWord.length - 1];


    function removeLastWord(str) {
        const lastIndexOfSpace = str.lastIndexOf(' ');

        if (lastIndexOfSpace === -1) {
            return str;
        }

        return str.substring(0, lastIndexOfSpace);
    }


    const lngs = {
        en: {nativeName: 'English'},
        ru: {nativeName: 'Русский'},
        es: {nativeName: 'Español'},
        ja: {nativeName: "日本語"},
        zh: {nativeName: "臺灣話"}
    };

    return (
        <div>
            {/*<div>
                {Object.keys(lngs).map((lng) => (
                    <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                        {lngs[lng].nativeName}
                    </button>
                ))}
            </div>*/}

            {isAuthenticated && accounts[0] ? <SurveyIndex/> :
                inProgress !== 'none' ?
                    inProgress === 'login' || inProgress === 'logout' ?
                        <div className="text-center">
                            <p>{`${capitalize(inProgress)} in progress...`}</p>

                            <div className="d-flex justify-content-center">
                                <MutatingDots
                                    color={palette.primary}
                                    height="100"
                                    width="100"
                                    visible={true}/>
                            </div>

                        </div> : null :
                    <div className="row h-100 mt-5">
                        <div className="col-md-6">
                            <p className="fs-lg-1">
                                <b>{t("home.title")}</b>
                            </p>
                            <p className="fs-5">{t('home.description')}</p>
                            {/*<Button variant="contained" color="primary" size="large" className="mt-2">{t('home.getStarted')}</Button>*/}

                            <SignInButton variant="outlined" color="primary" size="large" className="mt-2"/>
                        </div>
                        <div className="col-md-6 text-center">
                            <img width="100%" src={employee} alt=""/>
                        </div>
                    </div>
            }
        </div>
    );
}

export default HomePage;