import React, {Component} from "react";
import * as SurveyCreator from "survey-creator-react";
import {StylesManager, SurveyCreatorComponent} from "survey-creator-react";
import {localization} from "survey-creator-core";
import * as Survey from "survey-core";

import "./styles.css";

import {AuthenticatedTemplate, MsalContext, UnauthenticatedTemplate} from "@azure/msal-react";
import {Button} from "@mui/material";

import {
    addNewAnswerThunk, addNewFullSurveyThunk,
    addNewQuestionThunk,
    addNewSurveyThunk,
    getSingleTemplateThunk
} from "../../../../redux/survey/survey.thunk";
import {connect} from "react-redux";

import "survey-creator-core/survey-creator-core.i18n";
import {withTranslation} from "react-i18next"
import {spanish} from "../helpers/languajes";
import {Link} from "react-router-dom";
import {addUserThunk, getUserThunk} from "../../../../redux/users/users.thunk";
import {toastr} from "react-redux-toastr";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../../../../assets/styles/theme";

import SurveyComponent from "./SurveyComponent.jsx";

class CreateSurvey extends Component {
    constructor(props) {
        super(props);

        console.log("gg createSurvey: ",this.props.i18n.language);
        localization.currentLocale = localStorage.getItem("i18nextLng");
        if (localStorage.getItem("i18nextLng") === "zh") {
            localization.currentLocale = "zh-tw";
        }

        ///if is spanish
        spanish();

        const options = {
            questionTypes: ["text", "comment", "checkbox", "radiogroup", "dropdown", "rating", "matrix", "imagepicker", "emotionsratings"],
            showLogicTab: true,
            pageEditMode: "bypage",
            showJSONEditorTab: true,
            haveCommercialLicense: true, //Add this line
            showTestSurveyTab: true, // configuración de showTestSurveyTab en false
            //isAutoSave: true,
        };
        // this.creator = new SurveyCreator.SurveyCreator(options);
        this.creator = new SurveyCreator.SurveyCreator(options);
        console.log("creator star-from: ",this.creator);
        this.creator.saveSurveyFunc = this.saveMySurvey;
        if (props.myJson) {
            this.creator.JSON = props.myJson.json;
        }

        this.creator.toolboxLocation = "left";
        this.creator.toolbox.forceCompact = true;

        let items = this.creator.toolbarItems;
        //hide undo, redo
        console.log("item start-from: ",items);
        if (this.props.breakpoint !== 'mobile') {
            items[0].visible = false;
            items[1].visible = false;
            items[4].visible = false;
            items[13].visible = true;
        } else {
            items[0].visible = true;
            items[1].visible = true;
            items[4].visible = true;
            items[13].visible = false;
        }

        //property grid on the right
        this.creator.collapseAllPropertyGridCategories();

        //remove green options in checkboxes and radio questions
        // Survey.settings.showDefaultItemsInCreatorV2 = false;


        //trash icon for deleting question options
        Survey.SvgRegistry.registerIconFromSvg("remove_16x16", '<svg class="svg-icon" viewBox="0 0 20 20"><path d="M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306"></path></svg>');

        StylesManager.applyTheme('bootstrap');
        // console.log(this.props.i18n.language);


        console.log("constructor");
        console.log(props);
        console.log(Survey.Serializer.getProperties("checkbox"))
        console.log(this.creator.toolbox.getItemByName("checkbox"))

        // this.creator.toolbox.getItemByName("checkbox").json.choices = json;

        console.log(this.creator)

        //Change the default choices for "checkbox", "radiogroup" and "dropdown" questions
        var newDefaultChoices = [
            "My Choice 1", {
                value: 2,
                text: "Show text not value"
            }
        ];

        this.creator.onQuestionAdded.add(function (_, options) {
                console.log("question added")
                console.log(options)

            }
        )

        /*this.creator.onUploadFile.add((_, options) => {
            console.log(options);
        });*/


        // //Change default choices only
        // this.creator
        //     .toolbox
        //     .getItemByName("checkbox")
        //     .json
        //     .choices = newDefaultChoices;
        // this.creator
        //     .toolbox
        //     .getItemByName("radiogroup")
        //     .json
        //     .choices = newDefaultChoices;
        // this.creator
        //     .toolbox
        //     .getItemByName("dropdown")
        //     .json
        //     .choices = newDefaultChoices;

        Survey.Serializer.getProperty("checkbox", "hasSelectAll").visible = false;
        Survey.Serializer.getProperty("selectbase", "hasOther").visible = false;
        Survey.Serializer.getProperty("selectbase", "hasNone").visible = false;

        console.log(Survey.Serializer.getProperties("question"))
        console.log(SurveyCreator);

        SurveyCreator.settings.toolbox.defaultJSON.emotionsratings = {
            "choices": [
                "1",
                "2",
                "3",
                "4",
                "5"
            ]
        }

        // Survey.Serializer.getProperty("radiogroup", "hasOther").visible = false;
        // Survey.Serializer.getProperty("radiougroup", "hasNone").visible = false;
        // console.log(Survey.Serializer.getProperty("checkbox", "hasNone"))
        // console.log(Survey.Serializer.getProperty("checkbox", "hasSelectAll"))


    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        let items = this.creator.toolbarItems;
        if (this.props.breakpoint !== 'mobile') {
            items[0].visible = false;
            items[1].visible = false;
            items[4].visible = false;
            items[13].visible = true;
        } else {
            items[0].visible = true;
            items[1].visible = true;
            items[4].visible = true;
            items[13].visible = false;
        }
    }

    static contextType = MsalContext;

    render() {
        const customeTheme = {
            "--primary": palette.main,
            "--primary-light": "rgba(28,131,234,0.06)",
            "--secondary": palette.main
        }
        const {t} = this.props;
        return (
            <div style={{width: '100%', height: '90vh'}}>
                <AuthenticatedTemplate>

                    <div className="d-flex justify-content-center">
                        <MutatingDots
                            color={palette.primary}
                            height="100"
                            width="100"
                            visible={this.state.saving}/>
                    </div>

                    {this.state.saving ?
                        null :
                        <div>
                            <div className="my-3 text-end">
                                <Link to={`/survey-create`} style={{textDecoration: 'none'}}>
                                    <Button variant="contained">{t('option.back')}</Button>
                                </Link>
                            </div>
                            <div style={{width: '100%', height: '90vh'}}>
                                {/*<Collapse in={this.state.openAlert}>
                                    <Alert severity="warning"
                                           action={
                                               <IconButton
                                                   aria-label="close"
                                                   color="inherit"
                                                   size="small"
                                                   onClick={() => {
                                                       this.setState({openAlert: false});
                                                   }}
                                               >
                                                   <Close fontSize="inherit"/>
                                               </IconButton>
                                           }>{t('survey.msgWarningLogo')}
                                    </Alert>
                                </Collapse>*/}
                                <SurveyCreatorComponent creator={this.creator} style={customeTheme}/>
                            </div>
                        </div>}
                    <p className="bg-danger text-white"> {this.state.error} </p>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>Please Sign To Use the Dashboard</UnauthenticatedTemplate>
            </div>);
    }

    saveMySurvey2 = async () => {
        console.log(this.creator);

        // this.setState({saving: true});

        try {


            const account = this.context.accounts[0];
            console.log(account);
            await this.props.getUser(account.localAccountId);

            if (!this.props.user.id) {

                await this.props.addUser({
                    id: account.localAccountId,
                    email: account.idTokenClaims.emails[0],
                    username: account.username,
                    firstName: account.idTokenClaims.extension_firstName,
                    lastName: account.idTokenClaims.extension_lastName,
                });
            }

            let json = this.creator.text;
            console.log(json);
            //parse before saving so it is stored as a json object, not string
            json = JSON.parse(this.creator.text);
            console.log(json);
            await this.props.addNewSurvey({
                title: json.title || "",
                description: json.description,
                json: json,
                nativeUserId: this.props.user.id
            })

            this.setState({errorSaveSurvey: this.props.error});

            for (let p of json.pages) {

                for (let q of p.elements) {
                    await this.props.addNewQuestion({
                        type: q.type,
                        content: q.title || q.name,
                        name: q.name,
                        nativeSurveyId: this.props.singleSurvey.id,
                    });
                    //add all answers with fk questionid that is created above

                    if (q.choices || q.rateValues) {
                        console.log("choiced or rate values")
                        let options = q.choices ? q.choices : q.rateValues
                        console.log(options);
                        for (let op of options) {
                            await this.props.addNewAnswer({
                                content: op.text ? op.text : (op.value ? op.value : op),
                                value: op.value ? op.value : op,
                                nativeSurveyId: this.props.singleSurvey.id,
                                nativeQuestionId: this.props.singleQuestion.id,
                            });
                        }
                    }


                    if (q.rows) {

                        for (let op of q.rows) {
                            await this.props.addNewAnswer({
                                content: op.text ? op.text : (op.value ? op.value : op),
                                value: op.value ? op.value : op,
                                nativeSurveyId: this.props.singleSurvey.id,
                                nativeQuestionId: this.props.singleQuestion.id,
                            });
                        }

                    }

                    if (q.columns) {

                        for (let op of q.columns) {
                            await this.props.addNewAnswer({
                                content: op.text ? op.text : (op.value ? op.value : op),
                                value: op.value ? op.value : op,
                                nativeSurveyId: this.props.singleSurvey.id,
                                nativeQuestionId: this.props.singleQuestion.id,
                            });
                        }

                    }


                }

            }

            this.setState({saving: false})
            if (this.state.errorSaveSurvey === null) {
                this.setState({saved: true})

                console.log(this.state, this.props);
                if (this.state.saved) {
                    this.props.navigate(`save/${this.props.singleSurvey.id}`)
                }
            } else {
                //this.setState({error: ''})
                //const itemSave = document.getElementById('svd-save');
                //const btn = itemSave.firstElementChild.firstElementChild;
                //btn.removeAttribute('disabled');
                toastr.error('Error', 'The survey could not be saved');
            }

        } catch (error) {
            console.log("ERROR!!!!!");
            console.log(error);
            this.setState({error: "" + error});
            this.setState({saving: false})
        }

    };

    saveMySurvey = async () => {
        console.log("entra ssavemysurvey 1:",this.creator);
        // this.setState({saving: true});
        this.setState({saving: true})
        try {
            const account = this.context.accounts[0];
            console.log(account);
            await this.props.getUser(account.localAccountId);

            if (!this.props.user.id) {
                await this.props.addUser({
                    id: account.localAccountId,
                    email: account.idTokenClaims.emails[0],
                    username: account.username,
                    firstName: account.idTokenClaims.extension_firstName,
                    lastName: account.idTokenClaims.extension_lastName,
                });
            }

            let json = this.creator.text;
            console.log(json);
            //parse before saving so it is stored as a json object, not string
            json = JSON.parse(this.creator.text);
            console.log(json);

            toastr.info('', 'Your survey is being stored, we will notify you when it is ready');

            await this.props.addNewFullSurvey({
                title: json.title || "",
                description: json.description,
                json: json,
                nativeUserId: this.props.user.id
            })

            this.setState({errorSaveSurvey: this.props.error, saving: false});
            if (this.state.errorSaveSurvey === null) {
                this.setState({saved: true})
                console.log(this.state, this.props);
                toastr.success('Success!', 'Your survey has been registered successfully');
                if (this.state.saved) {
                    this.props.navigate(`save/${this.props.singleSurvey.id}`)
                }
            } else {
                //this.setState({error: ''})
                //const itemSave = document.getElementById('svd-save');
                //const btn = itemSave.firstElementChild.firstElementChild;
                //btn.removeAttribute('disabled');
                toastr.error('Error', 'The survey could not be saved');
            }

        } catch (error) {
            console.log("ERROR!!!!!");
            console.log(error);
            this.setState({error: "" + error});
            this.setState({saving: false})
            toastr.error('Error', 'The survey could not be saved');
        }

    };

    state = {
        //if survey, questions, and answers got added succesfully, display a success
        saved: false,
        //inidicator that there is some progress happening once users click save button
        saving: false,
        error: '',
        errorSaveSurvey: null,
        loaded: false,
        openAlert: true,
    }


}


// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        singleSurvey: state.surveys.singleSurvey,
        singleQuestion: state.surveys.singleQuestion,
        user: state.users.user,
        singleTemplate: state.surveys.singleTemplate,
        error: state.surveys.error
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        addNewSurvey: (obj) => dispatch(addNewSurveyThunk(obj)),
        addNewFullSurvey: (obj) => dispatch(addNewFullSurveyThunk(obj)),
        addNewQuestion: (obj) => dispatch(addNewQuestionThunk(obj)),
        addNewAnswer: (obj) => dispatch(addNewAnswerThunk(obj)),
        getUser: (id) => dispatch(getUserThunk(id)),
        getTemplate: (id) => dispatch(getSingleTemplateThunk(id)),
        addUser: (obj) => dispatch(addUserThunk(obj))


    };
};

// const firstHOC = withTranslation(CreateSurvey);
// const secondHOC = connect(mapStateToProps, mapDispatchToProps)(firstHOC);
// export default secondHOC;


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CreateSurvey));
// export default withTranslation(connect(mapStateToProps, mapDispatchToProps)(CreateSurvey))
