import { choiceQuestionRender, choiceQuestionShowResults, matrixQuestionRender, textQuestionRender, textQuestionShowResults } from "../../components/render/QuestionRender/QuestionRender";
import { IQuestion } from "../Question";
import { DataConverter } from "./DataConverter";

export class QuestionsConverter extends DataConverter {
    protected questions = [] as IQuestion[];
    protected numberOfQuestions = 0;
    protected numberOfResponses = 0;

    constructor(
        rawDataFull: any,
        rawDataWithR: any
    ) {
        super(rawDataFull, rawDataWithR);
    }

    protected getNumberOfQuestions(): void {
        this.numberOfQuestions = this.rawDataFull.native_questions.length;
    }

    protected getNumberOfResponses(): void {
        this.numberOfResponses = this.rawDataFull.numberOfResponses;
    }

    protected getBasicData(): void {
        const surveyId = this.rawDataFull.id;
        let question = {} as IQuestion;
        console.log("** QuestionsConverter: this.rawDataFull",this.rawDataFull);
        for (const native_question of this.rawDataFull.native_questions) {
            question.id = native_question.id;
            question.title = native_question.content;
            question.type = native_question.type;
            //dimension = ?
            //score = ?
            //maxScore = ?
            question.date = native_question.updatedAt;
            question.surveyId = surveyId;
            this.questions.push(question);
            question = {} as IQuestion;
        }
        let i = 0;
        let dimension = "";
        for (const page of this.rawDataFull.json.pages) {
            dimension = page.title;
            for (const element of page.elements) {
                this.questions[i].dimension = dimension;
                i++;
                //score = ?
                /*
                if (this.questions[i].type == "comment" || this.questions[i].type == "text")
                    this.questions[i].maxScore = 3;
                else
                    this.questions[i].maxScore = 20; //!!!!!
                i++
                */
            }
        }
    }

    protected getQuestionsData(): void {
        let i = 0;
        console.log("entra aqui pregunta?:",this.questions);
        for (const question of this.questions) {
            this.getQuestionData(i);
            i++;
        }
    }

    protected getQuestionData(questionId: number) {
        let questionDataConverter = {} as QuestionDataConverter;
        questionDataConverter = QuestionDataConverterFactory.createConverter(this.questions[questionId].type, this.questions[questionId], this.rawDataFull, this.rawDataWithR);
        this.questions[questionId] = questionDataConverter.createQuestionWithData();
    }

    protected assignFunctions() {
    }

    protected others(): void {
    }

    public createQuestions(): IQuestion[] {
        //console.log("getting number of questions");
        this.getNumberOfQuestions();
        //console.log("getting number of responses");
        this.getNumberOfResponses();
        //console.log(this.questions);
        //console.log("getting basic data");
        this.getBasicData();
        //console.log(this.questions);
        //console.log("getting questions data");
        this.getQuestionsData();
        //console.log(this.questions);
        //console.log("assigning functions");
        this.assignFunctions();
        //console.log("getting others");
        this.others();
        return this.questions;
    }
}

export class QuestionDataConverterFactory {
    public static createConverter(type: string, question: IQuestion, rawDataFull: any, rawDataWithR: any): QuestionDataConverter {
        console.log("QuestionDataConverterFactory type:",type);
        switch (type) {
            case "rating": return new ChoiceQuestionDataConverter(question, rawDataFull, rawDataWithR);
            case "text":
            case "comment":
                return new TextQuestionDataConverter(question, rawDataFull, rawDataWithR);
            case "matrix": return new MatrixQuestionDataConverter(question, rawDataFull, rawDataWithR);
            //default: return {} as QuestionDataConverter;
            default: return new ChoiceQuestionDataConverter(question, rawDataFull, rawDataWithR);
        }
    }
}

abstract class QuestionDataConverter {
    constructor(
        protected question: IQuestion,
        protected rawDataFull: any,
        protected rawDataWithR: any,
    ) { }
    protected abstract getQuestionData(): void;
    protected abstract assignFunctions(): void;
    public createQuestionWithData() {
        this.getQuestionData();
        this.assignFunctions();
        return this.question;
    }
}

class ChoiceQuestionDataConverter extends QuestionDataConverter {
    protected getQuestionMaxValueAndChoices(): void {
        let data: any;
        let questionPosition = this.getQuestionPosition();
        const pagesLength = this.rawDataFull.json.pages.length;
        let pagesLengthSum = this.rawDataFull.json.pages[0].elements.length;
        let pagesLengthSumPrev = 0;

        let i = 0;
        while (i < pagesLength) {
            if (questionPosition >= pagesLengthSum) {
                pagesLengthSumPrev = pagesLengthSum;
                pagesLengthSum += this.rawDataFull.json.pages[++i].elements.length;
            }
            else {
                questionPosition = questionPosition - pagesLengthSumPrev;
                data = this.rawDataFull.json.pages[i].elements[questionPosition];
                break;
            }
        }
        //console.log(`position: ${questionPosition} page: ${i}`);

        if (this.question.choiceQuestionProps != undefined) {
            if (this.question.type != "rating") {
                if (this.question.type == "imagepicker") {
                    this.question.choiceQuestionProps.choices = data.choices.map((choice: { value: string }) => choice.value);
                }
                else {
                    this.question.choiceQuestionProps.choices = data.choices;
                }
                this.question.maxScore = data.choices.length;
            }
            else {
                if (data.rateValues != undefined) {
                    this.question.choiceQuestionProps.choices = data.rateValues.map((rateValue: { text: string }) => rateValue.text);
                    this.question.maxScore = data.rateValues.length;
                }
                else {
                    this.question.choiceQuestionProps.choices = []; //["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree"];
                    this.question.maxScore = 0; //5;
                }
            }
        }
        //console.log(this.question);
    }

    protected getQuestionPosition(): number {
        return this.rawDataFull.native_questions.findIndex((native_question: { id: number }) => native_question.id.toString() == this.question.id);
    }

    protected getQuestionData(): void {
        this.question.choiceQuestionProps = { choices: [] as string[], choiceValues: [] as number[], maxValue: 0 };
        this.getQuestionMaxValueAndChoices()
        this.getChoiceQuestionData();
    }

    protected getChoiceQuestionData(): void {
        //this.question.choiceQuestionProps = { choices: [] as string[], choiceValues: [] as number[], maxValue: -1 };
        let questionResponses = this.rawDataWithR.native_responses.filter((native_response: { nativeQuestionId: number; }) => native_response.nativeQuestionId.toString() == this.question.id);

        let choiceValues = new Array(this.question.maxScore).fill(0);
        for (const questionResponse of questionResponses) {
            //if (this.question.type == "rating" && +questionResponse.content - 1 < 5) {
            //    choiceValues[(+questionResponse.content) - 1]++;
            //}
            //else {
            if (this.question.choiceQuestionProps != undefined) {
                if (this.question.choiceQuestionProps.choices.length > 0) {
                    let choiceIndex = this.question.choiceQuestionProps.choices.findIndex(choice => choice == questionResponse.content);
                    choiceValues[choiceIndex]++;
                }
                else {
                    //If max score doesnt exists (= 0), then generate it using only the response values. 
                    const resizeArrayRight = (array: string[], length: number, fill_with: number) => array.concat((new Array(length)).fill(fill_with)).slice(0, length);
                    if (+questionResponse.content > choiceValues.length) {
                        this.question.choiceQuestionProps.choices = resizeArrayRight(this.question.choiceQuestionProps.choices, +questionResponse.content, 0);
                        choiceValues = resizeArrayRight(choiceValues, +questionResponse.content, 0);
                    }
                    this.question.choiceQuestionProps.choices = this.question.choiceQuestionProps.choices.map((choice, index) => choice = (index + 1).toString());
                    choiceValues[+questionResponse.content - 1]++;
                }
            }
            //}
        }
        let choiceQuestionProps = this.question.choiceQuestionProps;
        if (choiceQuestionProps != undefined) {
            choiceQuestionProps.choiceValues = choiceValues;
        }
        //this.question.score = choiceValues.reduce((previousValue, currentValue) => previousValue + currentValue) / choiceValues.length;

        //Calculate question score
        let responsesNumber = 0;
        let totalValue = 0;
        let average = 0;
        for (let i = 0; i < choiceValues.length; i++) {
            responsesNumber += choiceValues[i];
            totalValue += choiceValues[i] * (i+1);
        }
        average = totalValue / responsesNumber;

        this.question.score = average;
    }

    protected assignFunctions(): void {
        console.log("***  antes de choiceQuestionRender:");
        this.question.render = choiceQuestionRender;
        this.question.showResults = choiceQuestionShowResults;
    }
}



class TextQuestionDataConverter extends QuestionDataConverter {
    /*
    protected getQuestionMaxValue(): void {
        let i = 0;
        for (const page of this.rawDataFull.json.pages) {
            for (const element of page.elements) {
                this.question.maxScore = 3;
                i++;
            }
        }
    }
    */

    protected getQuestionData(): void {
        this.getTextQuestionData();
    }

    protected getTextQuestionData(): void {
        this.question.textQuestionProps = { answers: [] as string[], sentimentScores: [] as number[] };
        let questionResponses = this.rawDataWithR.native_responses.filter((native_response: { nativeQuestionId: number; }) => native_response.nativeQuestionId.toString() == this.question.id);

        let sentimentScores = [] as number[];
        for (const questionResponse of questionResponses) {
            this.question.textQuestionProps?.answers.push(questionResponse.content);
            sentimentScores.push(+questionResponse.native_response_sentiments[0].sentimentScore);
        }
        let textQuestionProps = this.question.textQuestionProps;
        if (textQuestionProps != undefined) {
            textQuestionProps.sentimentScores = sentimentScores;
        }
        if(sentimentScores.length===0)
        {this.question.score = 0} 
        else{  
        console.log("sentimentValues",sentimentScores)
        this.question.score = sentimentScores.reduce((previousValue, currentValue) => {
            console.log("values",previousValue, currentValue)
            return previousValue + currentValue}) / sentimentScores.length;
        }
    }

    protected assignFunctions(): void {
        console.log("file questionsconverter antes llamada a textQuestionRender");
        this.question.render = textQuestionRender;
        this.question.showResults = textQuestionShowResults;
    }
}
class MatrixQuestionDataConverter extends QuestionDataConverter {
    protected getQuestionData(): void{}
    protected assignFunctions(): void{
        this.question.render = matrixQuestionRender;
    }
    public createQuestionWithData() {
        this.getQuestionData();
        this.assignFunctions();
        return this.question;
    }
}
/*
class RadioQuestionDataConverter extends QuestionDataConverter {
}
*/

/*
class RatingQuestionDataConverter extends QuestionDataConverter {
}
*/

/*
class BoxQuestionDataConverter extends QuestionDataConverter {
}

class DesplegableQuestionDataConverter extends QuestionDataConverter {
}

class ImageQuestionDataConverter extends QuestionDataConverter {
}
*/

/*
class TextQuestionDataConverter extends QuestionDataConverter {
}

/*
class CommentQuestionDataConverter extends QuestionDataConverter {
}



class EmojiQuestionDataConverter extends QuestionDataConverter {
}
*/