import {Alert, ButtonGroup, IconButton, Menu, MenuItem, Snackbar, TableCell, TableRow, Tooltip} from "@mui/material";
import {
    InsertChartOutlined,
    MoreHoriz,
    Pause,
    PieChartOutline,
    PlayArrow,
    Telegram,
    Visibility
} from "@mui/icons-material";
import React, {useState} from "react";
import {format} from "date-fns";
import {Link} from "react-router-dom";
import ShareClipboard from "../../../layouts/ShareClipboard";
import {useTranslation} from "react-i18next";

const createOptions = (name, routerLink, translation) => {
    return {
        name,
        routerLink,
        translation
    }
}


const SurveyTableItem = (props) => {
    const {item, deleteSurvey, updateSurvey} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [status, setStatus] = useState(item.active);
    const [open, setOpen] = useState(false);
    const {t} = useTranslation();


    const options = [
        /*createOptions('Send a copy', '', t('surveys.sendCopy')),
        createOptions('Transfer', '', t('surveys.transfer')),
        createOptions('Make a copy', '', t('surveys.makeCopy')),
        createOptions('Create template', '', t('surveys.createTemplate')),
        createOptions('Clear response', '', t('surveys.clearResponse')),*/
        createOptions('Delete', '', t('surveys.delete')),
    ]

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseMenu = (option = '') => {
        if (option === 'Delete') {
            if (window.confirm(`Are you sure to delete the Survey: ${item.title}?`)) {
                deleteSurvey(item.id);
            }
        }
        setAnchorEl(null);
    }

    const toggleStatus = () => {
        console.log(item);
        console.log(props);
        updateSurvey(item.id, {active: !status});
        setStatus(!status)
        setOpen(true);
    }

    return (
        <TableRow hover
                  role="row"
                  tabIndex={-1}>
            <TableCell component="th"
                       style={{minWidth: '180px'}}
                       scope="row">
                <b className="text-primary">{item.title}</b>
                <p className="text-muted">{`Created: ${format(new Date(item.createdAt), 'MM/dd/yyyy')}`}</p>
            </TableCell>
            <TableCell align="left">{format(new Date(item.updatedAt), 'MM/dd/yyyy')}</TableCell>
            <TableCell align="center" className="fs-6">{item.numberOfResponses || 0}</TableCell>
            <TableCell align="center">
                <ButtonGroup>
                    <ShareClipboard id={item.id}/>
                </ButtonGroup>
            </TableCell>
            <TableCell align="right">
                <ButtonGroup>
                    <Link to={`/surveys-show/${item.id}`} params={{json: item.json}}>
                        <Tooltip title={t('item.preview')}>
                            <IconButton><Visibility/></IconButton>
                        </Tooltip>
                    </Link>

                    <Link to={`/collect/${item.id}`}>
                        <Tooltip title={t('item.distribute')}>
                            <IconButton><Telegram/></IconButton>
                        </Tooltip>
                    </Link>

                    <div className="d-flex">
                        {/*<Link to={`/report/${item.id}`}>
                            <Tooltip title={t('item.results')}>
                                <IconButton><PieChartOutline/></IconButton>
                            </Tooltip>
                        </Link>*/}
                        <Link to={`/myreporttest/${item.id}`}>
                            <Tooltip title={t('item.results')}>
                                <IconButton><InsertChartOutlined/></IconButton>
                            </Tooltip>
                        </Link>
                    </div>

                    <Tooltip title={t('item.status')}>
                        <IconButton color={status === false ? 'success' : 'warning'}
                                    sx={{borderRadius: '50rem'}}
                                    onClick={toggleStatus} value="status" selected={status}>
                            {status === false ? <PlayArrow/> : <Pause/>}
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={t('item.more')}>
                        <IconButton onClick={handleOpenMenu}><MoreHoriz/></IconButton>
                    </Tooltip>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                        {options.map((option, index) => <MenuItem key={index}
                                                                  onClick={() => handleCloseMenu(option.name)}>{option.translation}</MenuItem>)}
                    </Menu>
                </ButtonGroup>
                <div>
                    <Snackbar open={open} autoHideDuration={3000} anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                              onClose={() => setOpen(false)}>
                        {status ?
                            <Alert severity="success" sx={{width: '100%'}} onClose={() => setOpen(false)}>
                                {`Survey `}<b>Active</b>
                            </Alert> :
                            <Alert severity="warning" sx={{width: '100%'}} onClose={() => setOpen(false)}>
                                {t('item.pause')}
                            </Alert>
                        }

                    </Snackbar>
                </div>
            </TableCell>
        </TableRow>
    )
}

export default SurveyTableItem;