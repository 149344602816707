import React, { useState } from 'react'
import { IQuestion } from '../../../classes/Question';
import { ISurvey } from '../../../classes/Survey';
import './ComparativeInformationChart.css';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import 'chart.js/auto'; //fix: Canvas is already in use. Chart with ID '0' must be destroyed before the canvas can be reused.
import { Line } from 'react-chartjs-2'
import { DimensionsChartConfig } from '../../../classes/ChartConfig';

interface Props {
    survey: ISurvey,
    //questions: IQuestion[],
    questions: any[],
    currentSurvey: IQuestion[],
    titleSurveyCurrent: string,
    dimension: string,
}
/***
 * 
 */
const ComparativeInformationChart = (props: Props) => {
    const [filter, setFilter] = useState("month");

    const changeFilter = (event: any): void => {
        setFilter(event.target.value);
    }
    console.log("Antes: ComparativeInformationChart: ",props);
    const chartConfig = new DimensionsChartConfig(props.questions, props.currentSurvey, props.titleSurveyCurrent, props.dimension, filter);
    console.log("despues: ComparativeInformationChart: ",props);
    
    const { options, data } = chartConfig.createChartConfig();
    const value = 5.98;
    const textValue = 'Job Security';
    console.log("this is ComparativeInformation: options",options);
    console.log("this is ComparativeInformation: data", data);
    const color = value > 0.66 ? value > 0.33 ? "#6DAE43" : "#FDD835" : "#FB5523";
    return (
        <>
            <div className="flex flex-col justify-start h-60 mt-1 mb-1 bg-white mitad">
            <select className="w-60 ml-auto" name="" id="" onClick={changeFilter}>
                    <option value="month">Monthly</option>
                    <option value="year">Year</option>
                    <option value="week">Weekly</option>
                </select>
                <div className="flex-grow h-40 mitad">
                    <Line options={options} data={data} />
                </div>
            </div>
        </>
    )
}
export { ComparativeInformationChart };