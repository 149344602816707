import {Collapse, Divider, IconButton, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore, Star} from "@mui/icons-material";
import {format} from "date-fns";
import {numberOfQuestions} from "../../../../helpers/survey.helper";
import {useState} from "react";

const CopyPasteSurveyListItem = (props) => {
    const {item, last} = props;
    const [open, setOpen] = useState(false);


    return (
        <div>
            <ListItem disablePadding secondaryAction={
                <IconButton edge="end">
                    <Star/>
                </IconButton>
            }>
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemText primary={item.title} secondary={format(new Date(item.createdAt), 'MM/dd/yyyy')}/>
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem sx={{pl: 4}}>
                        <ListItemText primary={`${numberOfQuestions(item)} questions`}/>
                    </ListItem>
                    <ListItem sx={{pl: 4}}>
                        <ListItemText primary={`${item.numberOfResponses} responses`}/>
                    </ListItem>
                </List>
            </Collapse>
            {!last ? <Divider/> : null}
        </div>
    )
}

export default CopyPasteSurveyListItem;