import {useNavigate, useRoutes} from "react-router-dom";
import StartTemplateIndex from "./start-template/start-template-index";
import CopyPasteSurveyIndex from "./copy-paste-survey/copy-paste-survey-index";
import SurveyCreateHome from "./survey-create-home";
import SaveIndex from "../share-components/save/save-index";
import MySurveyCreator from "./start-from-scratch/start-from-scratch-index-new";
import useBreakpoint from "use-breakpoint";

const BREAKPOINTS = {mobile: 0, tablet: 768, desktop: 1280};

const SurveyRouting = ({t}) => {

    const {breakpoint} = useBreakpoint(BREAKPOINTS, 'desktop');
    return useRoutes([
        {
            path: '/',
            element: <SurveyCreateHome/>
        },
        {
            path: 'start-template',
            element: <StartTemplateIndex/>
        },
        {
            path: 'copy-paste-survey',
            element: <CopyPasteSurveyIndex/>
        },
        {
            path: 'start-from-scratch',
            element: <MySurveyCreator t={t} navigate={useNavigate()} breakpoint={breakpoint}/>
        },
        {
            path: 'start-template/:id',
            element: <MySurveyCreator t={t} navigate={useNavigate()} breakpoint={breakpoint}/>
        },
        {
            path: 'save/:id',
            element: <SaveIndex/>
        },
    ]);
}

export default SurveyRouting;
