import {Box, TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import {visuallyHidden} from "@mui/utils";
import {useTheme} from "@mui/material/styles";
import { useTranslation } from "react-i18next";


const createHeads = (id, position, disabledSearch, label) => {
    return {
        id,
        position,
        disabledSearch,
        label
    }
}


const SurveyTableHeader = (props) => {
    const {t} = useTranslation();
    const headCells = [
        createHeads('title', 'left', false, t('surveys.title')),
        createHeads('updatedAt', 'left', false, t('surveys.modified')),
        createHeads('numberOfResponses', 'center', false, t('surveys.responses')),
        createHeads('share', 'center', true, t('surveys.share')),
        createHeads('options', 'center', true, t('surveys.options')),
        /*createHeads('preview', true, true, 'Preview'),
        createHeads('distribute', true, true, 'Distribute'),
        createHeads('results', true, true, 'Results'),
        createHeads('status', true, true, 'Status'),
        createHeads('share', true, true, 'Share'),
        createHeads('more', true, true, 'More'),*/
    ];
    const {order, orderBy, onRequestSort} = props;
    const createSortHandler = (headCell) => (event) => {
        if (!headCell.disabledSearch) onRequestSort(event, headCell.id);
    };

    const theme = useTheme();

    return (
        <TableHead>
            <TableRow sx={{background: theme.palette.primary.main, color: theme.palette.primary.contrastText}}>
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={index}
                        align={headCell.position}
                        padding={'normal'}
                        className="showHideHeaders"
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            className="text-white"
                            sx={{'& .MuiTableSortLabel-icon': {
                                    color: '#fff !important',
                                }}}
                            onClick={createSortHandler(headCell)}>
                            {headCell.label}
                            {orderBy === headCell.id && !headCell.disabledSearch ? (
                                <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default SurveyTableHeader;