import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {msalConfig} from './auth/authConfig';
import {MsalProvider} from "@azure/msal-react";
import {PublicClientApplication} from "@azure/msal-browser";

//Redux
import {Provider} from "react-redux";
import store from "./redux/store";

import {BrowserRouter, HashRouter} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {palette, theme} from "./assets/styles/theme";

import './i18n';
import ReduxToastr from "react-redux-toastr";
import {useTranslation} from "react-i18next";
import {MutatingDots} from "react-loader-spinner";

const msalInstance = new PublicClientApplication(msalConfig);

const AppComponent = () => {
    const {t} = useTranslation();

    return <App instance={msalInstance} t={t}/>
}

const AppLoading = () => {
    const {t} = useTranslation();
    return (<p className="text-center">{t('loading')}... </p>)
}

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <HashRouter>
                    <ThemeProvider theme={theme}>
                        <Suspense fallback={
                            <div>
                                <div className="d-flex justify-content-center">
                                    <MutatingDots
                                        color={palette.primary}
                                        height="100"
                                        width="100"
                                        visible={true}/>
                                </div>
                                {/*<AppLoading/>*/}
                            </div>
                        }>
                            <AppComponent/>
                        </Suspense>
                    </ThemeProvider>
                </HashRouter>
                <div>
                    <ReduxToastr
                        timeOut={4000}
                        newestOnTop={false}
                        preventDuplicates
                        position="top-right"
                        getState={(state) => state.toastr} // This is the default
                        transitionIn="fadeIn"
                        transitionOut="fadeOut"
                        closeOnToastrClick/>
                </div>
            </Provider>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
