import {combineReducers} from "redux";

//import here
import surveysReducer from "./survey/survey.reducer";
import usersReducer from "./users/users.reducer";

import {reducer as toastrReducer} from 'react-redux-toastr'


const rootReducer = combineReducers({
    surveys: surveysReducer,
    users: usersReducer,
    toastr: toastrReducer
});

export default rootReducer;
